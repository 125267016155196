import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

//images
import imgDivider from '../../../assets/frontend/assets/divider.png';
import imgDividerBottom from '../../../assets/frontend/assets/divider-bottom.png';
import imgMembershipBox from '../../../assets/frontend/assets/membership-box.png';
import imgFirstFriday from '../../../assets/frontend/assets/first-friday.jpg';
import WhyMembership from './sections/WhyMembership';
import { useTranslation } from 'react-i18next';
import Header from '../../../layouts/frontend/Header';
import Footer from '../../../layouts/frontend/Footer';
import Breadcrumb from '../ui/Breadcrumb';
import Helmet from 'react-helmet';

const HowItWorks = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('navigation.howItWorks')} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb crumbs={[
          {text: t("navigation.home"), link: props.getLink("/")},
          {text: t("howItWorks.title"), link: ''}
      ]} />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="hiw">
          <div className="section-content pb-0">
              <h1 className="display-7 pb-4" dangerouslySetInnerHTML={{ __html: t("howItWorks.title") }} />
              <h2 className="display-6 force-uppercase" dangerouslySetInnerHTML={{ __html: t("howItWorks.subTitle") }} />
              <div className="header-description" dangerouslySetInnerHTML={{ __html: t("howItWorks.description") }} />

              <div className="container my-5">
                  <div className="row">
                      <div className="col">
                          <img src={imgDivider} className="w-100 pb-5" alt="" />

                          <div className="container-fluid monthly-delivery">
                              <div className="row">
                                  <div className="col-md-6 text-md-end pe-md-5 left-content">
                                      <img src={imgMembershipBox} className="w-md-100 pb-md-4" style={{ maxWidth: "265px" }} alt="Saké Box" />
                                  </div>
                                  <div className="col-md-6 text-md-start ps-md-5 right-content">
                                      <h3 dangerouslySetInnerHTML={{ __html: t("howItWorks.summaryTitle") }} />
                                      <p dangerouslySetInnerHTML={{ __html: t("howItWorks.summary") }} className="d-none d-lg-block" />
                                  </div>
                              </div>
                          </div>

                          <img src={imgDividerBottom} className="w-100 pt-5" alt="" />
                      </div>
                  </div>
              </div>

              <div className="cta-buttons">
                  <Link to={props.getLink("start-your-journey")} className="btn btn-light btn-cta">{t("ctaButtons.startJourney")}</Link>
              </div>
          </div>
      </section>
      <section id="hiw-days">
          <div className="section-content">
              <div className="container">
                  <div className="row section-info">
                      <div className="col-sm-6 text-sm-end pe-sm-5 pb-sm-5 left-content" dangerouslySetInnerHTML={{ __html: t("howItWorks.point1Title") }} />
                      <div className="col-sm-6 text-sm-start ps-sm-5 pb-5 right-content">
                          <h3 className="h3-enhanced" dangerouslySetInnerHTML={{ __html: t("howItWorks.point1SubTitle") }} />
                          <p dangerouslySetInnerHTML={{ __html: t("howItWorks.point1Info") }} className="d-none d-lg-block" />
                      </div>
                  </div>
                  <br />
                  <div className="row section-info">
                      <div className="col-sm-6 text-sm-end pe-sm-5 pb-sm-5 left-content">
                          <h3 dangerouslySetInnerHTML={{ __html: t("howItWorks.point2Title") }} />
                          
                          <img src={imgFirstFriday} alt="First Friday and Gift" className="w-100 pb-4 pt-3" style={{ maxWidth: "598px" }} />
                      </div>
                      <div className="col-sm-6 text-sm-start ps-sm-5 pb-5 right-content">
                          <h3 className="h3-enhanced" dangerouslySetInnerHTML={{ __html: t("howItWorks.point2SubTitle") }} />
                          <p dangerouslySetInnerHTML={{ __html: t("howItWorks.point2Info") }} className="d-none d-lg-block" />
                      </div>
                  </div>

                  {/* <div className="row section-info">
                      <div className="col-sm-6 text-sm-end pe-sm-5 pb-sm-5 left-content">
                          <h3>3. 4<sup>th</sup> Month of Subscription</h3>
                  
                          <img src="/assets/subscription-perks.gif" alt="3rd month perks" className="w-100 pb-4 pt-3"
                              style="max-width: 598px;" />
                      </div>
                      <div className="col-sm-6 text-sm-start ps-sm-5 pb-5 right-content">
                          <h3 className="h3-enhanced">SUBSCRIPTION PERKS</h3>
                          <p>As a valued subscriber, we would like to reward you with being a loyal member of JustSaké! As a loyal fan, we’d like to
                          give you a <strong>$200 subscription rebate!</strong> For this month, you’ll only be charged <strong>$499!</strong></p>
                      </div>
                  </div>

                  <div className="row section-info">
                      <div className="col-sm-6 text-sm-end pe-sm-5 pb-sm-5 left-content">
                          <h3>3. 5<sup>th</sup> Month Onwards</h3>
                  
                          <img src="/assets/subscription-loyalty.jpg" alt="Loyalty benefits" className="w-100 pb-4 pt-3"
                              style="max-width: 598px;" />
                      </div>
                      <div className="col-sm-6 text-sm-start ps-sm-5 pb-5 right-content">
                          <h3 className="h3-enhanced">Loyalty Benefits</h3>
                          <p>So, we thought, you should love us by now? So as a loyal member, we must reduce your <strong>monthly subscription fee
                          ($599/month)</strong>, and send you a lovely <strong>thank you gift</strong>!</p>
                      </div>
                  </div> */}

                  <div className="row section-info">
                      <div className="col-sm-6 text-sm-end pe-sm-5 pb-sm-5 left-content">
                          <h3 dangerouslySetInnerHTML={{ __html: t("howItWorks.point3Title") }} />
                  
                          <img src={imgMembershipBox} alt="Loyalty benefits" className="pb-4 pt-3" style={{ maxWidth: "598px" }} />
                      </div>
                      <div className="col-sm-6 text-sm-start ps-sm-5 pb-5 right-content">
                          <h3 className="h3-enhanced" dangerouslySetInnerHTML={{ __html: t("howItWorks.point3SubTitle") }} />
                          <p dangerouslySetInnerHTML={{ __html: t("howItWorks.point3Info") }} className="d-none d-lg-block" />
                      </div>
                  </div>

              </div>

              <div className="cta-buttons mt-5">
                  <Link to={props.getLink("start-your-journey")} className="btn btn-light btn-cta">{t("ctaButtons.startJourney")}</Link>
              </div>

              <br /><br />
              <div className="some-fineprint" style={{ fontSize: "0.8rem" }}>
                  {t("howItWorks.finePrint")}
              </div>
          </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
}

export default HowItWorks;
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { numberFormat } from "../../../../../assets/frontend/js/numberFormat";
import "./Subscription.min.css";

axios.defaults.withCredentials = true;

const Subscription = (props) => {
  const { t, i18n } = useTranslation();
  const [subscription, setSubscription] = useState(props.subscription);
  const langContentModifier = i18n.language === "en" ? "" : "_zh";
  const deliveryAddress = JSON.parse(
    subscription.relationships.order.address_data
  );
  const storedUserToken = localStorage.getItem("userToken");
  const [cancelIsLoading, setCancelIsLoading] = useState(false);
  const [resumeIsLoading, setResumeIsLoading] = useState(false);

  const cancelSubscriptionHandler = (subscriptions_id) => {
    setCancelIsLoading(true);

    Swal.fire({
      title: t("general.areYouSure"),
      text: t("general.cancelSubscriptionText"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("general.cancel"),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("general.yesCancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            process.env.REACT_APP_API_URL +
              "user/subscriptions/cancel/" +
              subscriptions_id,
            {
              headers: {
                Authorization: `Bearer ${storedUserToken}`,
              },
            }
          )
          .then((response) => {
            setCancelIsLoading(false);
            setSubscription(response.data.data);
          })
          .catch((err) => {
            setCancelIsLoading(false);
            Swal.fire(
              t("errorMessages.swalDefaultTitle"),
              err.response.data.message,
              "error"
            );
          });
      } else {
        setCancelIsLoading(false);
      }
    });
  };

  const resumeSubscriptionHandler = async (subscriptions_id) => {
    setResumeIsLoading(true);
    await axios
      .put(
        process.env.REACT_APP_API_URL +
          "user/subscriptions/resume/" +
          subscriptions_id,
        null,
        {
          headers: {
            Authorization: `Bearer ${storedUserToken}`,
          },
        }
      )
      .then((response) => {
        setResumeIsLoading(false);
        setSubscription(response.data.data);
      })
      .catch((err) => {
        setResumeIsLoading(false);
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
      });
  };

  return (
    <>
      <div className="card text-start">
        <div className="image-display" style={{ backgroundImage: "url(/assets/images/" + props.subscription.relationships.set.image_name + ")" }} />
        <div className="card-body">
          <h4 className="card-title">
            {props.subscription.relationships.set["name" + langContentModifier]}
            <span style={{ color: "#ccc" }}>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            </span>
            {
              props.subscription.relationships.set_sku_type[
                "name" + langContentModifier
              ]
            }
          </h4>
          <div className="price">
            {numberFormat(props.subscription.relationships.set_sku_type.price)}{" "}
            / {subscription.relationships.set_sku_type.payment_period_count}{" "}
            {t("profile.months")}
          </div>
          <p
            className="card-text"
            dangerouslySetInnerHTML={{
              __html:
                props.subscription.relationships.set[
                  "description" + langContentModifier
                ],
            }}
          />
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <strong>{t("profile.deliveryAddress")}</strong>:<br />
            <strong style={{ textTransform: "uppercase" }}>
              [{deliveryAddress.attributes.type}]
            </strong>
            &nbsp;&nbsp;{deliveryAddress.attributes.floor}/
            {deliveryAddress.attributes.flat},{" "}
            {deliveryAddress.attributes.building},{" "}
            {deliveryAddress.attributes.street},{" "}
            {
              deliveryAddress.relationships.district[
                "name" + langContentModifier
              ]
            }{" "}
            {/* <button className="btn btn-success btn-sm float-end">
              {t("profile.changeAddress")}
            </button> */}
          </li>

          <li className="list-group-item">
            <strong>{t("profile.nextDeliveryDate")}</strong>:<br />
            {subscription.attributes.next_delivery_start !== null && (
              <>
                {moment(subscription.attributes.next_delivery_start).format(
                  "MMMM Do"
                )}{" "}
                -{" "}
              </>
            )}
            {moment(subscription.attributes.next_delivery_end).format(
              "MMMM Do YYYY"
            )}{" "}
            {/* <button className="btn btn-success btn-sm float-end">
              {t("profile.pastDeliveries")}
            </button> */}
          </li>

          <li className="list-group-item">
            <strong>{t("profile.subscriptionStatus")}</strong>:{" "}
            {subscription.attributes.stripe_status} <br />
            <span style={{ fontSize: "0.8rem" }}>
              <strong>{t("general.orderNumber")}</strong>:{" "}
              {subscription.relationships.order.order_number} <br />
              <strong>{t("profile.subscriptionPeriod")}</strong>:{" "}
              {subscription.relationships.set_sku_type.payment_period_count}{" "}
              {t("profile.months")}
              <br />
              <strong>{t("profile.subscriptionStartedAt")}</strong>:{" "}
              {moment(subscription.attributes.created_at).format(
                "MMMM Do YYYY"
              )}
              <br />
              <strong>{t("profile.subscriptionCurrentPeriod")}</strong>:{" "}
              {moment(subscription.attributes.current_period_start).format(
                "MMMM Do"
              )}{" "}
              -{" "}
              {moment(subscription.attributes.current_period_end).format(
                "MMMM Do YYYY"
              )}
            </span>
            {/* <button className="btn btn-success btn-sm float-end">
              {t("profile.paymentUpdate")}
            </button> */}
          </li>
        </ul>
        <div className="card-body">
          {subscription.attributes.cancel_status !== null && (
            <>
              <strong>{t("profile.cancelStatus")}</strong>:{" "}
              {subscription.attributes.cancel_status}
              <br />
            </>
          )}

          {subscription.attributes.ends_at !== null && (
            <span style={{ fontSize: "0.8rem" }}>
              <strong>{t("profile.subscriptionWillEndOn")}</strong>:{" "}
              {moment(subscription.attributes.ends_at).format("MMMM Do YYYY")}
            </span>
          )}

          {subscription.attributes.can_cancel === true && (
            <button
              className="btn btn-sm btn-danger float-end"
              onClick={() => {
                cancelSubscriptionHandler(subscription.id);
              }}
              disabled={cancelIsLoading}
            >
              <span id="button-text">
                {cancelIsLoading === true && (
                  <div className="spinner" id="spinner"></div>
                )}
                {cancelIsLoading === false && t("profile.cancelSubscription")}
              </span>
            </button>
          )}

          {subscription.attributes.can_resume === true && (
            <button
              className="btn btn-sm btn-warning float-end"
              onClick={() => {
                resumeSubscriptionHandler(subscription.id);
              }}
              disabled={resumeIsLoading}
            >
              <span id="button-text">
                {resumeIsLoading === true && (
                  <div className="spinner" id="spinner"></div>
                )}
                {resumeIsLoading === false && t("profile.resumeSubscription")}
              </span>
            </button>
          )}
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default Subscription;

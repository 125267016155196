import React from 'react';
import { useTranslation } from 'react-i18next';

const Modal = (props) => {
  const { t } = useTranslation();
  return (
    <div className="modal fade" id={props.id} aria-labelledby={props.id} aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel" dangerouslySetInnerHTML={{ __html: props.title }} />
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body" dangerouslySetInnerHTML={{ __html: props.content }} />
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t("general.close")}</button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Modal;
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../../assets/frontend/js/numberFormat";
import CartContext from "../../../../../contexts/cart-context";
import Loader from "../../../ui/Loader";
import BottleDiscountBar from "../../cart/partials/BottleDiscountBar";
import CartItems from "../../cart/partials/CartItems";
import FreeDeliveryBar from "../../cart/partials/FreeDeliveryBar";

const CartSummary = (props) => {
  const { t, i18n } = useTranslation();
  const langContentModifier = i18n.language === "en" ? "" : "_zh";
  const cartCtx = useContext(CartContext);

  const updateCartHandler = (e) => {
    cartCtx.updateCart(
      e.target.getAttribute("data-set_skus_id"),
      e.target.getAttribute("data-product_skus_id"),
      e.target.getAttribute("data-quantity"),
      "fixed"
    );
  };

  const itemCount =
    props.setCartItems !== null
      ? props.setCartItems.length
      : props.productCartItems !== null
      ? props.productCartItems.length
      : null;

  let totalBottlesToPrice =
    cartCtx.isCartEmpty() === false
      ? cartCtx.cart.total_bottles_to_price
      : props.productCartItems !== null
      ? props.productCartItems.reduce(
          (prev, current) => prev + current.attributes.quantity,
          0
        )
      : 1;
  totalBottlesToPrice =
    totalBottlesToPrice > process.env.REACT_APP_MAX_BOTTLES_TO_PRICE
      ? process.env.REACT_APP_MAX_BOTTLES_TO_PRICE
      : totalBottlesToPrice;

  const cartTotal = 
    cartCtx.isCartEmpty() === false
      ? cartCtx.cart.total
      : props.cart.attributes.sub_total;

  const noDiscountTotal = 
    cartCtx.isCartEmpty() === false
      ? cartCtx.cart.no_discount_total
      : props.productCartItems.reduce((prev, current) => prev + (current.attributes.quantity * current.relationships.product_sku.price_data[0].price), 0);

  const msrpTotal = 
    cartCtx.isCartEmpty() === false
      ? cartCtx.cart.msrp_total
      : props.productCartItems.reduce((prev, current) => prev + (current.attributes.quantity * current.relationships.product_sku.msrp_price), 0);
  return (
    <React.Fragment>
      <h4>
        {t("general.orderSummary")}{" "}
        {itemCount !== null && (
          <>
            ({itemCount} {t("checkout.items")})
          </>
        )}
      </h4>
      {props.checkoutIsLoading && <Loader />}
      {!props.checkoutIsLoading && props.productCartItems && (
        // #TODO - Display for products if we start selling single item products.
        <div className={props.from === undefined ? "products-container" : ""}>
          <CartItems
            from={props.from}
            getLink={props.getLink}
            cartItems={props.productCartItems}
            totalBottlesToPrice={totalBottlesToPrice}
            updateCartHandler={updateCartHandler}
          />
        </div>
      )}

      {props.setCartItems &&
        props.setCartItems.map((setCartItem, idx) => {
          return (
            <div className="card mb-3" key={idx}>
              <div className="row g-0">
                <div
                  className="col-md-4 image-display"
                  style={{
                    backgroundImage:
                      "url(/assets/images/" +
                      setCartItem.relationships.set.image_name +
                      ")",
                  }}
                ></div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h3 className="card-title">
                      {
                        setCartItem.relationships.set[
                          "name" + langContentModifier
                        ]
                      }{" "}
                      -{" "}
                      {
                        setCartItem.relationships.set_sku_type[
                          "name" + langContentModifier
                        ]
                      }
                    </h3>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="float-start">
                        <strong>{t("general.unitPrice")}</strong>
                      </div>
                      <div className="float-end">
                        {numberFormat(
                          setCartItem.relationships.set_sku_type.price
                        )}
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="float-start">
                        <strong>{t("general.quantity")}</strong>
                      </div>
                      <div className="float-end">
                        {setCartItem.attributes.quantity}
                      </div>
                    </li>

                    <li className="list-group-item">
                      <div className="float-start">
                        <strong>{t("general.subTotal")}</strong>
                      </div>
                      <div className="float-end">
                        {numberFormat(
                          setCartItem.relationships.set_sku_type.price *
                            setCartItem.attributes.quantity
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        })}

      <table className="table table-responsive">
        {props.cart && (
          <tfoot>
            {props.productCartItems !== null && (
              <>
                <tr>
                  <td colSpan="4">
                    <BottleDiscountBar
                      numBottlesToPrice={totalBottlesToPrice}
                      cartTotal={cartTotal}
                      noDiscountTotal={noDiscountTotal}
                      msrpTotal={msrpTotal}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <FreeDeliveryBar
                    cartTotal={props.cart.attributes.sub_total}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ textAlign: "right" }}>
                    {t("general.subTotal")}:
                  </td>
                  <td style={{ textAlign: "right" }}>
                {numberFormat(props.cart.attributes.sub_total)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ textAlign: "right" }}>
                    {props.cart.attributes.coupon_discount_total > 0 &&
                      props.cart.relationships.cart_coupons &&
                      props.cart.relationships.cart_coupons.map(
                        (cart_coupon, idx) => (
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => props.couponRemove(cart_coupon.id)}
                            key={idx}
                          >
                            Remove Discount
                          </button>
                        )
                      )}
                    &nbsp;&nbsp;
                    {t("general.discount")}:
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {numberFormat(props.cart.attributes.coupon_discount_total)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ textAlign: "right" }}>
                    {t("general.deliveryFee")}:
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {numberFormat(props.cart.attributes.delivery_fee_total)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ textAlign: "right" }}>
                    {props.from === "order" && (
                      <strong>{t("general.paymentMade")}:</strong>
                    )}
                    {props.from === undefined && (
                      <strong>{t("general.currentPaymentDue")}:</strong>
                    )}
                  </td>
                  <td style={{ textAlign: "right" }}>
                <strong>{numberFormat(props.cart.attributes.total)}</strong>
                  </td>
                </tr>
              </>
            )}
          </tfoot>
        )}
      </table>
    </React.Fragment>
  );
};

export default CartSummary;

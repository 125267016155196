import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../../layouts/frontend/Footer';
import Header from '../../../../layouts/frontend/Header';
import Breadcrumb from '../../ui/Breadcrumb';
import WhyMembership from '../sections/WhyMembership';
import UserContext from '../../../../contexts/user-context';
import Loader from '../../ui/Loader';
import axios from 'axios';
import Swal from 'sweetalert2';
import Delivery from './partials/Delivery';
import PastDelivery from './partials/PastDelivery';
import '../../ui/cards/SetInfoCard.min.css';
import Helmet from 'react-helmet';

axios.defaults.withCredentials = true;

const Deliveries = (props) => {
  const { t } = useTranslation();
  const [ deliveries, setDeliveries ] = useState(null);
  const [ pastDeliveries, setPastDeliveries ] = useState(null);
  const userCtx = useContext(UserContext);
  const storedUserToken = localStorage.getItem('userToken');

  const getDeliveries = async () => {
    await axios.get(process.env.REACT_APP_API_URL + 'user/deliveries', {
      headers: {
        "Authorization": `Bearer ${storedUserToken}`
      }
    })
    .catch((err) => {
      Swal.fire(t("errorMessages.swalDefaultTitle"), err.response.data.message, "error");
    })
    .then((response) => {
      if (typeof response != "undefined") {
        setDeliveries(response.data.data);      
      } else {
        Swal.fire(t("errorMessages.swalDefaultTitle"), t("errorMessages.noData"), "error");
      }
    });
  };

  const getPastDeliveries = async () => {
    await axios.get(process.env.REACT_APP_API_URL + 'user/past_deliveries', {
      headers: {
        "Authorization": `Bearer ${storedUserToken}`
      }
    })
    .catch((err) => {
      Swal.fire(t("errorMessages.swalDefaultTitle"), err.response.data.message, "error");
    })
    .then((response) => {
      if (typeof response != "undefined") {
        setPastDeliveries(response.data.data);      
      } else {
        Swal.fire(t("errorMessages.swalDefaultTitle"), t("errorMessages.noData"), "error");
      }
    });
  };

  useEffect(() => {
    getDeliveries();
    getPastDeliveries();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("navigation.deliveries")} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb crumbs={[
          {text: t("navigation.home"), link: props.getLink("/")},
          {text: t("navigation.deliveries"), link: ''}
      ]} />

      <div className="liquor-law">{t('general.liquorLaw')}</div>
        
      {(userCtx.userIsLoggedIn === null || userCtx.userIsLoggedIn === false) && 
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      }

      {userCtx.userIsLoggedIn === true && 
        <section className="breadcrumb-spacing" id="user-account">
          <div className="section-content" style={{ paddingBottom: "100px" }}>
            <h1
              className="display-7 pb-2"
              dangerouslySetInnerHTML={{ __html: t("navigation.deliveries") }}
            />

            <div
              className="header-description"
              dangerouslySetInnerHTML={{ __html: t("deliveries.description") }}
            />

            <div className="container my-5">
              <div className="row">
                <div className="col-lg-6 pb-5">
                  <h3 className="pb-3">{t("deliveries.yourUpcomingDeliveries")}</h3>
                  {(deliveries === null || deliveries.length === 0) && <div>{t("deliveries.noUpcomingDeliveries")}</div>}
                  {deliveries && deliveries.map((delivery, dSubIdx) => 
                    <Delivery delivery={delivery} key={dSubIdx} />
                  )}
                </div>

                <div className="col-lg-6">
                  <h3 className="pb-3">{t("deliveries.yourPastDeliveries")}</h3>
                  {(pastDeliveries === null || pastDeliveries.length === 0) && <div>{t("deliveries.noPastDeliveries")}</div>}
                  {pastDeliveries && pastDeliveries.map((delivery, pDSubIdx) => 
                    <PastDelivery delivery={delivery} key={pDSubIdx} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>}

      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
}

export default Deliveries;
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import ReactFBPixel from "react-facebook-pixel";

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
      if (!window.location.href.includes("localhost")) {
        //ga4 
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
        setInitialized(true);

        //gtm
        const tagManagerArgs = {
          gtmId: 'GTM-5F37LHX'
        };
        TagManager.initialize(tagManagerArgs);

        //fb pixel
        const advancedMatching = {};
        const options = {
          autoConfig: true,
          debug: false
        };
        ReactFBPixel.init('6703591589656745', advancedMatching, options);
      }
  }, []);

  useEffect(() => {
      if (initialized) {
        const thePage = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: thePage });
      }

      ReactFBPixel.pageView();
  }, [initialized, location]);
};

export default useGaTracker;
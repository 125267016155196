import React from "react";
import DeliveryInfoSingle from "./DeliveryInfoSingle";
import DeliveryInfoSubscription from "./DeliveryInfoSubscription";

const SubscriptionDeliveryInfo = (props) => {
  return (
    <React.Fragment>
      {((props.dataSetSku === null || props.productCartItems) ||
        (props.dataSetSku &&
          props.dataSetSku.relationships.set_sku_type.type === "prepay")) && (
        <DeliveryInfoSingle />
      )}

      {props.dataSetSku &&
        props.dataSetSku.relationships.set_sku_type.type === "subscription" && (
          <DeliveryInfoSubscription dataSetSku={props.dataSetSku} />
      )}
    </React.Fragment>
  );
};

export default SubscriptionDeliveryInfo;

import React, { useContext, useEffect, useState } from "react";
import { Link as button, Link, NavLink } from "react-router-dom";
import imgLogo from "../../assets/frontend/assets/logo.png";
import LangSelector from "../../components/frontend/ui/LangSelector";
import { useTranslation } from "react-i18next";
import AccountButtons from "../../components/frontend/ui/AccountButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CartContext from "../../contexts/cart-context";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
    const { t } = useTranslation();
    const cartCtx = useContext(CartContext);

    return (
        <React.Fragment>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                    <div className="container-fluid">
                        <NavLink className="navbar-brand" to={props.getLink("/")}>
                            <img src={imgLogo} id="navbar-logo" alt="JustSaké" />
                        </NavLink>
                        {/* <div className="d-lg-none top-nav" style={{ marginTop: "-34px" }}><LangSelector /></div> */}
                        <div
                            className="d-lg-none top-nav"
                            style={{ position: "absolute", marginTop: "4px", right: "60px", top: "10px" }}
                        >
                            <button
                                onClick={cartCtx.showCartHandler}
                                style={{ background: "none", border: "0px", padding: "0px", margin: "0px" }}
                            >
                                <FontAwesomeIcon
                                    icon={faShoppingBasket}
                                    style={{ height: "18px", padding: "14px 13px 0px 13px", color: "#000" }}
                                />
                                <span
                                    className="position-relative translate-middle badge rounded-pill bg-danger"
                                    style={{ right: "5px", top: "-5px" }}
                                >
                                    {cartCtx.isCartEmpty() === true && "0"}
                                    {cartCtx.cart &&
                                        cartCtx.cart.items &&
                                        cartCtx.cart.items.length > 0 &&
                                        cartCtx.cart.items.length}
                                </span>
                            </button>
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            {" "}
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0" id="membership-nav">
                                {/* <li className="nav-item"><NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} aria-current="page" to={props.getLink("products")}>{t('navigation.shop')}</NavLink></li> */}
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        id="ourSets"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                        href="/"
                                    >
                                        {t("navigation.shop")}
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-start" data-bs-popper="none">
                                        <li>
                                            <Link className="dropdown-item" to={props.getLink("/")}>
                                                {t("general.home")}
                                            </Link>
                                        </li>
                                        {/* <li><Link className="dropdown-item" to={props.getLink("buy-more-and-save")}>{t('navigation.buyMoreSaveMore')}</Link></li>
                              <li><Link className="dropdown-item" to={props.getLink("be-a-sake-ambassador")}>{t('navigation.ambassadorProgram')}</Link></li> */}
                                        <li>
                                            <Link className="dropdown-item" to={props.getLink("products")}>
                                                {t("ourProducts.title")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        id="ourSets"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                        href="/"
                                    >
                                        {t("navigation.subscriptions")}
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-start" data-bs-popper="none">
                                        <li>
                                            <Link className="dropdown-item" to={props.getLink("subscriptions/home")}>
                                                {t("general.home")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to={props.getLink("how-it-works")}>
                                                {t("navigation.howItWorks")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to={props.getLink("what-is-in-a-box-set")}>
                                                {t("navigation.whatsInASet")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to={props.getLink("start-your-journey")}>
                                                {t("ourSubscriptionSets.title")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0" id="info-nav">
                                <li className="nav-item">
                                    <LangSelector />
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                        aria-current="page"
                                        to={props.getLink("about")}
                                    >
                                        {t("navigation.aboutUs")}
                                    </NavLink>
                                </li>
                                {/* <AccountButtons getLink={props.getLink} /> */}
                                <li className="nav-item">
                                    <button
                                        onClick={cartCtx.showCartHandler}
                                        style={{ background: "none", border: "0px", padding: "0px", margin: "0px" }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faShoppingBasket}
                                            style={{ height: "18px", padding: "14px 13px 0px 13px", color: "#000" }}
                                        />
                                        <span
                                            className="position-relative translate-middle badge rounded-pill bg-danger"
                                            style={{ right: "5px", top: "-5px" }}
                                        >
                                            {cartCtx.isCartEmpty() === true && "0"}
                                            {cartCtx.cart &&
                                                cartCtx.cart.items &&
                                                cartCtx.cart.items.length > 0 &&
                                                cartCtx.cart.items.length}
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </React.Fragment>
    );
};

export default Header;

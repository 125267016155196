import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Footer from '../../../layouts/frontend/Footer';
import Header from '../../../layouts/frontend/Header';
import Breadcrumb from '../ui/Breadcrumb';
import WhyMembership from './sections/WhyMembership';

const About = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('navigation.aboutUs')} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb crumbs={[
          {text: t("navigation.home"), link: props.getLink("/")},
          {text: t("aboutUs.titlePlain"), link: ''}
      ]} />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="membership-push">
          <div className="jumbotron jumbotron-fluid">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col jumbo-right">
                          <div className="banner-bg">
                              <div className="banner-content">
                                  <h2 className="display-6" dangerouslySetInnerHTML={{ __html: t("aboutUs.title") }} />
                                  <div className="header-description mt-3 mb-4" dangerouslySetInnerHTML={{ __html: t("aboutUs.subTitle") }} />
                                  <p className="mb-4" dangerouslySetInnerHTML={{ __html: t("aboutUs.description") }} />
                              </div>
                          </div>
                      </div>
                      <div className="col jumbo-left jumbo-left-about"></div>
                  </div>
              </div>
          </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
}

export default About;
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../../assets/frontend/js/numberFormat";
import CartContext from "../../../../../contexts/cart-context";

const AddToCartButtons = (props) => {
  const { i18n } = useTranslation();
  const langContentModifier = i18n.language === "en" ? "" : "_zh";
  const product = props.product;
  const cartCtx = useContext(CartContext);
  const totalBottlesToPrice =
    cartCtx.cart.total_bottles_to_price > 0
      ? cartCtx.cart.total_bottles_to_price >=
        process.env.REACT_APP_MAX_BOTTLES_TO_PRICE
        ? 11
        : cartCtx.cart.total_bottles_to_price
      : 0;

  const addToCartHandler = (e) => {
    e.preventDefault();
    // console.log(e.target.getAttribute("data-product_skus_id"));
    cartCtx.showCartHandler();
    cartCtx.updateCart(
      null,
      e.target.getAttribute("data-product_skus_id"),
      1,
      "increment"
    );
  };

  return (
    <>
      <table style={{ width: "100%" }}>
        <tbody>
          {product?.relationships.product_skus.map((productSku, key) => (
            <tr key={key}>
              <td style={{ width: "45px", textAlign: "left" }}>
                {
                  productSku.relationships.product_type[
                    "name" + langContentModifier
                  ]
                }
              </td>
              <td
                style={{
                  width: "100%",
                  textAlign: "left",
                  paddingLeft: "10px",
                  lineHeight: "1rem"
                }}
              >
                {productSku.attributes.msrp_price !==
                  productSku.attributes.price_data[totalBottlesToPrice]
                    .price && (
                  <>
                    <span
                      style={{
                        color: "#b1b1b1",
                        fontSize: "0.7rem",
                        textDecoration: "line-through",
                      }}
                    >
                      {numberFormat(productSku.attributes.msrp_price)}
                    </span>
                    &nbsp;&nbsp;
                    <span style={{ color: "red", fontWeight: "bold", fontSize: "0.9rem" }}>
                      {numberFormat(
                        productSku.attributes.price_data[totalBottlesToPrice]
                          .price
                      )}
                    </span>
                    &nbsp;&nbsp;
                    <span
                      className="badge bg-success"
                      style={{
                        fontSize: "0.6rem",
                        display: "inline-block"
                      }}
                    >
                      {/* {Math.floor((1 - (productSku.attributes.price_data[totalBottlesToPrice]
                          .price/productSku.attributes.msrp_price)) * 100)}%&nbsp;OFF */}
                      {numberFormat(Math.floor(productSku.attributes.msrp_price - productSku.attributes.price_data[totalBottlesToPrice]
                          .price))}&nbsp;OFF!
                    </span>
                  </>
                )}
                {productSku.attributes.msrp_price ===
                  productSku.attributes.price_data[totalBottlesToPrice]
                    .price && (
                  <span>
                    {numberFormat(
                      productSku.attributes.price_data[totalBottlesToPrice]
                        .price
                    )}
                  </span>
                )}
              </td>
              <td style={{ textAlign: "right" }}>
                {(productSku.attributes.ordering_stock_check === 0 ||
                  productSku.attributes.supplier_stock_level > 0) && (
                  <button
                    onClick={addToCartHandler}
                    data-product_skus_id={productSku.id}
                    className="btn btn-sm btn-warning"
                  >
                    +
                  </button>
                )}
                {productSku.attributes.ordering_stock_check === 1 &&
                  productSku.attributes.supplier_stock_level <= 0 && (
                    <button
                      className="btn btn-sm btn-secondary"
                      disabled={true}
                      style={{
                        fontSize: "0.6rem",
                        fontWeight: "bold",
                      }}
                    >
                      No Stock
                    </button>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AddToCartButtons;

import React from "react";
import { useTranslation } from "react-i18next";

const PaymentMethods = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div 
        className="card"
        style={{ 
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0
        }}
      >
        <div className="card-header">{t("general.payments")}</div>
        {props.paymentMethods.length > 0 && <div className="card-body">
          <h5 className="card-title">{t("general.savedCards")}:</h5>
          <div className="card-text">
            {!props.paymentMethods && "Loading..."}
            {props.paymentMethods &&
              props.paymentMethods.map((paymentMethod, idx) => {
                return (
                  <div
                    className="form-check"
                    key={paymentMethod.keyid ? paymentMethod.keyid : idx}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentMethod"
                      value={paymentMethod.id}
                      id={`paymentMethod${idx}`}
                      defaultChecked={paymentMethod.is_default === 1}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`paymentMethod${idx}`}
                    >
                      {paymentMethod.card_brand} - xxxx-xxxx-xxxx-
                      {paymentMethod.card_last4} -{" "}
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={() => {
                          props.removePaymentMethodHandler(paymentMethod.id);
                        }}
                      >
                        {t("general.remove")}
                      </button>
                    </label>
                  </div>
                );
              })}
          </div>
        </div>}
      </div>
    </React.Fragment>
  );
};

export default PaymentMethods;

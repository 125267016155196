import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../layouts/frontend/Footer";
import Header from "../../../layouts/frontend/Header";
import Breadcrumb from "../ui/Breadcrumb";
import WhyMembership from "./sections/WhyMembership";

const TermsAndConditions = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          { text: t("footer.termsAndConditions"), link: "" },
        ]}
      />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="membership-push">
        <div className="jumbotron jumbotron-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col jumbo-right">
                <div className="banner-bg">
                  <div className="banner-content">
                    <h2
                      className="display-6"
                      dangerouslySetInnerHTML={{
                        __html: t("footer.termsAndConditions"),
                      }}
                    />
                    {i18n.language === "en" && (
                      <div>
                        <h1>OVERVIEW</h1>
                        <ul>
                          <li>
                            <p>
                              The JustSaké website is operated by JUST ALCOHOL
                              COMPANY LIMITED. Throughout the site, the terms
                              &ldquo;we&rdquo;, &ldquo;us&rdquo; and
                              &ldquo;our&rdquo; refer to JustSaké. We offer
                              this website, including all information, tools and
                              services available from this site to you, the
                              user, conditioned upon your acceptance of all
                              terms, conditions, policies and notices stated
                              here.
                            </p>
                          </li>
                          <li>
                            <p>
                              By visiting our site and/ or purchasing something
                              from us, you engage in our &ldquo;Service&rdquo;
                              and agree to be bound by the following terms and
                              conditions (&ldquo;Terms of Service&rdquo;,
                              &ldquo;Terms&rdquo;), including those additional
                              terms and conditions and policies referenced
                              herein and/or available by hyperlink. These Terms
                              of Service apply to all users of the site,
                              including without limitation users who are
                              browsers, vendors, customers, merchants, and/ or
                              contributors of content.
                            </p>
                          </li>
                          <li>
                            <p>
                              Please read these Terms of Service carefully
                              before accessing or using our website. By
                              accessing or using any part of the site, you agree
                              to be bound by these Terms of Service. If you do
                              not agree to all the terms and conditions of this
                              agreement, then you may not access the website or
                              use any services. If these Terms of Service are
                              considered an offer, acceptance is expressly
                              limited to these Terms of Service.
                            </p>
                          </li>
                          <li>
                            <p>
                              Any new features or tools which are added to the
                              current store shall also be subject to the Terms
                              of Service. You can review the most current
                              version of the Terms of Service at any time on
                              this page. We reserve the right to update, change
                              or replace any part of these Terms of Service by
                              posting updates and/or changes to our website. It
                              is your responsibility to check this page
                              periodically for changes. Your continued use of or
                              access to the website following the posting of any
                              changes constitutes acceptance of those changes.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>1 | ONLINE STORE TERMS</h1>
                        <ul>
                          <li>
                            <p>
                              By agreeing to these Terms of Service, you
                              represent that you are at least the age of
                              majority (18) in your state or province of
                              residence, or that you are the age of majority in
                              your state or province of residence and you have
                              given us your consent to allow any of your minor
                              dependents to use this site. All orders placed by
                              minors will be canceled.
                            </p>
                          </li>
                          <li>
                            <p>
                              You may not use our products for any illegal or
                              unauthorized purpose nor may you, in the use of
                              the Service, violate any laws in your jurisdiction
                              (including but not limited to copyright laws).
                            </p>
                          </li>
                          <li>
                            <p>
                              You must not transmit any worms or viruses or any
                              code of a destructive nature. A breach or
                              violation of any of the Terms will result in
                              immediate termination of your Services.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>2 | YOUR ACCOUNT</h1>
                        <ul>
                          <li>
                            <p>
                              You are required to create an account in order to
                              purchase products from us. You must ensure that
                              any personal information you give to us when
                              creating an account is accurate and up-to-date.
                              All personal information that you give to us will
                              be treated in accordance with our Privacy Policy.
                            </p>
                          </li>
                          <li>
                            <p>
                              When you create an account, you agree to provide
                              current, complete and accurate purchase and
                              account information for all purchases made at our
                              store. You agree to promptly update your account
                              and other information, including your email
                              address and credit card numbers and expiration
                              dates so that we can complete your transactions
                              and contact you as needed.
                            </p>
                          </li>
                          <li>
                            <p>
                              It is your responsibility to keep your account
                              details confidential. You are liable for all
                              activity on your account, including any purchases
                              made using your account details.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>3 | ORDERS</h1>
                        <ul>
                          <li>
                            <p>
                              At the time you place your order you are agreeing
                              to pay the total amount - including any delivery
                              fees and any other charges or taxes that apply to
                              your location.
                            </p>
                          </li>
                          <li>
                            <p>
                              It is your responsibility to check the order
                              details, including selected products and pricing
                              (including any discount codes you wish to apply)
                              before you submit your order through our website.
                            </p>
                          </li>
                          <li>
                            <p>
                              When you order and pay on the Site and your
                              payment has been validated, we will send you an
                              email receipt with order details, which will
                              include an order number, the delivery and billing
                              addresses, and a description of what was ordered.
                            </p>
                          </li>
                          <li>
                            <p>
                              Under Hong Kong laws, alcohol can only be sold or
                              supplied to persons aged 18 years or older. By
                              placing an order with us, you confirm that you are
                              at least 18 years old. You agree that it is your
                              responsibility to consume alcohol in accordance
                              with the law and responsibly.
                            </p>
                          </li>
                          <li>
                            <p>
                              We reserve the right to refuse or cancel any order
                              placed with us if we feel it has violated any of
                              these Terms &amp; Conditions or in violation of
                              Hong Kong law.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>4 | PRICE AND PAYMENTS</h1>
                        <ul>
                          <li>
                            <p>
                              You must pay us the purchase price of each product
                              you order plus any applicable delivery costs based
                              on the delivery options selected by you as set out
                              on the website in accordance with this clause.
                            </p>
                          </li>
                          <li>
                            <p>
                              You must pay the Price via credit card or any
                              other payment methods set out on the site such as
                              Stripe, Apple Pay, PayPal or any other payment
                              option listed.
                            </p>
                          </li>
                          <li>
                            <p>
                              Bank payments or any other delayed payments will
                              not be accepted.
                            </p>
                          </li>
                          <li>
                            <p>
                              All amounts are stated in Hong Kong dollars and we
                              display delivery costs separately from the
                              purchase price.
                            </p>
                          </li>
                          <li>
                            <p>
                              You must not pay, or attempt to pay, by fraudulent
                              or unlawful means.
                            </p>
                          </li>
                          <li>
                            <p>
                              If any payment in relation to a subscription fee
                              has not been made as set out on the website or in
                              these terms, we may (at our absolute discretion)
                              immediately cease supplying the products.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>5 | DISCOUNT CODES</h1>
                        <ul>
                          <li>
                            <p>
                              We may from time to time offer promotional
                              discount codes (ie: vouchers, coupon code, etc.).
                              To claim the discount, you must enter the
                              promotional discount code at the time you submit
                              your order.
                            </p>
                          </li>
                          <li>
                            <p>
                              Discount codes can only be used once per customer
                              (unless stated otherwise), and only one discount
                              code may be used per order.
                            </p>
                          </li>
                          <li>
                            <p>
                              Discount codes cannot be applied to previous
                              purchases. Vouchers cannot be redeemed for cash or
                              combined with other offers or credits. Discount
                              codes or vouchers are not valid for resale and
                              have no cash value. Discount codes sent to
                              individuals are valid for the intended recipient
                              only. Void where prohibited, taxed or restricted.
                            </p>
                          </li>
                          <li>
                            <p>
                              JustSaké reserves the right to change or limit
                              Discount codes in its sole discretion. JustSaké
                              has no liability for any problems or inability to
                              use the discount codes.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>6 | FREE GIFTS</h1>
                        <ul>
                          <li>
                            <p>
                              JustSaké is now offering a free gift for
                              subscriptions that are greater than or equal to 3
                              months of prepayment. &nbsp;Free gifts cannot be
                              applied to previous purchases.
                            </p>
                          </li>
                          <li>
                            <p>
                              Gift items are non-refundable and cannot be
                              redeemed for cash.
                            </p>
                          </li>
                          <li>
                            <p>
                              JustSaké reserves the right to change or limit
                              free gifts in its sole discretion and is not
                              responsible if the free gift does not meet your
                              expectations. All gifts are subject to
                              availability.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>7 | AVAILABILITY, PRODUCTS &amp; SERVICES</h1>
                        <ul>
                          <li>
                            <p>
                              All purchases made through the Site are subject to
                              availability. We do our best to keep products in
                              stock and to keep the website up to date with the
                              availability of products.
                            </p>
                          </li>
                          <li>
                            <p>
                              We reserve the right to cancel, at any time before
                              delivery and for whatever reason, an order that we
                              have previously accepted, including where there is
                              a considerable delay in dispatching your order, if
                              for any reason we cannot supply the products you
                              order (for example for an event beyond our
                              reasonable control) or if products ordered were
                              subject to an error on our website (for example in
                              relation to a description, price or image). We
                              will contact you using the details you provided
                              when you placed your order.
                            </p>
                          </li>
                          <li>
                            <p>
                              Certain products or services may be available
                              exclusively online through the website. These
                              products or services may have limited quantities
                              and are subject to return or exchange only
                              according to our Return Policy.
                            </p>
                          </li>
                          <li>
                            <p>
                              We have made every effort to display as accurately
                              as possible the colors and images of our products
                              that appear at the store. We cannot guarantee that
                              your computer monitor&apos;s display of any color
                              will be accurate.
                            </p>
                          </li>
                          <li>
                            <p>
                              We reserve the right, but are not obligated, to
                              limit the sales of our products or Services to any
                              person, geographic region or jurisdiction. We may
                              exercise this right on a case-by-case basis. We
                              reserve the right to limit the quantities of any
                              products or services that we offer. All
                              descriptions of products or product pricing are
                              subject to change at any time without notice, at
                              the sole discretion of us. We reserve the right to
                              discontinue any product at any time. Any offer for
                              any product or service made on this site is void
                              where prohibited.
                            </p>
                          </li>
                          <li>
                            <p>
                              We do not warrant that the quality of any
                              products, services, information, or other material
                              purchased or obtained by you will meet your
                              expectations, or that any errors in the service
                              will be corrected.
                            </p>
                          </li>
                          <li>
                            <p>
                              If you are not 100% satisfied with the taste of
                              one of our products, please kindly let us know via
                              email at: hello@justsake.com and we will do
                              everything we can (within reason) to remedy the
                              issue.
                            </p>
                          </li>
                          <li>
                            <p>
                              Prices for our products are subject to change
                              without notice. We reserve the right at any time
                              to modify or discontinue the Service (or any part
                              or content thereof) without notice at any time. We
                              shall not be liable to you or any third-party for
                              any modification, price change, suspension or
                              discontinuance of the Service.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>8 | DELIVERY, TITLE &amp; RISK</h1>
                        <ul>
                          <li>
                            <p>
                              1-4 Day Delivery: Free delivery on all orders with
                              no minimum purchase for ALL of Hong Kong, except
                              for the following places which we do not deliver
                              to: Cheung Chau, Peng Chau, Ma Wan, Lantau Island,
                              Tung Chung and Lamma Island.
                            </p>
                          </li>
                          <li>
                            <p>
                              We will deliver the products to the delivery
                              address you provide when making your order. We
                              deliver to all of Hong Kong (Except Cheung Chau,
                              Peng Chau, Ma Wan, Lantau Island, Tung Chung and
                              Lamma Island) and if we cannot deliver to you, a
                              JustSaké team member will notify you as soon as
                              possible. Please inquire for international
                              shipping information.
                            </p>
                          </li>
                          <li>
                            <p>
                              It is your responsibility to check the delivery
                              details and pricing, and be aware of any fees that
                              could be incurred before you submit your order
                              through our website.
                            </p>
                          </li>
                          <li>
                            <p>
                              We always try to send out your order as soon as
                              possible, but some things are out of our control.
                              Any delivery periods displayed or advertised by us
                              are estimates only and JustSaké is not
                              responsible for any delays that could arise.
                            </p>
                          </li>
                          <li>
                            <p>
                              We may deliver your order using a range of
                              delivery methods or courier services.
                            </p>
                          </li>
                          <li>
                            <p>
                              If you need to change the delivery address or have
                              a delivery request, please notify us immediately
                              in writing. If you change your delivery address
                              after your order has left our warehouse, we are no
                              longer able to modify the delivery address and
                              your order will be delivered to the original
                              address.
                            </p>
                          </li>
                          <li>
                            <p>
                              We offer free delivery to all locations in Hong
                              Kong, but JustSaké is not responsible for any
                              additional costs or delays should you enter the
                              wrong address or want to make changes after your
                              order has been passed on to our delivery agents.
                            </p>
                          </li>
                          <li>
                            <p>
                              We will not deliver any orders without first
                              receiving payment and in the event, there is a
                              problem with processing your payment, a JustSaké
                              team member will contact you to sort the issue.
                            </p>
                          </li>
                          <li>
                            <p>
                              Once items are delivered to the delivery address
                              provided, JustSaké is no longer liable for any
                              liabilities, damages, or losses, and all risks are
                              passed onto you.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>&nbsp;</p>
                        <h1>9 | REFUND &amp; RETURN POLICY</h1>
                        <p>
                          If you are not 100% satisfied with your order, please
                          kindly let us know via email at: aiya@justsake.com and
                          we will do everything we can (within reason) to remedy
                          the issue.
                        </p>
                        <p>RETURNS:</p>
                        <ul>
                          <li>
                            <p>
                              Our policy lasts 14 days. If 14 days have gone by
                              since your purchase, unfortunately, we can&rsquo;t
                              offer you a refund or exchange.
                            </p>
                          </li>
                          <li>
                            <p>
                              To be eligible for a return, your item must be
                              unopened or unused and in the same condition that
                              you received it. It must also be in the original
                              packaging.
                            </p>
                          </li>
                          <li>
                            <p>
                              Open (unscrewed/uncorked) bottles or bottles with
                              a damaged label cannot be returned.
                            </p>
                          </li>
                          <li>
                            <p>
                              Gift cards cannot be returned or redeemed for
                              cash.
                            </p>
                          </li>
                          <li>
                            <p>
                              If you need to return an item, send us a friendly
                              email at aiya@justsake.com
                            </p>
                          </li>
                          <li>
                            <p>
                              To process your return, we require your order
                              number, valid reason for the return, and receipt
                              or proof of purchase.
                            </p>
                          </li>
                          <li>
                            <p>
                              Please do not send your purchase back without
                              prior notice.
                            </p>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>
                          <strong>REFUNDS:</strong>
                        </p>
                        <ul>
                          <li>
                            <p>
                              Once your return is received and inspected, we
                              will send you an email to notify you that we have
                              received your returned item. We will also notify
                              you of the approval or rejection of your refund.
                            </p>
                          </li>
                          <li>
                            <p>
                              If you are approved, then your refund will be
                              processed, and a credit will automatically be
                              applied to your credit card or original method of
                              payment, within a certain amount of days.
                            </p>
                          </li>
                          <li>
                            <p>
                              Only regular priced items may be refunded,
                              unfortunately, sale items cannot be refunded. Bulk
                              products such as Saké Boxes cannot be returned as
                              single bottles.
                            </p>
                          </li>
                          <li>
                            <p>
                              Free gift items cannot be returned or redeemed for
                              cash.
                            </p>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>
                          <strong>EXCHANGES:</strong>
                        </p>
                        <ul>
                          <li>
                            <p>
                              We only replace items if they are corked and
                              unopened. If you need to exchange it for the same
                              item, send us an email at{" "}
                              <a href="mailto:aiya@justsake.com">
                                aiya@justsake.com
                              </a>
                              .
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>10 | SAKÉ &nbsp;SUBSCRIPTIONS</h1>
                        <ul>
                          <li>
                            <p>
                              When you place an order for a Saké Subscription,
                              you can choose from a variety of Saké Sets on our
                              platform. &nbsp;The set you choose will provide
                              information on what to expect in the set. &nbsp;
                              Your subscription will automatically renew each
                              billing cycle based on your chosen Subscription.
                              &nbsp; Your billing cycle is set on the day you
                              make your first payment. &nbsp;For example, if you
                              subscribed to a monthly subscription and made your
                              first payment on the 3rd of March, your next
                              billing date is the 3rd of April.
                            </p>
                          </li>
                          <li>
                            <p>
                              The selection of products to be included in each
                              subscription order will be decided by the JUST
                              SAKÉ team.
                            </p>
                          </li>
                          <li>
                            <p>
                              Your Subscription automatically continues until
                              canceled in accordance with the
                              &lsquo;Cancellation&rsquo; clause.
                            </p>
                          </li>
                          <li>
                            <p>
                              you are responsible for reviewing the pricing
                              schedule, inclusions and exclusions associated
                              with your Subscription.
                            </p>
                          </li>
                          <li>
                            <p>
                              We don&apos;t usually change our subscription
                              prices, but if at any point we do modify our
                              prices you will be notified via email, text
                              message or phone call. If you do not agree to the
                              price change, you may cancel your Subscription in
                              accordance with the &lsquo;Cancellation&rsquo;
                              clause.
                            </p>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>
                          <strong>CANCELLATION:</strong>
                        </p>
                        <ul>
                          <li>
                            <p>
                              You can cancel your saké subscription at any time
                              by updating your account on our website. You must
                              notify us in writing and/or update your account at
                              least 15 business days prior to the next billing
                              cycle. If you cancel your subscription less than
                              15 business days before the next Billing Date, you
                              will be charged the subscription fee for the next
                              billing cycle and your cancellation of the
                              Subscription will be effective for the following
                              period.
                            </p>
                          </li>
                          <li>
                            <p>
                              For monthly subscriptions, we have a minimum of 2
                              months of subscription. &nbsp;
                            </p>
                          </li>
                          <li>
                            <p>
                              We may immediately suspend, terminate or limit
                              your access to and use of the Site and terminate
                              these Terms. If you breach the Terms (including
                              your obligation to pay the subscription fee) and
                              the breach cannot be remedied or is not remedied
                              within 5 business days of us notifying you of the
                              breach.
                            </p>
                          </li>
                          <li>
                            <p>
                              If your saké subscription is canceled and you
                              later decide to re-subscribe, we are not able
                              honor past subscription prices, so you will be
                              charged at the then-current subscription fee.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>11 | INTELLECTUAL PROPERTY</h1>
                        <ul>
                          <li>
                            <p>
                              All intellectual property (including copyright)
                              developed, adapted, modified or created by us or
                              our personnel (including in connection with the
                              Terms and the Site) will at all times vest, or
                              remain vested, in us.
                            </p>
                          </li>
                          <li>
                            <p>
                              You must not, without our prior written consent:
                            </p>
                          </li>
                          <li>
                            <p>
                              copy or use, in whole or in part, any of our
                              intellectual property; including images,
                              copywriting, or proposals.
                            </p>
                          </li>
                          <li>
                            <p>
                              reproduce, retransmit, distribute, disseminate,
                              sell, publish, broadcast or circulate any of our
                              intellectual property to any third party;
                            </p>
                          </li>
                          <li>
                            <p>
                              breach any intellectual property rights connected
                              with the Site or the products, including (without
                              limitation) altering or modifying any of our
                              intellectual property; causing any of our
                              intellectual property to be framed or embedded in
                              another website; or creating derivative works from
                              any of our intellectual property.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>12 | USER CONTENT</h1>
                        <ul>
                          <li>
                            <p>
                              You may be permitted to post, create, upload,
                              publicize or otherwise submit content, such as
                              reviews, comments, pictures and videos. You must
                              submit User Content in accordance with these
                              Terms.
                            </p>
                          </li>
                          <li>
                            <p>
                              We ask you to limit your discussions to topics
                              which are relevant to the Site and the products.
                            </p>
                          </li>
                          <li>
                            <p>
                              We reserve the right to remove any reviews or
                              comments which we, in our sole discretion, deem to
                              be in breach to any of our terms and anything we
                              deem to be inappropriate.
                            </p>
                          </li>
                          <li>
                            <p>
                              We are not responsible for the conduct of any
                              other user of our Site or social media accounts.
                            </p>
                          </li>
                          <li>
                            <p>
                              By making available any user content on our Site
                              or social media, you grant to us a worldwide,
                              irrevocable, perpetual, non-exclusive,
                              transferable, royalty-free license to use the User
                              Content, with the right to use, view, copy, adapt,
                              modify, distribute, license, transfer,
                              communicate, publicly display, publicly perform,
                              transmit, stream, broadcast, access, or otherwise
                              exploit such User Content on, through or by means
                              of our Site.
                            </p>
                          </li>
                          <li>
                            <p>
                              You agree that you are solely responsible for all
                              User Content that you make available on or through
                              our Site or social media. You represent and
                              warrant that you are either the sole and exclusive
                              owner of all User Content or you have all rights,
                              licenses, consents and releases that are necessary
                              to grant to us the rights in such User Content (as
                              contemplated by these Terms);
                            </p>
                          </li>
                          <li>
                            <p>
                              If at our request, you send certain specific
                              submissions (for example contest entries) or
                              without a request from us you send creative ideas,
                              suggestions, proposals, plans, or other materials,
                              whether online, by email, by postal mail, or
                              otherwise (collectively, &apos;comments&apos;),
                              you agree that we may, at any time, without
                              restriction, edit, copy, publish, distribute,
                              translate and otherwise use in any medium any
                              comments that you forward to us. We are and shall
                              be under no obligation (1) to maintain any
                              comments in confidence; (2) to pay compensation
                              for any comments; or (3) to respond to any
                              comments.
                            </p>
                          </li>
                          <li>
                            <p>
                              We may, but have no obligation to, monitor, edit
                              or remove content that we determine in our sole
                              discretion are unlawful, offensive, threatening,
                              libellous, defamatory, pornographic, obscene or
                              otherwise objectionable or violates any
                              party&rsquo;s intellectual property or these Terms
                              of Service.
                            </p>
                          </li>
                          <li>
                            <p>
                              You agree that your comments will not violate any
                              right of any third-party, including copyright,
                              trademark, privacy, personality or other personal
                              or proprietary rights. You further agree that your
                              comments will not contain libelous or otherwise
                              unlawful, abusive or obscene material, or contain
                              any computer virus or other malware that could in
                              any way affect the operation of the Service or any
                              related website.
                            </p>
                          </li>
                          <li>
                            <p>
                              You may not use a false e‑mail address, pretend to
                              be someone other than yourself, or otherwise
                              mislead us or third-parties as to the origin of
                              any comments. You are solely responsible for any
                              comments you make and their accuracy. We take no
                              responsibility and assume no liability for any
                              comments posted by you or any third-party.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>
                          13 | DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
                        </h1>
                        <ul>
                          <li>
                            <p>
                              We do not guarantee, represent or warrant that
                              your use of our service will be uninterrupted,
                              timely, secure or error-free.
                            </p>
                          </li>
                          <li>
                            <p>
                              We do not warrant that the results that may be
                              obtained from the use of the service will be
                              accurate or reliable.
                            </p>
                          </li>
                          <li>
                            <p>
                              You agree that from time to time we may remove the
                              service for indefinite periods of time or cancel
                              the service at any time, without notice to you.
                            </p>
                          </li>
                          <li>
                            <p>
                              You expressly agree that your use of, or inability
                              to use, the service is at your sole risk. The
                              service and all products and services delivered to
                              you through the service are (except as expressly
                              stated by us) provided &apos;as is&apos; and
                              &apos;as available&apos; for your use, without any
                              representation, warranties or conditions of any
                              kind, either express or implied, including all
                              implied warranties or conditions of
                              merchantability, merchantable quality, fitness for
                              a particular purpose, durability, title, and
                              non-infringement.
                            </p>
                          </li>
                          <li>
                            <p>
                              In no case shall JustSaké, our directors,
                              officers, employees, affiliates, agents,
                              contractors, interns, suppliers, service providers
                              or licensors be liable for any injury, loss,
                              claim, or any direct, indirect, incidental,
                              punitive, special, or consequential damages of any
                              kind, including, without limitation lost profits,
                              lost revenue, lost savings, loss of data,
                              replacement costs, or any similar damages, whether
                              based in contract, tort (including negligence),
                              strict liability or otherwise, arising from your
                              use of any of the service or any products procured
                              using the service, or for any other claim related
                              in any way to your use of the service or any
                              product, including, but not limited to, any errors
                              or omissions in any content, or any loss or damage
                              of any kind incurred as a result of the use of the
                              service or any content (or product) posted,
                              transmitted, or otherwise made available via the
                              service, even if advised of their possibility.
                              Because some states or jurisdictions do not allow
                              the exclusion or the limitation of liability for
                              consequential or incidental damages, in such
                              states or jurisdictions, our liability shall be
                              limited to the maximum extent permitted by law.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>14 | PERSONAL INFORMATION</h1>
                        <ul>
                          <li>
                            <p>
                              Your submission of personal information through
                              the store is governed by our Privacy Policy.
                              Scroll Down to view our Privacy Policy.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>15 | OPTIONAL TOOLS &amp; THIRD PARTY LINKS</h1>
                        <ul>
                          <li>
                            <p>
                              Your submission of personal information through
                              the store is governed by our Privacy Policy.
                              Scroll Down to view our Privacy Policy.
                            </p>
                          </li>
                          <li>
                            <p>
                              We may provide you with access to third-party
                              tools over which we neither monitor nor have any
                              control nor input.
                            </p>
                          </li>
                          <li>
                            <p>
                              You acknowledge and agree that we provide access
                              to such tools &rdquo;as is&rdquo; and &ldquo;as
                              available&rdquo; without any warranties,
                              representations or conditions of any kind and
                              without any endorsement. We shall have no
                              liability whatsoever arising from or relating to
                              your use of optional third-party tools.
                            </p>
                          </li>
                          <li>
                            <p>
                              Any use by you of optional tools offered through
                              the site is entirely at your own risk and
                              discretion and you should ensure that you are
                              familiar with and approve of the terms on which
                              tools are provided by the relevant third-party
                              provider(s).
                            </p>
                          </li>
                          <li>
                            <p>
                              We may also, in the future, offer new services
                              and/or features through the website (including,
                              the release of new tools and resources). Such new
                              features and/or services shall also be subject to
                              these Terms of Service.
                            </p>
                          </li>
                          <li>
                            <p>
                              Certain content, products and services available
                              via our Service may include materials from
                              third-parties.
                            </p>
                          </li>
                          <li>
                            <p>
                              Third-party links on this site may direct you to
                              third-party websites that are not affiliated with
                              us. We are not responsible for examining or
                              evaluating the content or accuracy and we do not
                              warrant and will not have any liability or
                              responsibility for any third-party materials or
                              websites, or for any other materials, products, or
                              services of third-parties.
                            </p>
                          </li>
                          <li>
                            <p>
                              We are not liable for any harm or damages related
                              to the purchase or use of goods, services,
                              resources, content, or any other transactions made
                              in connection with any third-party websites.
                              Please review carefully the third-party&apos;s
                              policies and practices and make sure you
                              understand them before you engage in any
                              transaction. Complaints, claims, concerns, or
                              questions regarding third-party products should be
                              directed to the third-party.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>16 | INDEMNIFICATION</h1>
                        <ul>
                          <li>
                            <p>
                              You agree to indemnify, defend and hold harmless
                              JustSaké and our parent, subsidiaries,
                              affiliates, partners, officers, directors, agents,
                              contractors, licensors, service providers,
                              subcontractors, suppliers, interns and employees,
                              harmless from any claim or demand, including
                              reasonable attorneys&rsquo; fees, made by any
                              third-party due to or arising out of your breach
                              of these Terms of Service or the documents they
                              incorporate by reference or your violation of any
                              law or the rights of a third-party.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>17 | INDEMNIFICATION</h1>
                        <ul>
                          <li>
                            <p>
                              The obligations and liabilities of the parties
                              incurred prior to the termination date shall
                              survive the termination of this agreement for all
                              purposes.
                            </p>
                          </li>
                          <li>
                            <p>
                              These Terms of Service are effective unless and
                              until terminated by either you or us. You may
                              terminate these Terms of Service at any time by
                              notifying us that you no longer wish to use our
                              Services, or when you cease using our site.
                            </p>
                          </li>
                          <li>
                            <p>
                              If in our sole judgment you fail, or we suspect
                              that you have failed, to comply with any term or
                              provision of these Terms of Service, we also may
                              terminate this agreement at any time without
                              notice and you will remain liable for all amounts
                              due up to and including the date of termination;
                              and/or accordingly may deny you access to our
                              Services (or any part thereof).
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>18 | TERMINATION</h1>
                        <ul>
                          <li>
                            <p>
                              The obligations and liabilities of the parties
                              incurred prior to the termination date shall
                              survive the termination of this agreement for all
                              purposes.
                            </p>
                          </li>
                          <li>
                            <p>
                              These Terms of Service are effective unless and
                              until terminated by either you or us. You may
                              terminate these Terms of Service at any time by
                              notifying us that you no longer wish to use our
                              Services, or when you cease using our site.
                            </p>
                          </li>
                          <li>
                            <p>
                              If in our sole judgment you fail, or we suspect
                              that you have failed, to comply with any term or
                              provision of these Terms of Service, we also may
                              terminate this agreement at any time without
                              notice and you will remain liable for all amounts
                              due up to and including the date of termination;
                              and/or accordingly may deny you access to our
                              Services (or any part thereof).
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>19 | GENERAL CONDITIONS</h1>
                        <ul>
                          <li>
                            <p>
                              We reserve the right to refuse service to anyone
                              for any reason at any time.
                            </p>
                          </li>
                          <li>
                            <p>
                              You understand that your content (not including
                              credit card information), may be transferred
                              unencrypted and involve (a) transmissions over
                              various networks; and (b) changes to conform and
                              adapt to technical requirements of connecting
                              networks or devices. Credit card information is
                              always encrypted during transfer over networks.
                            </p>
                          </li>
                          <li>
                            <p>
                              You agree not to reproduce, duplicate, copy, sell,
                              resell or exploit any portion of the Service, use
                              of the Service, or access to the Service or any
                              contact on the website through which the service
                              is provided, without express written permission by
                              us.
                            </p>
                          </li>
                          <li>
                            <p>
                              The headings used in this agreement are included
                              for convenience only and will not limit or
                              otherwise affect these Terms.
                            </p>
                          </li>
                          <li>
                            <p>
                              ERRORS, INACCURACIES &amp;
                              OMISSIONS:&nbsp;Occasionally there may be
                              information on our site that contains
                              typographical errors, inaccuracies or omissions
                              that may relate to product descriptions, pricing,
                              promotions, offers, product shipping charges,
                              transit times and availability. We reserve the
                              right to correct any errors, inaccuracies or
                              omissions, and to change or update information or
                              cancel orders if any information in the Service or
                              on any related website is inaccurate at any time
                              without prior notice (including after you have
                              submitted your order). We are not responsible if
                              information made available on this site is not
                              accurate, complete or current. The material on
                              this site is provided for general information only
                              and should not be relied upon or used as the sole
                              basis for making decisions without consulting
                              primary, more accurate, more complete or more
                              timely sources of information. Any reliance on the
                              material on this site is at your own risk. This
                              site may contain certain historical information.
                              Historical information, necessarily, is not
                              current and is provided for your reference only.
                              We reserve the right to modify the contents of
                              this site at any time, but we have no obligation
                              to update any information on our site. You agree
                              that it is your responsibility to monitor changes
                              to our site. We undertake no obligation to update,
                              amend or clarify information in the Service or on
                              any related website, including without limitation,
                              pricing information, except as required by law. No
                              specified update or refresh date applied in the
                              Service or on any related website should be taken
                              to indicate that all information in the Service or
                              on any related website has been modified or
                              updated.
                            </p>
                          </li>
                          <li>
                            <p>
                              AMENDMENTS OR CHANGES:&nbsp;We reserve the right,
                              at our sole discretion, to update, change or
                              replace any part of these Terms of Service by
                              posting updates and changes to our website. It is
                              your responsibility to check our website
                              periodically for changes. Your continued use of or
                              access to our website or the Service following the
                              posting of any changes to these Terms of Service
                              constitutes acceptance of those changes. You can
                              review the most current version of the Terms of
                              Service at any time at this page.
                            </p>
                          </li>
                          <li>
                            <p>
                              GOVERNING LAW:&nbsp;These Terms of Service and any
                              separate agreements whereby we provide you
                              Services shall be governed by and construed in
                              accordance with the laws of Hong Kong. The Site is
                              not intended for individuals under the age of 18.
                              By using our website and ordering from us, you
                              automatically agree to our terms and that you are
                              not under the age of 18.
                            </p>
                          </li>
                          <li>
                            <p>
                              ENTIRE AGREEMENT:&nbsp;The failure of us to
                              exercise or enforce any right or provision of
                              these Terms of Service shall not constitute a
                              waiver of such right or provision. These Terms of
                              Service and any policies or operating rules posted
                              by us on this site or in respect to The Service
                              constitutes the entire agreement and understanding
                              between you and us and govern your use of the
                              Service, superseding any prior or contemporaneous
                              agreements, communications and proposals, whether
                              oral or written, between you and us (including,
                              but not limited to, any prior versions of the
                              Terms of Service). Any ambiguities in the
                              interpretation of these Terms of Service shall not
                              be construed against the drafting party.
                            </p>
                          </li>
                          <li>
                            <p>
                              PROHIBITED USE:&nbsp;In addition to other
                              prohibitions as set forth in the Terms of Service,
                              you are prohibited from using the site or its
                              content: (a) for any unlawful purpose; (b) to
                              solicit others to perform or participate in any
                              unlawful acts; (c) to violate any international,
                              federal, provincial or state regulations, rules,
                              laws, or local ordinances; (d) to infringe upon or
                              violate our intellectual property rights or the
                              intellectual property rights of others; (e) to
                              harass, abuse, insult, harm, defame, slander,
                              disparage, intimidate, or discriminate based on
                              gender, sexual orientation, religion, ethnicity,
                              race, age, national origin, or disability; (f) to
                              submit false or misleading information; (g) to
                              upload or transmit viruses or any other type of
                              malicious code that will or may be used in any way
                              that will affect the functionality or operation of
                              the Service or of any related website, other
                              websites, or the Internet; (h) to collect or track
                              the personal information of others; (i) to spam,
                              phish, pharm, pretext, spider, crawl, or scrape;
                              (j) for any obscene or immoral purpose; or (k) to
                              interfere with or circumvent the security features
                              of the Service or any related website, other
                              websites, or the Internet. We reserve the right to
                              terminate your use of the Service or any related
                              website for violating any of the prohibited uses.
                            </p>
                          </li>
                          <li>
                            <p>
                              SEVERABILITY: In the event that any provision of
                              these Terms of Service is determined to be
                              unlawful, void or unenforceable, such provision
                              shall nonetheless be enforceable to the fullest
                              extent permitted by applicable law, and the
                              unenforceable portion shall be deemed to be
                              severed from these Terms of Service, such
                              determination shall not affect the validity and
                              enforceability of any other remaining provisions.
                            </p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>20 | CONTACT INFORMATION</h1>
                        <p>
                          Questions about the Terms of Service should be sent to
                          us at&nbsp;kanpai@justsake.com
                        </p>
                      </div>
                    )}

                    {i18n.language !== "en" && (
                      <div>
                        <p>概述</p>
                        <p>
                          JustSaké 網站由 JUST ALCOHOL COMPANY LIMITED
                          運營。在整個網站中，&ldquo;我們&rdquo;、&ldquo;我們的&rdquo;和&ldquo;我們的&rdquo;等術語均指純清酒。我們向您（用戶）提供本網站，包括本網站提供的所有信息、工具和服務，前提是您接受此處所述的所有條款、條件、政策和通知。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          通過訪問我們的網站和/或從我們這裡購買商品，您參與了我們的&ldquo;服務&rdquo;並同意受以下條款和條件（&ldquo;服務條款&rdquo;、&ldquo;條款&rdquo;）的約束，包括那些附加的條款和條件以及政策此處引用和/或可通過超鏈接獲得。這些服務條款適用於網站的所有用戶，包括但不限於作為瀏覽器、供應商、客戶、商家和/或內容貢獻者的用戶。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          在訪問或使用我們的網站之前，請仔細閱讀這些服務條款。通過訪問或使用本網站的任何部分，您同意受這些服務條款的約束。如果您不同意本協議的所有條款和條件，則您不得訪問本網站或使用任何服務。如果這些服務條款被視為要約，則接受明確限於這些服務條款。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          添加到當前商店的任何新功能或工具也應受服務條款的約束。您可以隨時在此頁面上查看最新版本的服務條款。我們保留通過在我們的網站上發布更新和/或更改來更新、更改或替換這些服務條款的任何部分的權利。您有責任定期檢查此頁面以了解更改。您在發布任何更改後繼續使用或訪問本網站即表示您接受這些更改。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>1 |網上商店條款</p>
                        <p>
                          同意這些服務條款，即表示您至少已達到您居住的州或省的成年年齡
                          (18)，或者您已達到您居住的州或省的成年年齡並且您已向我們提供您同意允許您的任何未成年家屬使用本網站。未成年人下的所有訂單將被取消。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您不得將我們的產品用於任何非法或未經授權的目的，也不得在使用服務時違反您所在司法管轄區的任何法律（包括但不限於版權法）。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您不得傳播任何蠕蟲或病毒或任何具有破壞性的代碼。違反或違反任何條款將導致您的服務立即終止。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>2 |您的帳戶</p>
                        <p>
                          您需要創建一個帳戶才能從我們這裡購買產品。您必須確保您在創建帳戶時提供給我們的任何個人信息都是準確且最新的。您提供給我們的所有個人信息都將按照我們的隱私政策進行處理。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          當您創建帳戶時，您同意為在我們商店進行的所有購買提供當前、完整和準確的購買和帳戶信息。您同意及時更新您的帳戶和其他信息，包括您的電子郵件地址和信用卡號以及到期日期，以便我們可以完成您的交易並根據需要與您聯繫。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您有責任對您的帳戶詳細信息保密。您應對您帳戶上的所有活動負責，包括使用您的帳戶詳細信息進行的任何購買。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>3 |訂單</p>
                        <p>
                          在您下訂單時，您同意支付總金額 -
                          包括任何運費和適用於您所在位置的任何其他費用或稅款。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          在通過我們的網站提交訂單之前，您有責任檢查訂單詳細信息，包括所選產品和定價（包括您希望應用的任何折扣代碼）。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          當您在網站上訂購和付款並且您的付款已得到驗證時，我們將向您發送一封包含訂單詳細信息的電子郵件收據，其中將包括訂單號、送貨地址和賬單地址，以及對所訂購商品的描述。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          根據香港法律，酒類只能出售或供應給年滿 18
                          歲或以上的人士。通過向我們下訂單，您確認您已年滿 18
                          歲。您同意依法負責任地飲酒是您的責任。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果我們認為任何訂單違反了這些條款和條件或違反了香港法律，我們保留拒絕或取消任何訂單的權利。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>4 |價格和付款</p>
                        <p>
                          您必鬚根據本條款根據您在網站上選擇的交付選項向我們支付您訂購的每件產品的購買價格以及任何適用的交付費用。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您必須通過信用卡或網站上規定的任何其他付款方式（例如
                          Stripe、Apple Pay、PayPal
                          或列出的任何其他付款方式）支付價格。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>不接受銀行付款或任何其他延遲付款。</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          所有金額均以港幣表示，我們將運費與購買價格分開顯示。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>您不得以欺詐或非法方式支付或試圖支付。</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果未按照網站或這些條款的規定支付與訂閱費有關的任何款項，我們可以（全權酌情決定）立即停止供應產品。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>5 |折扣代碼</p>
                        <p>
                          我們可能會不時提供促銷折扣代碼（即：優惠券、優惠券代碼等）。要申請折扣，您必須在提交訂單時輸入促銷折扣代碼。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          每位客戶只能使用一次折扣碼（除非另有說明），並且每個訂單只能使用一個折扣碼。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          折扣代碼不能應用於以前的購買。優惠券不能兌換現金或與其他優惠或積分結合使用。折扣碼或優惠券不可用於轉售，也沒有現金價值。發送給個人的折扣代碼僅對預期收件人有效。在禁止、徵稅或限制的地方無效。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          JustSaké 保留自行決定更改或限制折扣代碼的權利。 JUST
                          SAKÉ 對任何問題或無法使用折扣代碼不承擔任何責任。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>6 |免費禮品</p>
                        <p>
                          JustSaké 現在為預付款大於或等於 3
                          個月的訂閱提供免費禮物。免費禮品不能用於之前的購買。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>禮品不可退款，也不能兌換現金。</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          JustSaké
                          保留自行決定更改或限制免費禮物的權利，如果免費禮物不符合您的期望，我們概不負責。所有禮品均視供應情況而定。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>7 |可用性、產品和服務</p>
                        <p>
                          通過本網站進行的所有購買均視供應情況而定。我們盡最大努力保持產品庫存，並讓網站及時了解產品的可用性。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們保留在交貨前的任何時間以及出於任何原因取消我們之前接受的訂單的權利，包括在派送您的訂單時出現相當長的延遲，如果由於任何原因我們無法供應您訂購的產品（例如例如超出我們合理控制的事件）或訂購的產品在我們的網站上存在錯誤（例如與描述、價格或圖像有關）。我們將使用您在下訂單時提供的詳細信息與您聯繫。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          某些產品或服務可能僅通過網站在線提供。這些產品或服務的數量可能有限，只能根據我們的退貨政策進行退貨或換貨。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們已盡一切努力盡可能準確地顯示出現在商店中的我們產品的顏色和圖像。我們不能保證您的電腦顯示器顯示的任何顏色都是準確的。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們保留權利但沒有義務將我們的產品或服務的銷售限制在任何人、地理區域或司法管轄區。我們可能會根據具體情況行使此權利。我們保留限制我們提供的任何產品或服務數量的權利。所有產品描述或產品定價如有更改，恕不另行通知，由我們自行決定。我們保留隨時停止任何產品的權利。在本網站上提供的任何產品或服務的任何報價在禁止的情況下均無效。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們不保證您購買或獲得的任何產品、服務、信息或其他材料的質量將符合您的期望，或者服務中的任何錯誤將得到糾正。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果您對我們其中一款產品的口味不是 100%
                          滿意，請通過電子郵件告知我們：hello@justsake.com，我們將盡我們所能（在合理範圍內）解決問題。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們產品的價格如有更改，恕不另行通知。我們保留隨時修改或終止服務（或其任何部分或內容）的權利，恕不另行通知。對於服務的任何修改、價格變動、暫停或中止，我們不對您或任何第三方承擔任何責任。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>8 |交付、所有權和風險</p>
                        <p>
                          1-4
                          天送貨：全港免費送貨，沒有最低消費，但以下我們不送貨到的地方除外：長洲、坪洲、馬灣、大嶼山、東涌和南丫島.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們會將產品運送到您下訂單時提供的收貨地址。我們送貨到全香港（長洲、坪洲、馬灣、大嶼山、東涌和南丫島除外），如果我們不能送貨給您，JUST
                          SAKÉ 團隊成員會盡快通知您。請諮詢國際航運信息。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您有責任檢查交貨詳情和定價，並在通過我們的網站提交訂單之前了解可能產生的任何費用。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們總是盡量盡快發送您的訂單，但有些事情是我們無法控制的。我們顯示或宣傳的任何交貨期僅為估計值，JUST
                          SAKÉ 不對可能出現的任何延誤負責。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們可能會使用一系列交付方式或快遞服務來交付您的訂單。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果您需要更改送貨地址或有送貨要求，請立即書面通知我們。如果您在訂單離開我們的倉庫後更改收貨地址，我們將無法再修改收貨地址，您的訂單將被送到原地址。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們提供免費送貨到香港所有地點，但如果您輸入錯誤的地址或在您的訂單已轉交給我們的送貨代理後想要進行更改，JUST
                          SAKÉ 不對任何額外費用或延誤負責。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們不會在沒有先收到付款的情況下交付任何訂單，如果處理您的付款出現問題，JUST
                          SAKÉ 團隊成員將與您聯繫以解決問題。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          一旦物品送達所提供的收貨地址，JustSaké
                          將不再承擔任何責任、損害或損失，並將所有風險轉嫁給您。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>9 |退款和退貨政策</p>
                        <p>
                          如果您對您的訂單不是 100%
                          滿意，請通過電子郵件告知我們：aiya@justsake.com，我們將盡我們所能（在合理範圍內）解決問題。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>回報：</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們的保單有效期為 14 天。如果您購買後 14
                          天過去了，很遺憾，我們無法為您提供退款或換貨。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          要獲得退貨資格，您的商品必須未開封或未使用，並且與您收到時的狀態相同。它也必須在原包裝中。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>打開（擰開/開塞）瓶子或標籤損壞的瓶子不能退回。</p>
                        <p>
                          <br />
                        </p>
                        <p>禮品卡不能退還或兌換現金。</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果您需要退貨，請發送電子郵件至 aiya@justsake.com
                          給我們一個友好的電子郵件
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          為了處理您的退貨，我們需要您的訂單號、退貨的正當理由以及收據或購買證明。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>請不要在未事先通知的情況下將您的購買退回。</p>
                        <p>
                          <br />
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          <br />
                        </p>
                        <p>退款：</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          收到並檢查您的退貨後，我們將向您發送一封電子郵件，通知您我們已收到您退回的物品。我們還將通知您批准或拒絕您的退款。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果您獲得批准，那麼您的退款將得到處理，並在一定天數內自動將信用額度應用到您的信用卡或原始付款方式中。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          只有正價商品可以退款，很遺憾，特價商品不能退款。清酒盒等散裝產品不能作為單瓶退回。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>免費禮品不能退回或兌換現金。</p>
                        <p>
                          <br />
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          <br />
                        </p>
                        <p>交易所：</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們僅在物品被塞住且未打開的情況下更換物品。如果您需要將其換成相同的物品，請發送電子郵件至
                          aiya@justsake.com 給我們。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>10 |清酒訂閱</p>
                        <p>
                          當您訂購清酒訂閱時，您可以從我們平台上的各種清酒套裝中進行選擇。您選擇的套裝將提供有關套裝中預期內容的信息。您的訂閱將根據您選擇的訂閱自動更新每個計費周期。您的結算週期是在您首次付款的當天設置的。例如，如果您訂閱了每月訂閱並在
                          3 月 3 日進行了首次付款，那麼您的下一個結算日期是 4 月
                          3 日。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          每個訂閱訂單中包含的產品選擇將由 JustSaké 團隊決定。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您的訂閱會自動繼續，直到根據&ldquo;取消&rdquo;條款取消。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您有責任查看與您的訂閱相關的定價表、包含項和排除項。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們通常不會更改我們的訂閱價格，但如果我們在任何時候修改我們的價格，您將通過電子郵件、短信或電話收到通知。如果您不同意價格變動，您可以根據&ldquo;取消&rdquo;條款取消您的訂閱。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          <br />
                        </p>
                        <p>消除：</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您可以隨時通過在我們的網站上更新您的帳戶來取消您的清酒訂閱。您必須在下一個結算週期前至少
                          15
                          個工作日以書面形式通知我們和/或更新您的帳戶。如果您在下一個計費日期前不到
                          15
                          個工作日取消訂閱，您將被收取下一個計費周期的訂閱費，並且您的訂閱取消將在下一個期間生效。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>對於每月訂閱，我們至少有 2 個月的訂閱。</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們可能會立即暫停、終止或限制您訪問和使用本網站並終止這些條款。如果您違反條款（包括您支付訂閱費的義務），並且在我們通知您違約後的
                          5 個工作日內無法補救或未補救違約。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果您的 Saké
                          訂閱被取消，而您後來又決定重新訂閱，我們將無法兌現過去的訂閱價格，因此將按當時的訂閱費向您收取費用。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>11 |知識產權</p>
                        <p>
                          我們或我們的人員（包括與條款和網站相關的）開發、改編、修改或創建的所有知識產權（包括版權）將始終歸屬或保持歸屬於我們。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>未經我們事先書面同意，您不得：</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          全部或部分複製或使用我們的任何知識產權；包括圖片、文案或提案。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          複製、轉發、分發、傳播、出售、出版、廣播或向任何第三方傳播我們的任何知識產權；
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          違反與本網站或產品相關的任何知識產權，包括（但不限於）更改或修改我們的任何知識產權；導致我們的任何知識產權被框定或嵌入另一個網站；或從我們的任何知識產權創作衍生作品。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>12 |用戶內容</p>
                        <p>
                          您可能被允許發布、創建、上傳、宣傳或以其他方式提交內容，例如評論、評論、圖片和視頻。您必鬚根據這些條款提交用戶內容。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>我們要求您將討論限制在與網站和產品相關的主題上。</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們保留刪除我們自行決定認為違反我們的任何條款以及我們認為不適當的任何評論或評論的權利。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們不對我們網站或社交媒體帳戶的任何其他用戶的行為負責。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          通過在我們的網站或社交媒體上提供任何用戶內容，您授予我們使用用戶內容的全球性、不可撤銷、永久、非排他性、可轉讓、免版稅許可，並有權使用、查看、複製、在我們的網站上、通過或通過我們的網站改編、修改、分發、許可、轉讓、交流、公開展示、公開表演、傳輸、流式傳輸、廣播、訪問或以其他方式利用此類用戶內容。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您同意對您在我們的網站或社交媒體上或通過我們的網站或社交媒體提供的所有用戶內容承擔全部責任。您聲明並保證您是所有用戶內容的唯一和專有所有者，或者您擁有向我們授予此類用戶內容中的權利所需的所有權利、許可、同意和發布（如本條款所述）；
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果您應我們的要求發送某些特定的提交（例如參賽作品），或者在沒有我們要求的情況下，您發送創意、建議、提案、計劃或其他材料，無論是在線、電子郵件、郵寄或其他方式（統稱為&ldquo;評論&rdquo;），您同意我們可以在任何時候不受限制地編輯、複製、發布、分發、翻譯和以其他方式在任何媒體上使用您轉發給我們的任何評論。我們沒有義務（1）對任何評論保密；
                          （二）對任何評論給予補償；或 (3) 回復任何評論。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們可以但沒有義務監控、編輯或刪除我們自行判斷為非法、冒犯、威脅、誹謗、誹謗、色情、淫穢或其他令人反感或違反任何一方的知識產權或本服務條款的內容.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您同意您的評論不會侵犯任何第三方的任何權利，包括版權、商標、隱私、人格或其他個人或專有權利。您進一步同意，您的評論不會包含誹謗或其他非法、辱罵或淫穢材料，或包含可能以任何方式影響服務或任何相關網站運行的任何計算機病毒或其他惡意軟件。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您不得使用虛假電子郵件地址、冒充他人或以其他方式誤導我們或第三方關於任何評論的來源。您對您所做的任何評論及其準確性負全部責任。對於您或任何第三方發布的任何評論，我們不承擔任何責任。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>13 |免責聲明；責任限制</p>
                        <p>
                          我們不保證、聲明或保證您對我們服務的使用不會中斷、及時、安全或無錯誤。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>我們不保證使用服務可能獲得的結果是準確或可靠的。</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您同意我們可能不時無限期地取消服務或隨時取消服務，恕不另行通知。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您明確同意，您使用或無法使用服務的風險由您自行承擔。服務以及通過服務提供給您的所有產品和服務（除非我們明確聲明）&ldquo;按原樣&rdquo;和&ldquo;可用&rdquo;提供給您使用，沒有任何明示或任何形式的陳述、保證或條件默示，包括所有默示保證或適銷性、適銷質量、特定用途適用性、耐用性、所有權和不侵權的條件。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          在任何情況下，我們的董事、管理人員、員工、關聯公司、代理、承包商、實習生、供應商、服務提供商或許可方均不對任何傷害、損失、索賠或任何直接、間接、附帶、懲罰性、特殊、或任何種類的間接損害，包括但不限於利潤損失、收入損失、儲蓄損失、數據丟失、更換成本或任何類似的損害，無論是基於合同、侵權（包括疏忽）、嚴格責任或其他原因，由您對任何服務或使用該服務採購的任何產品的使用，或以任何方式與您對服務或任何產品的使用相關的任何其他索賠，包括但不限於任何內容中的任何錯誤或遺漏，或由於使用服務或通過服務發布、傳輸或以其他方式提供的任何內容（或產品）而導致的任何類型的任何損失或損害，即使被告知其可能性。由於某些州或司法管轄區不允許排除或限制間接或附帶損害的責任，因此在此類州或司法管轄區，我們的責任應限於法律允許的最大範圍內。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>14 |個人信息</p>
                        <p>
                          您通過商店提交的個人信息受我們的隱私政策約束。向下滾動以查看我們的隱私政策。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>15 |可選工具和第三方鏈接</p>
                        <p>
                          您通過商店提交的個人信息受我們的隱私政策約束。向下滾動以查看我們的隱私政策。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們可能會為您提供對我們既不監控也無任何控製或輸入的第三方工具的訪問權限。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您承認並同意，我們&ldquo;按原樣&rdquo;和&ldquo;按可用&rdquo;提供對此類工具的訪問，不提供任何形式的保證、陳述或條件，也沒有任何背書。對於因您使用可選的第三方工具而引起或與之相關的任何責任，我們概不負責。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您對通過本網站提供的可選工具的任何使用完全由您自行承擔風險和酌情權，您應確保您熟悉並同意相關第三方提供商提供工具的條款。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          未來，我們還可能通過網站提供新服務和/或功能（包括發布新工具和資源）。此類新功能和/或服務也應受這些服務條款的約束。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          通過我們的服務提供的某些內容、產品和服務可能包括來自第三方的材料。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          本網站上的第三方鏈接可能會將您引導至與我們無關的第三方網站。我們不負責檢查或評估內容或準確性，我們不保證也不會對任何第三方材料或網站，或第三方的任何其他材料、產品或服務承擔任何責任或義務。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          對於與購買或使用商品、服務、資源、內容或與任何第三方網站相關的任何其他交易相關的任何損害或損害，我們概不負責。請仔細閱讀第三方的政策和做法，並確保您在進行任何交易之前了解它們。有關第三方產品的投訴、索賠、疑慮或問題應直接提交給第三方。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>16 |保障</p>
                        <p>
                          您同意對 JustSaké
                          和我們的母公司、子公司、關聯公司、合作夥伴、高級職員、董事、代理、承包商、許可方、服務提供商、分包商、供應商、實習生和員工進行賠償、辯護並使他們免受任何索賠或要求的損害，包括任何第三方因您違反本服務條款或其通過引用而包含的文件或您違反任何法律或第三方權利而產生的合理律師費。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>17 |保障</p>
                        <p>
                          各方在終止日期之前產生的義務和責任在本協議終止後繼續有效。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          這些服務條款在您或我們終止之前均有效。您可以隨時通過通知我們您不再希望使用我們的服務或停止使用我們的網站來終止這些服務條款。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果我們單方面判斷您未能或我們懷疑您未能遵守本服務條款的任何條款或規定，我們也可以隨時終止本協議，恕不另行通知，您仍將對所有到期款項負責至並包括終止日期；和/或因此可能會拒絕您訪問我們的服務（或其任何部分）。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>18 |終止</p>
                        <p>
                          各方在終止日期之前產生的義務和責任在本協議終止後繼續有效。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          這些服務條款在您或我們終止之前均有效。您可以隨時通過通知我們您不再希望使用我們的服務或停止使用我們的網站來終止這些服務條款。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          如果我們單方面判斷您未能或我們懷疑您未能遵守本服務條款的任何條款或規定，我們也可以隨時終止本協議，恕不另行通知，您仍將對所有到期款項負責至並包括終止日期；和/或因此可能會拒絕您訪問我們的服務（或其任何部分）。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>19 |大體情況</p>
                        <p>
                          我們保留隨時以任何理由拒絕向任何人提供服務的權利。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          您了解您的內容（不包括信用卡信息）可能會在未加密的情況下傳輸並涉及
                          (a) 通過各種網絡傳輸； (b)
                          更改以符合和適應連接網絡或設備的技術要求。信用卡信息在通過網絡傳輸時始終被加密。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          未經我們明確書面許可，您同意不復制、複製、複製、出售、轉售或利用服務的任何部分、服務的使用或訪問服務或提供服務的網站上的任何联係人.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          本協議中使用的標題僅為方便起見而包含，不會限製或以其他方式影響這些條款。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          錯誤、不准確和遺漏：有時，我們網站上的信息可能包含與產品描述、定價、促銷、優惠、產品運輸費用、運輸時間和可用性有關的印刷錯誤、不准確或遺漏。如果服務或任何相關網站上的任何信息不准確，我們保留隨時更正任何錯誤、不准確或遺漏以及更改或更新信息或取消訂單的權利，恕不另行通知（包括在您提交訂單後）
                          .如果本網站上提供的信息不准確、不完整或不及時，我們概不負責。本網站上的材料僅供一般參考，在未諮詢主要、更準確、更完整或更及時的信息來源的情況下，不應依賴或用作做出決策的唯一依據。對本網站材料的任何依賴均由您自行承擔風險。本網站可能包含某些歷史信息。歷史信息必然不是最新的，僅供您參考。我們保留隨時修改本網站內容的權利，但我們沒有義務更新我們網站上的任何信息。您同意監控我們網站的更改是您的責任。除法律要求外，我們不承擔更新、修改或澄清服務或任何相關網站上的信息的義務，包括但不限於定價信息。服務或任何相關網站上應用的指定更新或刷新日期不應被視為表明服務或任何相關網站上的所有信息已被修改或更新。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          修訂或更改：我們保留自行決定通過在我們的網站上發布更新和更改來更新、更改或替換這些服務條款的任何部分的權利。您有責任定期檢查我們的網站以了解更改。在發布對這些服務條款的任何更改後，您繼續使用或訪問我們的網站或服務即表示您接受這些更改。您可以隨時在此頁面查看最新版本的服務條款。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          適用法律：本服務條款以及我們向您提供服務的任何單獨協議均受香港法律管轄並按其解釋。本網站不適用於
                          18
                          歲以下的個人。使用我們的網站並向我們訂購，即表示您自動同意我們的條款並且您未滿
                          18 歲。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          完整協議：我們未能行使或執行本服務條款的任何權利或規定不構成對此類權利或規定的放棄。這些服務條款以及我們在本網站上發布的或與服務相關的任何政策或操作規則構成您與我們之間的完整協議和諒解，並約束您對服務的使用，取代任何先前或同期的協議、通信和建議，無論是口頭的還是書面的，您和我們之間（包括但不限於任何先前版本的服務條款）。對本服務條款的解釋中的任何含糊之處均不得解釋為對起草方不利。
                        </p>
                        <p>
                          禁止使用：除了服務條款中規定的其他禁令外，您不得使用本網站或其內容：(a)
                          用於任何非法目的； (b)
                          招攬他人進行或參與任何非法行為； (c)
                          違反任何國際、聯邦、省或州法規、規則、法律或地方法令；
                          (d) 侵犯或侵犯我們的知識產權或他人的知識產權； (e)
                          基於性別、性取向、宗教、民族、種族、年齡、國籍或殘疾，騷擾、辱罵、侮辱、傷害、誹謗、誹謗、貶低、恐嚇或歧視；
                          (f) 提交虛假或誤導性信息； (g)
                          上傳或傳播病毒或任何其他類型的惡意代碼，這些惡意代碼將以任何方式使用或可能會影響服務或任何相關網站、其他網站或互聯網的功能或操作；
                          (h) 收集或跟踪他人的個人信息； (i)
                          垃圾郵件、網絡釣魚、藥品、藉口、蜘蛛、爬網或抓取； (j)
                          用於任何淫穢或不道德的目的； (k)
                          干擾或規避服務或任何相關網站、其他網站或互聯網的安全功能。我們保留因您違反任何禁止使用而終止您使用服務或任何相關網站的權利。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          可分割性：如果本服務條款的任何條款被確定為非法、無效或不可執行，該條款仍應在適用法律允許的最大範圍內執行，並且不可執行部分應被視為與這些條款分離服務條款，此類確定不得影響任何其他剩餘條款的有效性和可執行性。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>20 | 聯繫信息</p>
                        <p>
                          有關服務條款的問題應發送至 kanpai@justsake.com 給我們
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </React.Fragment>
  );
};

export default TermsAndConditions;

import React, { useContext } from "react";
import CartContext from "../../../../contexts/cart-context";
import classes from "./CartModal.module.css";
import CartItems from "./partials/CartItems";
import { numberFormat } from "../../../../assets/frontend/js/numberFormat";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FreeDeliveryBar from "./partials/FreeDeliveryBar";
import BottleDiscountBar from "./partials/BottleDiscountBar";

const CartModal = (props) => {
  const { t } = useTranslation();
  const cartCtx = useContext(CartContext);

  const updateCartHandler = (e) => {
    cartCtx.updateCart(
      e.target.getAttribute("data-set_skus_id"),
      e.target.getAttribute("data-product_skus_id"),
      e.target.getAttribute("data-quantity"),
      "fixed"
    );
  };

  return (
    <>
      <div
        className={classes["modal-bg"]}
        onClick={cartCtx.showCartHandler}
      ></div>
      <div className={classes["cart-modal"]} style={{ height: "80%" }}>
        <div className="container" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div className="offset-md-2 col-md-8" style={{ height: "100%" }}>
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header">{t("shoppingCart.title")}</div>
                <div className="card-body" style={{ overflowY: "auto" }}>
                  {cartCtx.isCartEmpty() === true && "Cart is empty."}
                  {cartCtx.isCartEmpty() === false && (
                    <CartItems
                      totalBottlesToPrice={cartCtx.cart.total_bottles_to_price}
                      showCartHandler={cartCtx.showCartHandler}
                      getLink={props.getLink}
                      cartItems={cartCtx.cart.items}
                      updateCartHandler={updateCartHandler}
                    />
                  )}
                </div>

                <div className="card-footer">
                  <BottleDiscountBar
                    numBottlesToPrice={cartCtx.cart.total_bottles_to_price}
                    cartTotal={cartCtx.cart.total}
                    noDiscountTotal={cartCtx.cart.no_discount_total}
                    msrpTotal={cartCtx.cart.msrp_total}
                  />
                </div>
                <div className="card-footer">
                  <FreeDeliveryBar cartTotal={cartCtx.cart.total} />
                </div>
                <div className="card-footer text-end">
                  <button
                    className="btn btn-secondary me-2"
                    onClick={cartCtx.showCartHandler}
                  >
                    {t("general.close")}
                  </button>
                  <Link
                    to={props.getLink("checkout")}
                    className="btn btn-primary"
                    onClick={cartCtx.showCartHandler}
                  >
                    {t("checkout.checkoutTitleSingle")} -{" "}
                    {numberFormat(cartCtx.cart.total)}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartModal;

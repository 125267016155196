import React from "react";
import { useTranslation } from "react-i18next";
import "./Loader.min.css";

const Loader = () => {
  const { t } = useTranslation();

  return (
      <div className="loader">
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="wineglass left">
          <div className="top"></div>
        </div>
        <div className="wineglass right">
          <div className="top"></div>
        </div>
        <br /><br />
        {t("general.loading")}
      </div>
  );
};

export default Loader;

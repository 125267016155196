import React from "react";
import { useTranslation } from "react-i18next";
import Address from "./Address";
import AddressForm from "./AddressForm";
import AddressSelect from "./AddressSelect";

const AddressDisplay = (props) => {
  const { t } = useTranslation();
  return (
    <div id="delivery-address">
      <div id="address-display">
        <div className="card">
          <div className="card-header">{t("general.deliveryAddress")}</div>
          <div className="card-body" style={{ padding: "0px" }}>
            <div className="accordion" id="accordionAddress">
              {props.deliveryAddress && (
                <div className="accordion-item">
                  <h2 className="accordion-header" id="deliveryAddress">
                    <button
                      id="collapseDeliveryButton"
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseDelivery"
                      aria-expanded="true"
                      aria-controls="collapseDelivery"
                    >
                      {t("general.deliverTo")}
                    </button>
                  </h2>
                  <div
                    id="collapseDelivery"
                    className="accordion-collapse collapse show"
                    aria-labelledby="deliveryAddress"
                    data-bs-parent="#accordionAddress"
                  >
                    <div className="accordion-body">
                      <Address
                        address={props.deliveryAddress}
                        addressDelete={props.addressDelete}
                      />
                    </div>
                  </div>
                </div>
              )}

              {props.userAddresses && (
                <div className="accordion-item">
                  <h2 className="accordion-header" id="selectAddress">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSelect"
                      aria-expanded="true"
                      aria-controls="collapseSelect"
                    >
                      {t("general.selectPreviousAddresses")}
                    </button>
                  </h2>
                  <div
                    id="collapseSelect"
                    className="accordion-collapse collapse"
                    aria-labelledby="selectAddress"
                    data-bs-parent="#accordionAddress"
                  >
                    <div className="accordion-body">
                      <AddressSelect
                        addresses={props.userAddresses}
                        addressSelect={props.addressSelect}
                        addressDelete={props.addressDelete}
                      />
                    </div>
                  </div>
                </div>
              )}

              {props.addressSubmit && (
                <div className="accordion-item">
                  <h2 className="accordion-header" id="addAddress">
                    <button
                      id="collapseAddButton"
                      className={`accordion-button ${
                        props.userAddresses !== null ||
                        props.deliveryAddress !== null
                          ? "collapsed"
                          : ""
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseAdd"
                      aria-expanded="false"
                      aria-controls="collapseAdd"
                    >
                      {t("general.addNewAddress")}
                    </button>
                  </h2>
                  <div
                    id="collapseAdd"
                    className={`accordion-collapse collapse ${
                      props.userAddresses !== null ||
                      props.deliveryAddress !== null
                        ? ""
                        : "show"
                    }`}
                    aria-labelledby="addAddress"
                    data-bs-parent="#accordionAddress"
                  >
                    <div className="accordion-body">
                      <AddressForm
                        addressSubmit={props.addressSubmit}
                        country={props.country}
                        name={props.name}
                        email={props.email}
                        phone={props.phone}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default AddressDisplay;

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#examples

export const numberFormat = (value) =>
  new Intl.NumberFormat('zh-HK', {
    style: 'currency',
    currency: 'HKD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);

  export const percentFormat = (value) =>
  new Intl.NumberFormat('zh-HK', {
    roundingMode: 'floor'
  }).format(value);

  export const smvFormat = (value) =>
  new Intl.NumberFormat('zh-HK', {
    signDisplay: "exceptZero"
  }).format(value);
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../../layouts/frontend/Footer";
import Header from "../../../../layouts/frontend/Header";
import Breadcrumb from "../../ui/Breadcrumb";
import WhyMembership from "../sections/WhyMembership";
import UserContext from "../../../../contexts/user-context";
import LoaderText from "../../ui/LoaderText";
import Loader from "../../ui/Loader";
import axios from "axios";
import Swal from "sweetalert2";
import Helmet from "react-helmet";

axios.defaults.withCredentials = true;

const Profile = (props) => {
  const { t } = useTranslation();
  const userCtx = useContext(UserContext);
  const nameRef = useRef();
  const phoneRef = useRef();
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const [ phoneVerifyStatus, setPhoneVerifyStatus ] = useState(null); // null, pending, approved
  const [ saveBtnDisabled, setSaveBtnDisabled ] = useState(false);
  const [ updateBtnDisabled, setUpdateBtnDisabled ] = useState(false);
  const initPhone = userCtx.user?.attributes?.phone;

  const userInfoSubmitHandler = async (e) => {
    e.preventDefault();

    setSaveBtnDisabled(true);

    if (phoneRef.current.value.trim() !== '' && 
        phoneVerifyStatus === null && 
        phoneRef.current.value.trim() !== initPhone
    ) {
      // do phone verify
      const { error:twilioVerifyError, data:twilioVerification } = await axios.post(process.env.REACT_APP_API_URL + 'register/phone/verify_create', {
        'phone': phoneRef.current.value
      }).catch(error => {
        return error.response.data;
      });

      if (twilioVerifyError) {
        setSaveBtnDisabled(false);
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          twilioVerifyError,
          "error"
        );
        return;
      }

      setPhoneVerifyStatus(twilioVerification.status);

      if (twilioVerification.status === 'pending') {
        phoneVerifyCheckThenSubmit();
      }
    } else {
      userCtx.updateUser(nameRef.current.value, phoneRef.current.value)
      .then(response => {
        setSaveBtnDisabled(false);
      })
      .catch(error => {
        setSaveBtnDisabled(false);
      });
    }
  };

  const phoneVerifyCheckThenSubmit = () => {
    Swal.fire({
      title: t("signup.submitJSCode"),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: false,
      confirmButtonText: t("signup.submitJSConfirm"),
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: (code) => {
        axios.post(process.env.REACT_APP_API_URL + 'register/phone/verify_check', {
          phone: phoneRef.current.value,
          code: code
        })
        .then(response => {
          setPhoneVerifyStatus(response.data.status);

          if (response.data.status === 'approved') {
            //if ok, continue -> addUser
            userCtx.updateUser(nameRef.current.value, phoneRef.current.value)
            .then(response => {
              setPhoneVerifyStatus(null);
              setSaveBtnDisabled(false);
            })
            .catch((err) => {
              setSaveBtnDisabled(false);
            });
          } else {
            Swal.fire({ icon: 'error', title: t("errorMessages.twilioVerifyCheckError"), text: response.data.message });
            setSaveBtnDisabled(false);
          }
        })
        .catch(error => {
            Swal.fire({ icon: 'error', title: t("errorMessages.twilioVerifyCheckError"), text: error.response.data.message });
            setSaveBtnDisabled(false);
        });
      }
    });
  }

  const userPasswordSubmitHandler = (e) => {
    e.preventDefault();
    setUpdateBtnDisabled(true);
    userCtx.updateUserPassword(oldPasswordRef.current.value, newPasswordRef.current.value, confirmPasswordRef.current.value)
    .then(response => {
      setUpdateBtnDisabled(false);
    })
    .catch(error => {
      setUpdateBtnDisabled(false);
    });
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("navigation.yourAccount")} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          { text: t("navigation.yourAccount"), link: "" },
        ]}
      />

      <div className="liquor-law">{t('general.liquorLaw')}</div>
      
      {(userCtx.userIsLoggedIn === null || userCtx.userIsLoggedIn === false) && 
        <div className="d-flex justify-content-center breadcrumb-spacing">
          <Loader />
        </div>
      }
      {userCtx.userIsLoggedIn === true && 
        <section className="breadcrumb-spacing" id="user-account">
          <div className="section-content pb-0">
            <h1
              className="display-7 pb-2"
              dangerouslySetInnerHTML={{ __html: t("navigation.yourAccount") }}
            />

            <div
              className="header-description"
              dangerouslySetInnerHTML={{ __html: t("profile.description") }}
            />

            <div className="container my-5">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="card text-dark bg-light mb-3"
                  >
                    <div className="card-header">{t("profile.accountInformation")}</div>
                    <div className="card-body text-start">
                    <form onSubmit={userInfoSubmitHandler}>
                      <div className="row mb-3">
                        <label className="col-md-3 col-form-label">{t("signup.emailAddress")}</label>
                        <div className="col-md-9">
                          {userCtx.user?.attributes?.email ?? <LoaderText />}
                        </div>
                      </div>
                      
                      <div className="row mb-3">
                        <label htmlFor="name" className="col-md-3 col-form-label">{t("signup.firstName")}</label>
                        <div className="col-md-9">
                          <input type="name" className="form-control" id="name" defaultValue={userCtx?.user?.attributes?.name ?? ''} ref={nameRef} />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="phone" className="col-md-3 col-form-label">{t("signup.phoneNumber")}</label>
                        <div className="col-md-9">
                          <input type="phone" className="form-control" id="phone" defaultValue={userCtx.user?.attributes?.phone ?? ''} ref={phoneRef} />
                        </div>
                      </div>
                      
                      <button type="submit" disabled={saveBtnDisabled} className="btn btn-primary">{t("general.save")}</button>
                    </form>
                    </div>
                  </div>
                
                  <br />
                </div>
                <div className="col-lg-6">
                  <div
                    className="card text-dark bg-light mb-3"
                  >
                    <div className="card-header">{t("profile.changePassword")}</div>
                    <div className="card-body text-start">
                    <form onSubmit={userPasswordSubmitHandler}>
                      <div className="row mb-3">
                        <label htmlFor="old-password" className="col-md-3 col-form-label">{t("profile.oldPassword")}</label>
                        <div className="col-md-9">
                          <input type="password" className="form-control" id="old-password" ref={oldPasswordRef} />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="new-password" className="col-md-3 col-form-label">{t("profile.newPassword")}</label>
                        <div className="col-md-9">
                          <input type="password" className="form-control" id="new-password" ref={newPasswordRef} />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="confirm-password" className="col-md-3 col-form-label">{t("profile.confirmPassword")}</label>
                        <div className="col-md-9">
                          <input type="password" className="form-control" id="confirm-password" ref={confirmPasswordRef} />
                        </div>
                      </div>
                      
                      <button type="submit" disabled={updateBtnDisabled} className="btn btn-primary">{t("general.update")}</button>
                    </form>
                    </div>
                  </div>
                
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
};

export default Profile;

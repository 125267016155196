import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../../layouts/frontend/Footer';
import Header from '../../../../layouts/frontend/Header';
import Swal from 'sweetalert2';
import { validate as validateEmail } from 'react-email-validator';
import UserContext from '../../../../contexts/user-context';
import Breadcrumb from '../../ui/Breadcrumb';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const Login = (props) => {
  const { t } = useTranslation();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const userCtx = useContext(UserContext);
  const [ submitBtnDisabled, setSubmitBtnDisabled ] = useState(false);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    //disable submit button
    setSubmitBtnDisabled(true);

    //do basic data validation
    if (validateUser(emailInputRef.current.value, passwordInputRef.current.value) === false) {
      //if not ok, alert user
      setSubmitBtnDisabled(false);
      return;
    }

    //if ok, continue -> addUser
    userCtx.loginUser(emailInputRef.current.value, passwordInputRef.current.value)
    .catch((err) => {
      setSubmitBtnDisabled(false);
      return;
    });
  }

  /**
   * 
   * @param {string} emailAddress
   * @param {string} password 
   * @returns boolean
   */
  const validateUser = (emailAddress, password) => {
    if (emailAddress.trim().length === 0) {
      Swal.fire({ icon: 'error', title: t("errorMessages.emailAddressEmptyTitle"), text: t("errorMessages.emailAddressEmptyText") });
      return false;
    }

    if (password.trim().length === 0) {
      Swal.fire({ icon: 'error', title: t("errorMessages.passwordEmptyTitle"), text: t("errorMessages.passwordEmptyText") });
      return false;
    }

    // check valid email
    if (validateEmail(emailAddress) === false) {
      Swal.fire({ icon: 'error', title: t("errorMessages.emailAddressInvalidTitle"), text: t("errorMessages.emailAddressInvalidText") });
      return false;
    }

    // check password length
    // TODO - Include better password validation
    if (password.trim().length < 8) {
      Swal.fire({ icon: 'error', title: t("errorMessages.passwordInvalidLengthTitle"), text: t("errorMessages.passwordInvalidLengthText") });
      return false;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('navigation.login')} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb crumbs={[
          {text: t("navigation.home"), link: props.getLink("/")},
          {text: t("navigation.login"), link: ''}
      ]} />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="login">
        <div className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6 pt-4 pb-5">
              <h2 className="my-5">{t("login.title")}</h2>
              <form onSubmit={onSubmitHandler}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">{t("signup.emailAddress")}</label>
                  <input type="email" className="form-control" value={userCtx.emailInput} onChange={userCtx.emailInputChange} aria-describedby="emailHelp" ref={emailInputRef} />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">{t("signup.password")}</label>
                  <input type="password" className="form-control" value={userCtx.passwordInput} onChange={userCtx.passwordInputChange} ref={passwordInputRef} />
                </div>
                <button type="submit" disabled={submitBtnDisabled} className="btn btn-primary" id="submitBtn">{t("general.submit")}</button>

                <hr style={{ color: "#ccc" }} className="my-4 mt-5" />

                <Link to={props.getLink('signup')}>{t("navigation.signup")}</Link> | <Link to={props.getLink('forgot-password')}>{t("login.forgotPassword")}</Link>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer getLink={props.getLink} />
    </>
  );
}

export default Login;
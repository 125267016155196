import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const SmvAcidityChart = (props) => {
  const acidity = parseFloat(props.acidity);
  const smv = parseFloat(props.smv);
  const options = {
    maintainAspectRatio: false,
    aspectRatio: 4,
    scales: {
      y: {
        title: {
          display: true,
          text: 'SMV'
        },
        min: -20,
        max: 20,
        ticks: {
          stepSize: 5,
          fontSize: 16,
          size: 40
        }
      },
      x: {
        title: {
          display: true,
          text: 'Acidity'
        },
        min: 0.6,
        max: 2.8,
        ticks: {
          stepSize: 0.2,
          fontSize: 16,
          size: 40
        }
      }
    }
  };

  const data = {
    datasets: [
      {
        label: props.name,
        data: [{
          x: acidity,
          y: smv
        }],
        backgroundColor: 'rgba(255, 99, 132, 1)',
      }
    ]
  }

  return <Scatter options={options} data={data} />;
}

export default SmvAcidityChart;
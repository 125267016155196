import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Header from '../../../layouts/frontend/Header';
import Breadcrumb from '../ui/Breadcrumb';

import imgBoxSet from '../../../assets/frontend/assets/whatsinaboxset/box-set.jpg';
import img2Bottles from '../../../assets/frontend/assets/whatsinaboxset/2xsake.png';
import imgSakeCards from '../../../assets/frontend/assets/whatsinaboxset/sake_cards.png';
import imgInfoCards from '../../../assets/frontend/assets/whatsinaboxset/info_cards.png';
import WhyMembership from './sections/WhyMembership';
import Footer from '../../../layouts/frontend/Footer';

import './WhatsInABoxSet.min.css';

const WhatsInABoxSet = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('navigation.howItWorks')} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb crumbs={[
          {text: t("navigation.home"), link: props.getLink("/")},
          {text: t("WhatsInABoxSet.subTitle"), link: ''}
      ]} />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="hiw">
        <div className="section-content pb-0">
          {/* <h1 className="display-7 pb-4" dangerouslySetInnerHTML={{ __html: t("WhatsInABoxSet.title") }} /> */}
          <h2 className="display-6 force-uppercase" dangerouslySetInnerHTML={{ __html: t("WhatsInABoxSet.subTitle") }} />
        </div>
        <br />
        <div className="container-fluid" style={{ backgroundColor: "#000" }}>
          <div className="row">
            <div className="col-md-12 image-holder" style={{ textAlign: "center" }}>
              <img src={imgBoxSet} style={{ paddingTop: "40px" }} alt="Box Set" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 image-holder" style={{ textAlign: "right" }}>
              <img src={img2Bottles} style={{ padding: "40px" }} alt="Box with 2 bottles"/>
            </div>
            <div className="col-md-6 text-holder" style={{ padding: "40px" }}>
              <h2 style={{ textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html:t("WhatsInABoxSet.twoBottleTitle") }}/>
              <div dangerouslySetInnerHTML={{ __html:t("WhatsInABoxSet.twoBottleText") }} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 image-holder" style={{ textAlign: "right" }}>
              <img src={imgSakeCards} style={{ padding: "40px" }} alt="Sake Cards" />
            </div>
            <div className="col-md-6 text-holder" style={{ padding: "40px" }}>
              <h2 style={{ textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html:t("WhatsInABoxSet.sakeCardsTitle") }} />
              <div dangerouslySetInnerHTML={{ __html:t("WhatsInABoxSet.sakeCardsText") }} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 image-holder" style={{ textAlign: "right" }}>
              <img src={imgInfoCards} style={{ padding: "40px" }} alt="Information Cards" />
            </div>
            <div className="col-md-6 text-holder" style={{ padding: "40px" }}>
              <h2 style={{ textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html:t("WhatsInABoxSet.infoCardsTitle") }} />
              <div dangerouslySetInnerHTML={{ __html:t("WhatsInABoxSet.infoCardsText") }} />
            </div>
          </div>
        </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
}

export default WhatsInABoxSet;
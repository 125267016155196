import React from 'react';
import { useTranslation } from 'react-i18next';
import { numberFormat } from '../../../../../assets/frontend/js/numberFormat';

const FreeDeliveryBar = (props) => {
  const { t } = useTranslation();
  const cartTotal = props.cartTotal;
  const freeToGoMessage = (msg, freeMsg, amtToFree) => {
    if (amtToFree > 0) {
      return t(msg, { amount: numberFormat(amtToFree) });
    } else {
      return t(freeMsg);
    }
  } 

  const amountToFree = process.env.REACT_APP_FREE_DELIVERY_ORDER_MINIMUM - cartTotal;
  const progressbarPercent = (cartTotal/process.env.REACT_APP_FREE_DELIVERY_ORDER_MINIMUM) * 100;

  return <>
    {/* <div className="text-center"><strong>{t("general.freeDeliveryMessage", { amount: "HK$" + process.env.REACT_APP_FREE_DELIVERY_ORDER_MINIMUM})}</strong></div> */}
    <div className="text-center" style={{ fontSize: "0.7rem", lineHeight: "1.2rem" }} dangerouslySetInnerHTML={{ __html: freeToGoMessage("general.amtToFreeMessage", "general.youHaveFreeDeliveryMessage", amountToFree) }} />
    <div className="progress" style={{ height: "10px" }}>
        <div className={`progress-bar progress-bar-striped progress-bar-animated ${amountToFree > 0 ? `bg-danger` : `bg-success`}`} role="progressbar" style={{ fontSize: "0.5rem", width: `${progressbarPercent}%` }} aria-valuenow={progressbarPercent} aria-valuemin="0" aria-valuemax="100"><strong>{freeToGoMessage("general.amtToGo", "general.freeDelivery", amountToFree)}</strong></div>
    </div>
  </>
}

export default FreeDeliveryBar;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../../../../layouts/frontend/Footer";
import Header from "../../../../layouts/frontend/Header";
import Breadcrumb from "../../ui/Breadcrumb";
import Loader from "../../ui/Loader";
import WhyMembership from "../sections/WhyMembership";
import Product from "./partials/Product";

axios.defaults.withCredentials = true;

const AllProducts = (props) => {
  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(true);
  const [products, setProducts] = useState(null);

  //params
  const [searchParams] = useSearchParams();
  
  const buildProductOptions = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push([key, value]);
    })

    return params;
  }

  const sortHandler = (e) => {
    switch (e.target.value) {
      case "sort-price-low-high":
        setProducts((prevProducts) => {
          const sortedProducts = prevProducts.sort((a, b) => {
            return a.relationships.product_skus[0].attributes.price - b.relationships.product_skus[0].attributes.price;
          });
          return [ ...sortedProducts ];
        });
        break;

      case "sort-price-high-low":
        setProducts((prevProducts) => {
          const sortedProducts = prevProducts.sort((a, b) => {
            return b.relationships.product_skus[0].attributes.price - a.relationships.product_skus[0].attributes.price;
          });
          return [ ...sortedProducts ];
        });
      break;

      default:
        setProducts((prevProducts) => {
          return [ ...props.allProducts ];
        });
    }
  }

  const getPageProducts = () => {
    const urlOptions = buildProductOptions();

    if (props.allProducts) {
      if (urlOptions.length > 0) {
          props.getProducts(urlOptions)
            .catch((err) => {
              setDataLoading(false);
              Swal.fire(
                t("errorMessages.swalDefaultTitle"),
                err.response.data.message,
                "error"
              );
            })
            .then((response) => {
              setDataLoading(false);
              setProducts(response.data.data);
              window.scrollTo(0, 0);
            });
      } else {
        setProducts([ ...props.allProducts ]); //sort is transformed on local, so need to spread for new reference of array
        setDataLoading(false);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageProducts();
  }, [props.allProducts, searchParams]);


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("ourProducts.title")} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          { text: t("OurProducts.titlePlain"), link: "" },
        ]}
      />

      <div className="liquor-law">{t("general.liquorLaw")}</div>

      <section className="breadcrumb-spacing" id="hiw">
        <div className="section-content pb-0">
          <h2
            className="display-6 force-uppercase"
            dangerouslySetInnerHTML={{ __html: t("OurProducts.subTitle") }}
          />
        </div>
        <br />

        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2">
              <div className="card" style={{ width: "100%", marginTop: "12px" }}>
                <div className="card-header">
                  <h3 style={{ margin: "0px" }}>Filters</h3>
                </div>
                <div className="card-body">
                  <h6 className="card-subtitle">Sorting</h6>
                  <select id="sorting" className="form-select" onChange={sortHandler}>
                    <option value="none">Select below...</option>
                    <option value="sort-price-low-high">Price Low to High</option>
                    <option value="sort-price-high-low">Price High to Low</option>
                  </select>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-10 d-flex flex-wrap justify-content-center">
              {dataLoading === true && <Loader />}
              {products &&
                dataLoading === false &&
                products.map((product, idx) => (
                  <Product
                    updateCart={props.updateCart}
                    product={product}
                    getLink={props.getLink}
                    key={idx}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>

      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
};

export default AllProducts;

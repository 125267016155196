import React from 'react';
import { useTranslation } from 'react-i18next';

const AddressSelect = (props) => {
  const { t } = useTranslation();
  const addressDeleteHandler = (addresses_id) => {
    props.addressDelete(addresses_id);
  }

  const addressSelectHandler = (e) => {
    props.addressSelect(e.target.value);
  }

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="card-text" onChange={addressSelectHandler}>
          {!props.addresses && "Loading..."}
            {props.addresses &&
              props.addresses.map((address, idx) => {
                return (
                  <div className="form-check" key={idx}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentMethod"
                      value={address.id}
                      id={`address${idx}`}
                      defaultChecked={address.is_default === 1}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`address${idx}`}
                    >
                      <strong>[{t("general." + address.attributes.type)}]</strong> - {address.attributes.name} - {address.attributes.floor} / {address.attributes.flat}, {address.attributes.building}, {address.attributes.street}, {address.relationships.district.name}<br />
                      <button className="btn btn-sm btn-warning" onClick={() => { addressDeleteHandler(address.id) }}>{t("general.deleteAddress")}</button>
                    </label>
                  </div>
                );
              })}   
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddressSelect;
import React from "react";
import { useTranslation } from "react-i18next";

const SubscriptionCheckbox = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {props.dataSetSku &&
        props.dataSetSku.relationships.set_sku_type.type === "subscription" && (
          <>
            <div className="card border-warning mb-3">
              <div className="card-body">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="yes"
                    ref={props.subscriptionCheckboxRef}
                    id="subscriptionCheckbox"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="subscriptionCheckbox"
                  >
                    {t("general.subCheckboxText")}
                    {' '}{props.dataSetSku.relationships.set_sku_type.payment_period_count}{' '}
                    {t("profile.months")}.
                  </label>
                </div>
              </div>
            </div>
            <br />
          </>
        )}
    </React.Fragment>
  );
};

export default SubscriptionCheckbox;

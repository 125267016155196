import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import imgLogoWhite from '../../assets/frontend/assets/logo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import "./Footer.min.css";

const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <footer>
        <div className="container px-5 py-5 text-white">
          <div className="row">
            <div className="col-lg-6">
              <img src={imgLogoWhite} style={{ width: "200px", marginTop: "-20px" }} alt="Just Sake" /><br /><br />
              <div className="footer-text" dangerouslySetInnerHTML={{ __html: t("footer.ourInfo")  }} />
            </div>
            <div className="col-lg-2">
              <h3 className="display-8 force-uppercase normal-weight">{t('footer.information')}</h3>
              <ul className="footer-list">
                <li className="list-group-item"><Link to={props.getLink("about")}>{t("navigation.aboutUs")}</Link></li>
                <li className="list-group-item"><Link to={props.getLink("terms-and-conditions")}>{t("footer.termsAndConditions")}</Link></li>
                <li className="list-group-item"><Link to={props.getLink("privacy")}>{t("footer.privacyPolicy")}</Link></li>
              </ul>
            </div>
            <div className="col-lg-2">
              <h3 className="display-8 force-uppercase normal-weight">{t('footer.customerService')}</h3>
              <ul className="footer-list">
                <li className="list-group-item"><Link to={props.getLink("profile")}>{t("navigation.yourAccount")}</Link></li>
                <li className="list-group-item"><Link to={props.getLink("orders")}>{t("navigation.orderedSets")}</Link></li>
                <li className="list-group-item"><Link to={props.getLink("deliveries")}>{t("navigation.deliveries")}</Link></li>
              </ul>
            </div>
            <div className="col-lg-2">
              <h3 className="display-8 force-uppercase normal-weight">{t('footer.latestNewsAndEvents')}</h3>
              <p className="footer-text" style={{ marginTop: "15px" }}>{t("footer.followInfo")}</p>
              <a href="https://www.facebook.com/justsakehk" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare} style={{ height: "20px", color: "#fff", marginRight: "10px" }} /></a>
              <a href="https://www.instagram.com/justsakehk" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagramSquare} style={{ height: "20px", color: "#fff" }} /></a>
            </div>
          </div>
        </div>

        <div className="px-5 py-5 text-white text-center" style={{ fontSize: "0.7rem" }}>
          Prices and offers are subject to change. © {moment().format('YYYY')} Just Alcohol Co. Ltd. All rights reserved.
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

import React from "react";
import { useTranslation } from "react-i18next";

const SetInfo = (props) => {
  const { t, i18n } = useTranslation();
  const langContentModifier = i18n.language === "en" ? "" : "_zh";
  const imgName = props.set.image_name;
  return (
    <>
      <div className="image-display" style={{ backgroundImage: "url(/assets/images/" + imgName + ")" }} />
      <div className="card-body">
        <h4 className="card-title">
          {props.set["name" + langContentModifier]}
          <span style={{ color: "#ccc" }}>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          </span>
          {
            props.set_sku_type[
              "name" + langContentModifier
            ]
          }
        </h4>
      </div>
      <div className="list-group-item" style={{ border: "0px", borderTop: "1px solid rgba(0,0,0,.125)" }}>
        <strong>{t("general.setContent")}:&nbsp;&nbsp;</strong>
        {props.set.product_quantity}
        {" x "}
        {
          props.product_type[
            "name" + langContentModifier
          ]
        }
      </div>
    </>
  );
}

export default SetInfo;
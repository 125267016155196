import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../../../../layouts/frontend/Footer";
import Header from "../../../../layouts/frontend/Header";
import ValidationErrors from "../../../../utility/ValidationErrors";
import Breadcrumb from "../../ui/Breadcrumb";
import WhyMembership from "../sections/WhyMembership";
import UserContext from "../../../../contexts/user-context";
import Swal from "sweetalert2";
import Helmet from "react-helmet";

axios.defaults.withCredentials = true;

const ResetPassword = (props) => {
  const { t } = useTranslation();
  const tokenInputRef = useRef();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const passwordConfirmInputRef = useRef();
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const { token } = useParams();
  const [ urlParams ] = useSearchParams();
  const email = urlParams.get('email') || '';
  const userCtx = useContext(UserContext);

  const resetPasswordHandler = (e) => {
    e.preventDefault();

    setSubmitBtnDisabled(true);

    axios
      .post(process.env.REACT_APP_API_URL + "reset_password", {
        email: emailInputRef.current.value,
        password: passwordInputRef.current.value,
        password_confirmation: passwordConfirmInputRef.current.value,
        token: token
      })
      .then((response) => {
        setSubmitBtnDisabled(false);
        if (response.data === true) {
          userCtx.redirectHandler(props.getLink('login'));

          Swal.fire(
            t("forgotPassword.resetSuccessTitle"),
            t("forgotPassword.resetSuccessTitle"),
            "success"
          );
        }
      })
      .catch((error) => {
        setSubmitBtnDisabled(false);
        if (error.response.data.error) {
          Swal.fire(
            t("forgotPassword.resetFailedTitle"),
            t("forgotPassword.resetFailedInfo"),
            "error"
          );
        }
        
        if (error.response.data.errors) {
          ValidationErrors(error.response.data.errors, [
            "email", "password"
          ], t);
        }
      });

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("forgotPassword.resetTitle")} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          { text: t("forgotPassword.resetTitle"), link: "" },
        ]}
      />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="forgot-password">
        <div className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6 pt-4 pb-5">
              <h2 className="my-5">{t("forgotPassword.resetTitle")}</h2>
              <form onSubmit={resetPasswordHandler}>
                <input type="hidden" ref={tokenInputRef} value={token} />
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {t("signup.emailAddress")}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    defaultValue={email}
                    ref={emailInputRef}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {t("signup.password")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    aria-describedby="emailHelp"
                    ref={passwordInputRef}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {t("signup.confirmPassword")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    aria-describedby="emailHelp"
                    ref={passwordConfirmInputRef}
                  />
                </div>

                <button
                  type="submit"
                  disabled={submitBtnDisabled}
                  className="btn btn-primary"
                  id="submitBtn"
                >
                  {t("general.submit")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
};

export default ResetPassword;

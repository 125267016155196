import React, { useContext, useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "./partials/CardForm";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../ui/Loader";
import Header from "../../../../layouts/frontend/Header";
import Breadcrumb from "../../ui/Breadcrumb";
import { useTranslation } from "react-i18next";
import LoaderText from "../../ui/LoaderText";
import Swal from "sweetalert2";
import AddressDisplay from "./partials/AddressDisplay";
import SubscriptionDeliveryInfo from "./partials/SubscriptionDeliveryInfo";
import CartSummary from "./partials/CartSummary";
import SubscriptionBillInfo from "./partials/SubscriptionBillInfo";
import CouponForm from "./partials/CouponForm";
import UserContext from "../../../../contexts/user-context";
import ValidationErrors from "../../../../utility/ValidationErrors";
import "./SubscriptionCardForm.min.css";
import Footer from "../../../../layouts/frontend/Footer";
import Helmet from "react-helmet";
import ReactGA from "react-ga4";
import ReactFBPixel from "react-facebook-pixel";
import CartContext from "../../../../contexts/cart-context";

axios.defaults.withCredentials = true;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SubscriptionCardForm = (props) => {
  const { t, i18n } = useTranslation();
  const couponCodeRef = useRef();
  const [clientSecret, setClientSecret] = useState("");
  const [setupIntentId, setSetupIntentId] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [cart, setCart] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [userAddresses, setUserAddresses] = useState(null);
  const [country, setCountry] = useState(null);
  const [checkoutIsLoading, setCheckoutIsLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState(null);
  // const [cartItemSwitched, setCartItemSwitched] = useState(false);
  // const [itemConflictWith, setItemConflictWith] = useState(false);
  const [productCartItems, setProductCartItems] = useState(null);
  const [setCartItems, setSetCartItems] = useState(null);
  const [dataSetSku, setDataSetSku] = useState(null);
  const storedUserToken = localStorage.getItem("userToken");
  const langContentModifier = i18n.language === "en" ? "" : "_zh";

  // this is defined if we are doing a direct subscription link.  Otherwise, its a regular checkout
  // if (setSku !== undefined) { ... 
  let { setSku } = useParams(); 
  
  const userCtx = useContext(UserContext);
  const cartCtx = useContext(CartContext);

  const createSetupIntent = (_dataSetSku) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "payment/create_setup_intent",
        {
          payment_method_types: "card",
          set_skus_id: _dataSetSku.relationships.set_sku.id,
        },
        {
          headers: { Authorization: `Bearer ${storedUserToken}` },
        }
      )
      .then((response) => {
        setSetupIntentId(response.data.id);
        setClientSecret(response.data.client_secret);
      })
      .catch((e) => {
        return e;
      });
  };

  const createPaymentIntent = (_dataSetSku) => {
    const options = _dataSetSku !== null ? {
      payment_method_types: "card",
      currency: "hkd",
      set_skus_id: _dataSetSku.relationships.set_sku.id,
    } : {
      payment_method_types: "card",
      currency: "hkd"
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + "payment/create_payment_intent",
        options,
        {
          headers: { Authorization: `Bearer ${storedUserToken}` },
        }
      )
      .then((response) => {
        setPaymentIntentId(response.data.id);
        setClientSecret(response.data.client_secret);
      })
      .catch((e) => {
        return e;
      });
  };

  const addressSubmit = async (data) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "user/addresses", data, {
        headers: {
          Authorization: `Bearer ${storedUserToken}`,
        },
      })
      .then((response) => {
        // #TODO - should delivery address be default address?
        if (response.data.deliveryAddress) {
          setDeliveryAddress(response.data.deliveryAddress);
          if (
            !document
              .getElementById("collapseDelivery")
              .classList.contains("show")
          ) {
            document.getElementById("collapseDeliveryButton").click();
          }
        }

        if (response.data.userAddresses) {
          setUserAddresses(response.data.userAddresses);
        }
      })
      .catch((e) => {
        ValidationErrors(e.response.data.errors, [
          "block",
          "building",
          "email",
          "flat",
          "floor",
          "name",
          "phone",
          "street",
        ], t);
      });
  };

  

  const addressSelect = async (addresses_id) => {
    await axios
      .post(
        process.env.REACT_APP_API_URL + "cart/address",
        { addresses_id: addresses_id },
        {
          headers: {
            Authorization: `Bearer ${storedUserToken}`,
          },
        }
      )
      .then((response) => {
        setDeliveryAddress(response.data.data.relationships.address);
        if (
          !document
            .getElementById("collapseDelivery")
            .classList.contains("show")
        ) {
          document.getElementById("collapseDeliveryButton").click();
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const addressDelete = async (addresses_id) => {
    await axios
      .delete(
        process.env.REACT_APP_API_URL + "user/addresses/" + addresses_id,
        {
          headers: {
            Authorization: `Bearer ${storedUserToken}`,
          },
        }
      )
      .then((response) => {
        const r = response.data;
        setDeliveryAddress(r.deliveryAddress);
        setUserAddresses(r.userAddresses);

        if (r.deliveryAddress !== null) {
          if (
            !document
              .getElementById("collapseDelivery")
              .classList.contains("show")
          ) {
            document.getElementById("collapseDeliveryButton").click();
          }
        }
      })
      .catch((e) => {
        return e;
      });
  };

  const couponSubmit = async () => {
    await axios
      .post(
        process.env.REACT_APP_API_URL + "cart/coupon",
        {
          coupon_code: couponCodeRef.current.value,
        },
        {
          headers: { Authorization: `Bearer ${storedUserToken}` },
        }
      )
      .then((response) => {
        setCart(response.data.data);
        Swal.fire(
          "Coupon Applied!",
          "Your coupon has been applied to you cart!",
          "success"
        );
        couponCodeRef.current.value = "";
      })
      .catch((e) => {
        if (e.response.data.errors === undefined) {
          Swal.fire(
            t("errorMessages.swalDefaultTitle"),
            e.response.data.error,
            "error"
          );
        } else {
          ValidationErrors(e.response.data.errors, ["coupon_code"], t);
        }
      });
  };

  const couponRemove = async (coupons_id) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "cart/coupon/" + coupons_id, {
        headers: { Authorization: `Bearer ${storedUserToken}` },
      })
      .then((response) => {
        setCart(response.data.data);
        Swal.fire(
          "Coupon Removed!",
          "Your coupon has been removed from cart.",
          "success"
        );
        couponCodeRef.current.value = "";
      })
      .catch((e) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          e.response.data.message,
          "error"
        );
      });
  };

  const setupCheckout = () => {
    var url = '';
    if (setSku !== undefined) {
      // checking out a set
      url = process.env.REACT_APP_API_URL + "cart/checkout?set_skus_id=" + setSku;
    } else {
      // checking out the cart
      url = process.env.REACT_APP_API_URL + "cart/checkout";
    }

    axios
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${storedUserToken}`,
          },
        }
      )
      .then((response) => {
        setupCheckoutData(response);
      })
      .catch((err) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
        setCheckoutIsLoading(false);
      });
  };

  const setupCheckoutData = (response) => {
    const d = response.data;
    const _dataSetSku = d.set_cart_items !== null ? d.set_cart_items[0] : null;
    setCart(d.cart);
    setDeliveryAddress(d.delivery_address);
    setUserAddresses(d.user_addresses);
    setPaymentMethods(d.payment_methods);
    // setCartItemSwitched(d.cart_item_switched);
    // setItemConflictWith(d.item_conflict_with);
    setProductCartItems(d.product_cart_items);
    setSetCartItems(d.set_cart_items);
    setDataSetSku(_dataSetSku);
    setCountry(d.country);
    setCheckoutIsLoading(false);

    if (_dataSetSku !== null) {
      if (
        _dataSetSku.relationships.set_sku_type.type === "subscription"
      ) {
        createSetupIntent(_dataSetSku);
      } else {
        createPaymentIntent(_dataSetSku);
      }
    } else {
      // create payment intent for regular cart
      createPaymentIntent(null);
    }
  }

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      //send GA event
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.event('begin_checkout', {
        'event_category': 'ecommerce',
        'event_label': 'ecommerce'
      });

      // fb pixel
      const advancedMatching = {};
      const options = {
        autoConfig: true,
        debug: false
      };
      ReactFBPixel.init('6703591589656745', advancedMatching, options);
      ReactFBPixel.track('InitiateCheckout');
    }

    // remove any previously set gaPurchaseEvent flag.  This is used to prevent duplicate purchase events sending.
    localStorage.removeItem('gaEventSent');

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (setSku === undefined && cartCtx.cart.items !== undefined) {
      axios
        .post(process.env.REACT_APP_API_URL + "cart",
          {
            cart_items: cartCtx.cart.items,
            update_type: 'fixed',
            replace_cart: true
          },
          {
            headers: { Authorization: `Bearer ${storedUserToken}` },
          }
        )
        .catch((err) => {
          console.log(err);
        })
        .then((response) => {
          // console.log(response);
          setupCheckoutData(response);
        });
    } else {
      setupCheckout();
    }
  }, [cartCtx.cart, setSku]);

  // TODO perform add to cart code here
  // TODO if user has already subscribed, show them they have subscribed, and provide ways to upgrade subscription (if any)
  // TODO if they already have a previous subscription, need to determine if they would like to swap, or, add new subscription.

  const text =
    dataSetSku?.relationships?.set_sku_type["name" + langContentModifier] ===
    undefined ? (
      <LoaderText />
    ) : (
      dataSetSku?.relationships?.set_sku_type["name" + langContentModifier]
    );

  const crumbData = (setSku === undefined) ? [
    { text: t("navigation.home"), link: props.getLink("/") },
    { text: t('checkout.checkoutTitleSingle'), link: "" }
  ] : [
    { text: t("navigation.home"), link: props.getLink("/") },
    {
      text: dataSetSku?.relationships?.set[
        "name" + langContentModifier
      ] ?? <LoaderText />,
      link: props.getLink(
        "start-your-journey/" +
          dataSetSku?.relationships?.set_type?.slug +
          "/" +
          dataSetSku?.relationships?.set?.slug
      ),
    },
    { text: text, link: "" },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("checkout.checkoutTitle")} | JustSaké Hong Kong
        </title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb crumbs={crumbData} />

      <div className="liquor-law checkout">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="subscription-card-form">
        <div className="section-content pb-0">
          <h1 className="display-7">
            {dataSetSku?.relationships.set_sku_type.period_type === 'single' && t("checkout.checkoutTitleSingle")}
            {dataSetSku?.relationships.set_sku_type.period_type !== 'single' && t("checkout.checkoutTitle")}
          </h1>
          <br />
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-start">
                <h3 style={{ textAlign: "center" }}>{t("general.productAndPayments")}</h3>

                <div id="apply-coupon">
                  <CouponForm
                    couponCodeRef={couponCodeRef}
                    couponSubmit={couponSubmit}
                  />
                  <br />
                </div>

                <div id="order-summary">
                  {cart && <CartSummary
                    checkoutIsLoading={checkoutIsLoading}
                    productCartItems={productCartItems}
                    setCartItems={setCartItems}
                    cart={cart}
                    couponRemove={couponRemove}
                    getLink={props.getLink}
                  />}
                  
                  <br />
                </div>

                {checkoutIsLoading && <Loader />}
                {!checkoutIsLoading && (
                  <AddressDisplay
                    deliveryAddress={deliveryAddress}
                    userAddresses={userAddresses}
                    addressSubmit={addressSubmit}
                    addressSelect={addressSelect}
                    addressDelete={addressDelete}
                    country={country}
                    name={userCtx?.user?.attributes?.name}
                    phone={userCtx?.user?.attributes?.phone}
                    email={userCtx?.user?.attributes?.email}
                  />
                )}

                {!clientSecret && <Loader />}
                {clientSecret && (
                  <Elements stripe={stripePromise}>
                    <CardForm
                      dataSetSku={dataSetSku}
                      setSku={setSku}
                      clientSecret={clientSecret}
                      paymentIntentId={paymentIntentId}
                      setupIntentId={setupIntentId}
                      getLink={props.getLink}
                      paymentMethods={paymentMethods}
                      deliveryAddress={deliveryAddress}
                      setPaymentMethods={setPaymentMethods}
                    />
                  </Elements>
                )}
                <br />
              </div>
              <div className="col-lg-6 text-start">
                {checkoutIsLoading && <Loader />}
                {(!checkoutIsLoading && (setCartItems !== null || productCartItems !== null)) && (
                  <SubscriptionDeliveryInfo dataSetSku={dataSetSku} productCartItems={productCartItems} />
                )}

                {/* {checkoutIsLoading && <Loader />}
                {!checkoutIsLoading && productCartItems && (
                  // #TODO - Display for products if we start selling single item products.
                  <>
                    <h4>Ordered Products</h4>
                    { cartCtx.isCartEmpty() === false && <CartItems cart={cartCtx.cart} updateCartHandler={updateCartHandler} /> }
                  </>
                )} */}

                {/* {checkoutIsLoading && <Loader />}
                {!checkoutIsLoading && deliveryAddress && (
                  <AddressDisplay
                    deliveryAddress={deliveryAddress}
                    userAddresses={userAddresses}
                    addressSubmit={addressSubmit}
                    addressSelect={addressSelect}
                    addressDelete={addressDelete}
                    country={country}
                  />
                )} */}

                {checkoutIsLoading && <Loader />}
                {(!checkoutIsLoading && dataSetSku !== null) && (
                  <>
                    <SubscriptionBillInfo dataSetSku={dataSetSku} />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer getLink={props.getLink} />
    </>
  );
};

export default SubscriptionCardForm;

import React from "react";
import { useTranslation } from "react-i18next";

const SubscriptionBillInfo = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {(props.dataSetSku === null ||
        (props.dataSetSku &&
          props.dataSetSku.relationships.set_sku_type.type === "prepay")) && (
        <div></div>
      )}
      {props.dataSetSku &&
        props.dataSetSku.relationships.set_sku_type.type === "subscription" && (
          <div
            className="accordion accordion-flush"
            id="sub-delivery-info"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  {t("general.subscriptionBillingInfo")}
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#sub-delivery-info"
              >
                <div className="accordion-body">
                  <div className="card border-light mb-3">
                    <div className="card-header">{t("general.subscriptionInfo")}</div>
                    <div className="card-body">
                      <h5 className="card-title"><strong>{t("general.howWeBillSubscription")}</strong></h5>
                      <p className="card-text">
                        {t("general.howWeBillSubscriptionDescription")}
                      </p>

                      <br />
                      <h5 className="card-title"><strong>{t("general.subscriptionCancellations")}</strong></h5>
                      <p className="card-text" dangerouslySetInnerHTML={{ __html: t("general.subscriptionCancellationsDescription") }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default SubscriptionBillInfo;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../../../../layouts/frontend/Footer";
import Header from "../../../../layouts/frontend/Header";
import Breadcrumb from "../../ui/Breadcrumb";
import Loader from "../../ui/Loader";
import WhyMembership from "../sections/WhyMembership";

import "./OrderComplete.min.css";
import SubscriptionDeliveryInfo from "../checkout/partials/SubscriptionDeliveryInfo";
import Address from "../checkout/partials/Address";
import CartSummary from "../checkout/partials/CartSummary";
import CardSummary from "../checkout/partials/CardSummary";
import SubscriptionBillInfo from "../checkout/partials/SubscriptionBillInfo";
import Helmet from "react-helmet";
import ReactGA from "react-ga4";
import ReactFBPixel from "react-facebook-pixel";

axios.defaults.withCredentials = true;

const OrderComplete = (props) => {
  const { t } = useTranslation();
  const [orderIsLoading, setOrderIsLoading] = useState(true);
  const [dataOrder, setDataOrder] = useState(null);
  const [dataSetSku, setDataSetSku] = useState(null);
  const [setOrderItems, setSetOrderItems] = useState(null);
  const [productOrderItems, setProductOrderItems] = useState(null);
  const storedUserToken = localStorage.getItem("userToken");
  const gaEventSent = localStorage.getItem("gaEventSent");
  const { order } = useParams();

  const getOrder = async () => {
    const data = await axios.get(
      process.env.REACT_APP_API_URL + "user/order/" + order,
      {
        headers: {
          Authorization: `Bearer ${storedUserToken}`,
        },
      }
    );
    return data;
  };

  useEffect(() => {
    getOrder()
      .catch((err) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
      })
      .then((response) => {
        if (typeof response != "undefined") {
          const setSku = response.data.set_order_items !== null ? response.data.set_order_items.find((set_order_item) => set_order_item.relationships.set_sku !== null) : null;
          setDataOrder(response.data.order);
          setDataSetSku(setSku);
          setSetOrderItems(response.data.set_order_items);
          setProductOrderItems(response.data.product_order_items);
          setOrderIsLoading(false);

          window.scrollTo(0, 0);

          const couponCode = response.data.order.relationships.order_coupons.length > 0 ? response.data.order.relationships.order_coupons[0].relationships.coupon : '';
          if (
            !window.location.href.includes("localhost") &&
            (gaEventSent === "false" ||
              gaEventSent === null ||
              gaEventSent === undefined)
          ) {
            ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

            if (setOrderItems !== null) {
              ReactGA.event('purchase', {
                transaction_id: order,
                value: response.data.data.attributes.total,
                shipping: response.data.data.attributes.delivery_fee_total,
                currency: "HKD",
                items: [{
                  item_id:
                    setOrderItems.relationships.set.slug +
                    "_" +
                    setOrderItems.relationships.set_sku_type.period_type,
                  item_name:
                    setOrderItems.relationships.set.name +
                    " (" +
                    setOrderItems.relationships.set_sku_type.period_type +
                    ")",
                  currency: "HKD",
                  coupon: couponCode,
                  discount: response.data.data.attributes.coupon_discount_total,
                  item_brand: setOrderItems.relationships.supplier.name,
                  item_category: response.data.data.attributes.order_type === "subscription"
                    ? "subscription"
                    : "single_purchase",
                  item_category2: setOrderItems.relationships.set.category,
                  quantity: setOrderItems.attributes.quantity,
                  price: setOrderItems.attributes.unit_price
                }]
              });

              // fb pixel
              const advancedMatching = {};
              const options = {
                autoConfig: true,
                debug: false
              };
              ReactFBPixel.init('6703591589656745', advancedMatching, options);

              const name = setOrderItems.relationships.set.name +
                  " (" +
                  setOrderItems.relationships.set_sku_type.period_type +
                  ")";
              if (response.data.data.attributes.order_type === 'subscription') {
                const ltv = setOrderItems.relationships.set_sku_type.payment_period_count === 1 ? setOrderItems.attributes.unit_price * 3 : setOrderItems.attributes.unit_price;
                
                ReactFBPixel.track('Subscribe', {
                  value: setOrderItems.attributes.unit_price,
                  currency: "HKD",
                  num_items: 1,
                  content_name: name,
                  content_type: "box-set",
                  predicted_ltv: ltv
                });
              } else {
                ReactFBPixel.track('Purchase', {
                  value: setOrderItems.attributes.unit_price,
                  num_items: 1,
                  content_name: name,
                  content_type: "box-set",
                  currency: "HKD"
                });
              }

              localStorage.setItem("gaEventSent", "true");
            }

            // #TODO - GA & FB for product items
            if (productOrderItems !== null) {

            }
          }
        }
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("general.orderComplete")} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          { text: t("general.thankYou"), link: "" },
        ]}
      />

      <div className="liquor-law">{t("general.liquorLaw")}</div>

      <section className="breadcrumb-spacing" id="order-complete">
        <div className="section-content pb-5">
          <h1
            className="display-7"
            dangerouslySetInnerHTML={{ __html: t("general.orderComplete") }}
          />
          <h4>{t("general.yourOrderInfo")}</h4>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 style={{ textAlign: "center" }}>
                {t("general.productAndPayments")}
              </h3>
              <div id="order-summary">
                {!dataOrder && (!setOrderItems || !productOrderItems) && <Loader />}
                {dataOrder && (setOrderItems || productOrderItems) && (
                  <>
                    <CartSummary
                      checkoutIsLoading={false}
                      productCartItems={productOrderItems}
                      setCartItems={setOrderItems}
                      cart={dataOrder}
                      from="order"
                      getLink={props.getLink}
                    />
                    {/* <CartSummary
                      setCartItems={dataOrder.relationships.order_items}
                      cart={dataOrder}
                      from="order"
                    /> */}
                    <br />
                    <CardSummary
                      payment={JSON.parse(dataOrder.attributes.payment_data)}
                    />
                    <br />
                  </>
                )}

                {!orderIsLoading && dataSetSku && (
                  <SubscriptionBillInfo dataSetSku={dataSetSku} />
                )}
              </div>
            </div>
            <div className="col-lg-6">
              {orderIsLoading && <Loader />}
              {!orderIsLoading && (dataSetSku || productOrderItems) && (
                <>
                  <SubscriptionDeliveryInfo dataSetSku={dataSetSku} productCartItems={productOrderItems} />
                  <Address
                    address={JSON.parse(dataOrder.attributes.address_data)}
                  />
                  <br />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
};

export default OrderComplete;

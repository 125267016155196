import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const Protected = ({isLoggedIn, redirectLoggedIn, redirectNotLoggedIn, children}) => {
  const { pathname } = useLocation();

  if (isLoggedIn === true && redirectLoggedIn !== '') {
    return <Navigate to={redirectLoggedIn} replace />;
  } 

  if (isLoggedIn === false && redirectNotLoggedIn !== '') {
    localStorage.setItem('protectedHistory', pathname);
    return <Navigate to={redirectNotLoggedIn} replace />;
  }

  return children;
}

export default Protected;
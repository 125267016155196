import React from "react";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../../../assets/frontend/js/numberFormat";

import "./CartItem.min.css";
import CartItemButtons from "./CartItemButtons";
import CartItemName from "./CartItemName";

const CartItem = (props) => {
  const { t } = useTranslation();

  const image_url =
    props.cartItem.item !== undefined
      ? props.cartItem.item.product_image_name_square
      : props.cartItem.relationships.product_sku.product_image_name_square;
  let subTotal = "";
  const quantity =
    props.cartItem.quantity !== undefined
      ? props.cartItem.quantity
      : props.cartItem.attributes.quantity;
  const set_skus_id =
    props.cartItem.set_skus_id !== undefined
      ? props.cartItem.set_skus_id
      : props.cartItem.attributes.set_skus_id;
  const product_skus_id =
    props.cartItem.product_skus_id !== undefined
      ? props.cartItem.product_skus_id
      : props.cartItem.attributes.product_skus_id;
  const itemAttribute =
    props.cartItem.item !== undefined
      ? props.cartItem.item
      : props.cartItem.relationships.product_sku;
  const itemType =
    props.cartItem.item_type !== undefined
      ? props.cartItem.item_type
      : props.cartItem.relationships.product_type;
  const price = 
    props.cartItem.set_sku_type !== undefined
      ? props.cartItem.set_sku_type.price
      : itemAttribute.price_data[props.totalBottlesToPrice - 1].price;
  const itemBrewery =
    props.cartItem.item_brewery !== undefined
      ? props.cartItem.item_brewery
      : null;

  const productDetailHandler = (url) => {
    if (url === null) return;
    props.showCartHandler();
    window.open(url);
  };

  subTotal = parseInt(quantity) * parseFloat(price);

  return (
    <>
      <tr className="cart-item">
        <td
          className="img-container"
          onClick={() =>
            productDetailHandler(
              props.getLink("products/" + props.cartItem.product.slug)
            )
          }
        >
          <img src={image_url} />
        </td>
        <td className="item-name">
          <CartItemName
            setSkusId={set_skus_id}
            productSkusId={product_skus_id}
            cartItem={props.cartItem}
            itemAttribute={itemAttribute}
            itemBrewery={itemBrewery}
            productDetailHandler={productDetailHandler}
            getLink={props.getLink}
            itemType={itemType}
          />
        </td>
        <td className="item-quantity text-center d-none d-sm-table-cell">
          {props.from === undefined && (
            <div className="d-none d-sm-block">
              <CartItemButtons
                updateCartHandler={props.updateCartHandler}
                setSkusId={set_skus_id}
                productSkusId={product_skus_id}
                quantity={quantity}
              />
            </div>
          )}
          {props.from === 'order' && quantity}
        </td>
        <td className="cart-sub-total">
          {numberFormat(subTotal)}
          <div className="item-price">
            {numberFormat(price)}/{t("general.perBottle")}
            {(itemAttribute.msrp_price - price > 0) && <span className="badge bg-success">
              {numberFormat(itemAttribute.msrp_price - price)}/
              {t("general.perBottle")} OFF!
            </span>}
            {props.from === undefined && (
              <div className="d-block d-sm-none cart-mobile-buttons">
                <CartItemButtons
                  updateCartHandler={props.updateCartHandler}
                  setSkusId={set_skus_id}
                  productSkusId={product_skus_id}
                  quantity={quantity}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default CartItem;

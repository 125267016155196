import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./AddressForm.min.css";

const AddressForm = (props) => {
  const { t, i18n } = useTranslation();
  const [ isDefault, setIsDefault ] = useState(1);
  const [ type, setType ] = useState('home');
  const [ name, setName ] = useState(props.name ?? '');
  const [ phone, setPhone ] = useState(props.phone ?? '');
  const [ email, setEmail ] = useState(props.email ?? '');
  const [ flat, setFlat ] = useState('');
  const [ floor, setFloor ] = useState('');
  const [ block, setBlock ] = useState('');
  const [ building, setBuilding ] = useState('');
  const [ street, setStreet ] = useState('');
  const [ district, setDistrict ] = useState(1);
  const langContentModifier = (i18n.language === 'en') ? '' : '_zh';

  const isDefaultHandler = (e) => {
    setIsDefault(e.target.value);
  }

  const typeHandler = (e) => {
    setType(e.target.value);
    if (e.target.value === 'gift') {
      setIsDefault(0);
      document.getElementById('is-default0').checked = true;
    }
  }

  const nameHandler = (e) => {
    setName(e.target.value);
  }

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  }

  const emailHandler = (e) => {
    setEmail(e.target.value);
  }

  const flatHandler = (e) => {
    setFlat(e.target.value);
  }

  const floorHandler = (e) => {
    setFloor(e.target.value);
  }

  const blockHandler = (e) => {
    setBlock(e.target.value);
  }

  const buildingHandler = (e) => {
    setBuilding(e.target.value);
  }

  const streetHandler = (e) => {
    setStreet(e.target.value);
  }

  const districtHandler = (e) => {
    setDistrict(e.target.value);
  }
  
  const addressSubmitHandler = (e) => {
    e.preventDefault();
    props.addressSubmit({
      'is_default': isDefault,
      'type': type,
      'name': name,
      'phone': phone,
      'email': email,
      'flat': flat,
      'floor': floor,
      'block': block,
      'building': building,
      'street': street,
      'districts_id': district,
      'set_cart_selected': 1
    });
  }

  return (
    <React.Fragment>
      <form onSubmit={addressSubmitHandler} style={{ padding: "0px", border: "0px", boxShadow: "none" }}>
        <h5 className="card-title">{t("general.enterDeliveryAddress")}</h5>
        <div className="card-text">
          <label htmlFor="exampleInputEmail1">{t("general.defaultAddress")}</label>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="is-default" onClick={isDefaultHandler} value="1" id="is-default1" defaultChecked={isDefault} />
            <label className="form-check-label" htmlFor="is-default1">
              {t("general.yes")}
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="is-default" onClick={isDefaultHandler} value="0" id="is-default0" defaultChecked={!isDefault} />
            <label className="form-check-label" htmlFor="is-default2">
            {t("general.no")}
            </label>
          </div>
          <small id="emailHelp" className="form-text text-muted">
            {t("general.defaultAddressInfo")}
          </small><br /><br />

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">{t("general.addressType")}:</label>
            <br />
            <select id="type" className="form-select" value={type} onChange={typeHandler}>
              <option value="home">{t("general.home")}</option>
              <option value="gift">{t("general.gift")}</option>
            </select>
            <small id="emailHelp" className="form-text text-muted">
              {t("general.addressTypeDescription")}
            </small>
          </div>

          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                name="name"
                id="name"
                placeholder={`${t("general.name")}*`}
                className="form-control"
                value={name}
                onChange={nameHandler}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder={`${t("general.contactPhone")}*`}
                className="form-control"
                required={true}
                value={phone}
                onChange={phoneHandler}
              />
            </div>
            <div className="col">
              <input
                type="text"
                name="email"
                id="email"
                placeholder={t("general.emailAddress")}
                className="form-control"
                value={email}
                onChange={emailHandler}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                name="flat"
                id="flat"
                placeholder={t("general.flat")}
                className="form-control"
                value={flat}
                onChange={flatHandler}
              />
            </div>
            <div className="col">
              <input
                type="text"
                name="floor"
                id="floor"
                placeholder={t("general.floor")}
                className="form-control"
                value={floor}
                onChange={floorHandler}
              />
            </div>
            <div className="col">
              <input
                type="text"
                name="block"
                id="block"
                placeholder={t("general.block")}
                className="form-control"
                value={block}
                onChange={blockHandler}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                name="building"
                id="building"
                placeholder={t("general.building")}
                className="form-control"
                value={building}
                onChange={buildingHandler}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                name="street"
                id="street"
                placeholder={`${t("general.street")}*`}
                className="form-control"
                value={street}
                onChange={streetHandler}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <label htmlFor="exampleInputEmail1">{t("general.district")}*:</label>
              <br />
              {!props.country && "Loading..."}
              {props.country && (
                <select id="district" className="form-select" value={district} onChange={districtHandler}>
                  {props.country.relationships.regions.map((region, regionIdx) => {
                    return (
                      <optgroup label={region.attributes["name" + langContentModifier]} key={regionIdx}>
                        {region.relationships.districts.map((district, districtIdx) => {
                          return (<option value={district.id} key={districtIdx}>{district["name" + langContentModifier]}</option>);
                        })}
                      </optgroup>
                    );
                  })}  
                </select>
              )}
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success btn-sm">
          {t("general.useAddress")}
        </button>
      </form>
    </React.Fragment>
  );
};

export default AddressForm;

import React from "react";
import Product from "./Product";

const ProductsList = (props) => {
  return (
    <>
      {props.setParam === undefined && (
        <table className="table">
          <tbody>
            {props.setProductSkus &&
              props.setProductSkus.map((setProductSku, idx) => (
                <Product
                  updateCart={props.cartCtx.updateCart}
                  product={setProductSku.relationships.product}
                  type="set-details"
                  display="table-row"
                  getLink={props.getLink}
                  key={idx}
                />
              ))}
          </tbody>
        </table>
      )}

      {props.setParam !== undefined &&
        props.setProductSkus &&
        props.setProductSkus.map((setProductSku, idx) => (
          <Product
            updateCart={props.cartCtx.updateCart}
            product={setProductSku.relationships.product}
            type="set-details"
            display="card"
            getLink={props.getLink}
            key={idx}
          />
        ))}
    </>
  );
};

export default ProductsList;

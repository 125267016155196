import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import UserContext from '../../../../contexts/user-context';
import Footer from '../../../../layouts/frontend/Footer';
import Header from '../../../../layouts/frontend/Header';
import Breadcrumb from '../../ui/Breadcrumb';
import SetCard from '../../ui/cards/SetCard';
import Dropdown from '../../ui/Dropdown';
import Loader from '../../ui/Loader';
import LoaderText from '../../ui/LoaderText';
import './Sets.min.css';
import ReactFBPixel from "react-facebook-pixel";

axios.defaults.withCredentials = true;

const Sets = (props) => {
  const { t, i18n } = useTranslation();
  const [ dataSetTypes, setDataSetTypes ] = useState(null);
  const [ dataSets, setDataSets ] = useState(null);
  const [ dataLoading, setDataLoading ] = useState(true);
  const storedUserToken = localStorage.getItem('userToken');
  const langContentModifier = (i18n.language === 'en') ? '' : '_zh';
  const userCtx = useContext(UserContext);
  let { setType } = useParams();
  let { set } = useParams();
  
  const getData = async () => {
    const url = process.env.REACT_APP_API_URL + 'set_types/' + setType;
    const data = await axios.get(url, {
      headers: {
        "Authorization": `Bearer ${storedUserToken}`
      }
    });
    return data;
  }

  useEffect(() => {
    setDataLoading(true);

    getData()
    .catch((err) => {
      Swal.fire(t("errorMessages.swalDefaultTitle"), err.response.data.message, "error");
    })
    .then((response) => {
      setDataLoading(false);
      if (typeof response != "undefined") {
        setDataSetTypes(response.data.data);

        const sets = set ? response.data.data.relationships["sets"].filter(_set => _set.attributes.slug === set) : response.data.data.relationships["sets"];
        if (sets.length === 0) {
          userCtx.redirectHandler(props.getLink('start-your-journey/' + response.data.data.attributes.slug));
        }
        setDataSets(sets);
      } else {
        Swal.fire(t("errorMessages.swalDefaultTitle"), t("errorMessages.noData"), "error");
      }
    });

    window.scrollTo(0, 0);
  }, [setType, set]);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      // fb pixel
      const advancedMatching = {};
      const options = {
        autoConfig: true,
        debug: false
      };
      ReactFBPixel.init('6703591589656745', advancedMatching, options);
      ReactFBPixel.track('ViewContent', {
        content_name: setType
      });
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${dataSets?.[0].attributes["name" + langContentModifier]}`} | {`${dataSetTypes?.attributes["name" + langContentModifier]}`} | {t('startYourJourney.title')} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      {!set && (
        <Breadcrumb
          crumbs={[
            { text: t("navigation.home"), link: props.getLink("/") },
            { text: t("startYourJourney.title"), link: props.getLink("start-your-journey") },
            { text: dataSetTypes?.attributes["name" + langContentModifier] ?? <LoaderText />, link: "" }
          ]}
        />
      )}
      {set && (
        <Breadcrumb
          crumbs={[
            { text: t("navigation.home"), link: props.getLink("/") },
            { text: t("startYourJourney.title"), link: props.getLink("start-your-journey") },
            { text: dataSetTypes?.attributes["name" + langContentModifier] ?? <LoaderText />, link: props.getLink('start-your-journey/' + dataSetTypes?.attributes["slug"]) },
            { text: dataSets?.[0].attributes["name" + langContentModifier] ?? <LoaderText />, link: "" }
          ]}
        />
      )}

      <div className="liquor-law">{t('general.liquorLaw')}</div>
      
      <section className="breadcrumb-spacing" id="introductory-sets">
        <div className="section-content pb-0">
          <div className="container">
            {set === undefined && <div className="row mb-5">
              <div className="col-md-12 text-center">
                <strong className="fs-6 ps-0">{t("boxSelection.subTitle")}</strong>
                <div className="d-grid gap-2 my-2">
                  <Dropdown getLink={props.getLink} type="warning" size="sm" selected={setType} items={[
                    { id: "introductory-sets", text: t("boxSelection.subTitleIntro"), link: props.getLink("start-your-journey/introductory-sets") },
                    { id: "special-sets", text: t("boxSelection.subTitleAvantGarde"), link: props.getLink("start-your-journey/special-sets") },
                    // { id: "all", text: t("boxSelection.subTitleEverything"), link: props.getLink("start-your-journey/all") }
                  ]} />
                </div>
              </div>
            </div>}
            <div className="row">
              <div className="col-md-12 text-start">
                <div className="container-fluid text-start item-container">
                  {set === undefined && <div className="row-cols-1 mb-5">
                    <h1 className="display-6">{dataSetTypes?.attributes["name" + langContentModifier] ?? <LoaderText />}</h1>
                    <div className="d-none d-lg-block">{dataSetTypes?.attributes["description" + langContentModifier] ?? <LoaderText />}</div>
                  </div>}
                  <div className="row">
                    <div className="col-md-12">
                      {dataLoading === true && <Loader />}
                      {dataSets && dataLoading === false && dataSets.map((_set, idx) => <SetCard
                        key={idx}
                        getLink={props.getLink}
                        setType={dataSetTypes}
                        set={_set}
                        setParam={set}
                        setSkus={_set.relationships["set_skus"]}
                        productType={_set.relationships["product_type"]}
                        title={`${_set.attributes["name" + langContentModifier]}`}
                        content={`${_set.attributes["description" + langContentModifier]}`}
                        url={props.getLink('set-type/' + _set.attributes.slug)}
                        buttonText={t("general.packageDetails")}
                        stockLevel={_set.attributes['stock_level']}
                        endDate={_set.attributes['end_date_at']}
                        imageName={_set.attributes.image_name}
                      />)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br /><br />
      {/* <WhyMembership getLink={props.getLink} /> */}
      <Footer getLink={props.getLink} />
    </>
  );
}

export default Sets;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//images
import WhyMembership from "./sections/WhyMembership";
import { useTranslation } from "react-i18next";
import Modal from "../../../layouts/frontend/modals/Modal";
import Header from "../../../layouts/frontend/Header";
import Footer from "../../../layouts/frontend/Footer";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import PreviewProducts from "./sections/PreviewProducts";

const ProductHome = (props) => {
  const { t } = useTranslation();
  
  const [ sweetProducts, setSweetProducts ] = useState(null);
  const [ ginjoProducts, setGinjoProducts ] = useState(null);
  const [ daiginjoProducts, setDaiginjoProducts ] = useState(null);
  const [ namaProducts, setNamaProducts ] = useState(null);
  const [ lowPriceProducts, setLowPriceProducts ] = useState(null);
  const [ newProducts, setNewProducts ] = useState(null);
  const [ saleProducts, setSaleProducts ] = useState(null);
  
  useEffect(() => {
    // get sweet products
    props.getProducts([
      ['max_smv', 1],
      ['min_smv', -20],
      ['max_acidity', 1.4],
      ['min_acidity', -20],
      ['num_products', 8],
      ['active_only', 1],
      ['have_stock', 1]
    ])
      .catch((err) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
      })
      .then((response) => {
        setSweetProducts(response.data.data);
      });
    
    // get Ginjo 
    props.getProducts([
      ['arr_sake_types_id', '2,4'],
      ['num_products', 8],
      ['active_only', 1],
      ['have_stock', 1]
    ])
      .catch((err) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
      })
      .then((response) => {
        setGinjoProducts(response.data.data);
      });
    
    // get Daiginjo 
    props.getProducts([
      ['arr_sake_types_id', '3,5'],
      ['num_products', 8],
      ['active_only', 1],
      ['have_stock', 1]
    ])
      .catch((err) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
      })
      .then((response) => {
        setDaiginjoProducts(response.data.data);
      });

    // get Nama 
    props.getProducts([
      ['arr_search_name', 'nama,生原酒,生酒'],
      ['num_products', 8],
      ['active_only', 1],
      ['have_stock', 1]
    ])
      .catch((err) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
      })
      .then((response) => {
        setNamaProducts(response.data.data);
      });

    // get Low Price 
    props.getProducts([
      ['max_price', 200],
      ['min_price', 0],
      ['num_products', 8],
      ['active_only', 1],
      ['have_stock', 1]
    ])
      .catch((err) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
      })
      .then((response) => {
        setLowPriceProducts(response.data.data);
      });

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("navigation.home")} | JustSaké Hong Kong -{" "}
          {t("home.banner.part1").replace(/(<([^>]+)>)/gi, " ")}{" "}
          {t("home.banner.part2").replace(/(<([^>]+)>)/gi, " ")}
        </title>
        <meta
          name="description"
          content={t("home.sellingPoints.description")}
        />
      </Helmet>
      <Header getLink={props.getLink} />
      <div className="liquor-law no-top-margin">{t("general.liquorLaw")}</div>
      <section id="home-banner">
        <div
          id="heroCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
          style={{ height: "511px", backgroundColor: "#000" }}
        >
          {/* <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div> */}
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div>
                <div className="container px-4 py-5">
                  <div
                    className="row g-5 py-5 carousel-bg"
                    style={{ backgroundImage: "url(/assets/images/buy-more-and-save.gif)" }}
                  >
                    <div className="col-md-6 text-box">
                      <h1
                        className="text1"
                        dangerouslySetInnerHTML={{
                          __html: t("home.productCarousel.banner1a"),
                        }}
                      />
                      <h1
                        className="text2"
                        dangerouslySetInnerHTML={{
                          __html: t("home.productCarousel.banner1b"),
                        }}
                      />
                      <div className="cta-buttons">
                        <Link
                          to={props.getLink("products")}
                          className="btn btn-light btn-cta"
                          style={{ marginRight: "5px" }}
                        >
                          {t("ctaButtons.findProductsNow")}
                        </Link>
                        <br />
                        {/* <Link
                          to={props.getLink("buy-more-and-save")}
                          className="mini-link text-white"
                        >
                          <small>{t("navigation.buyMoreSaveMore")}</small>
                        </Link> */}
                      </div>
                    </div>
                    <div className="col-md-6 image-box" />
                    <div className="bg-overlay" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="carousel-item">
              <div>
                <div className="container px-4 py-5">
                  <div
                    className="row g-5 py-5 carousel-bg"
                    style={{ backgroundImage: "url(/assets/images/hero2.jpg)" }}
                  >
                    <div className="col-md-6 text-box">
                      <h1
                        className="LineHeight35 text1"
                        dangerouslySetInnerHTML={{
                          __html: t("home.carousel.banner2a"),
                        }}
                      />
                      <h1
                        className="text2-2"
                        dangerouslySetInnerHTML={{
                          __html: t("home.carousel.banner2b"),
                        }}
                      />
                      <div className="cta-buttons">
                        <Link
                          to={props.getLink("start-your-journey")}
                          className="btn btn-light btn-cta"
                          style={{ marginRight: "5px" }}
                        >
                          {t("ctaButtons.startJourney")}
                        </Link>
                        <br />
                        <Link
                          to={props.getLink("how-it-works")}
                          className="mini-link text-white"
                        >
                          <small>{t("general.howDoesMembershipWork")}</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 image-box" />
                    <div className="bg-overlay" />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div>
                <div className="container px-4 py-5">
                  <div
                    className="row g-5 py-5 carousel-bg"
                    style={{ backgroundImage: "url(/assets/images/hero3.jpg)" }}
                  >
                    <div className="col-md-6 text-box">
                      <h1
                        className="LineHeight35 text1"
                        dangerouslySetInnerHTML={{
                          __html: t("home.carousel.banner3a"),
                        }}
                      />
                      <h1
                        className="text2-2"
                        dangerouslySetInnerHTML={{
                          __html: t("home.carousel.banner3b"),
                        }}
                      />
                      <div className="cta-buttons">
                        <Link
                          to={props.getLink("start-your-journey")}
                          className="btn btn-light btn-cta"
                          style={{ marginRight: "5px" }}
                        >
                          {t("ctaButtons.startJourney")}
                        </Link>
                        <br />
                        <Link
                          to={props.getLink("how-it-works")}
                          className="mini-link text-white"
                        >
                          <small>{t("general.howDoesMembershipWork")}</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 image-box" />
                    <div className="bg-overlay" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#heroCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#heroCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button> */}
        </div>
      </section>

      <section className="sweet-products">
        <PreviewProducts 
          title={t("home.section.title1")}
          products={sweetProducts}
          updateCart={props.updateCart}
          getLink={props.getLink}
          moreLink={props.getLink('products?max_smv=1&min_smv=-20&max_acidity=1.4&min_acidity=-20')}
        />
      </section>

      <section className="sweet-products">
        <PreviewProducts 
          title={t("home.section.title8")}
          products={lowPriceProducts}
          updateCart={props.updateCart}
          getLink={props.getLink}
          moreLink={props.getLink('products?max_price=150&min_price=0')}
        />
      </section>

      <section className="nama-products">
        <PreviewProducts 
          title={t("home.section.title4")}
          products={namaProducts}
          updateCart={props.updateCart}
          getLink={props.getLink}
          moreLink={props.getLink('products?arr_search_name=nama,生原酒,生酒')}
        />
      </section>

      <section className="ginjo-products">
        <PreviewProducts 
          title={t("home.section.title2")}
          products={ginjoProducts}
          updateCart={props.updateCart}
          getLink={props.getLink}
          moreLink={props.getLink('products?arr_sake_types_id=2,4')}
        />
      </section>

      <section className="daiginjo-products">
        <PreviewProducts 
          title={t("home.section.title3")}
          products={daiginjoProducts}
          updateCart={props.updateCart}
          getLink={props.getLink}
          moreLink={props.getLink('products?arr_sake_types_id=3,5')}
        />
      </section>

      <div className="cta-buttons text-center m-5">
          <Link to={props.getLink("products")} className="btn btn-light btn-cta">{t("ctaButtons.findProductsNow")}</Link>
      </div>

      <WhyMembership
        getLink={props.getLink}
        buyLink={props.getLink("products")}
      />
      <Modal
        id="welcomeBoxMore"
        title={t("modals.welcomeBoxMore.title")}
        content={t("modals.welcomeBoxMore.content")}
      />
      <Modal
        id="newCuratedMore"
        title={t("modals.newCuratedMore.title")}
        content={t("modals.newCuratedMore.content")}
      />
      <Modal
        id="freeDeliveryMore"
        title={t("modals.freeDeliveryMore.title")}
        content={t("modals.freeDeliveryMore.content")}
      />
      <Modal
        id="shareMore"
        title={t("modals.shareMore.title")}
        content={t("modals.shareMore.content")}
      />
      <Modal
        id="giftMore"
        title={t("modals.giftMore.title")}
        content={t("modals.giftMore.content")}
      />
      <Modal
        id="cancellationMore"
        title={t("modals.cancellationMore.title")}
        content={t("general.subscriptionCancellationsDescription")}
      />
      <Footer getLink={props.getLink} />
    </>
  );
};

export default ProductHome;

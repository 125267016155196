import { CardElement } from "@stripe/react-stripe-js";
import React from "react";
import { useTranslation } from "react-i18next";
import SubscriptionCheckbox from "./SubscriptionCheckbox";

const CardElementForm = (props) => {
  const { t } = useTranslation();
  const handleSubmitHandler = (e, paymentMethods) => {
    e.preventDefault();

    props.handleSubmit(props.paymentMethods);
  };

  return (
    <>
      <form
        id="payment-form"
        onSubmit={(e) => handleSubmitHandler(e, props.paymentMethods)}
        style={{
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          boxShadow: "none",
          border: "1px solid rgba(0,0,0,.125)",
          borderTop: 0,
        }}
      >
        <input
          type="text"
          name="card-holder-name"
          id="card-holder-hame"
          placeholder={t("general.cardHolderName")}
          ref={props.cardHolderNameInputRef}
        />

        <CardElement id="card" className="form-control" locale="zh-HK" options={props.options} />
        <br />
        {props.dataSetSku &&
        props.dataSetSku.relationships.set_sku_type.type === "subscription" && (
          <div id="subscription-confirm">
            <SubscriptionCheckbox
              dataSetSku={props.dataSetSku}
              subscriptionCheckboxRef={props.subscriptionCheckboxRef}
            />
          </div>
        )}

        <button type="submit" className="btn-cta" disabled={props.isLoading}>
          <span id="button-text">
            {props.isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              t("general.payNow")
            )}
          </span>
        </button>
      </form>


    </>
  );
};

export default CardElementForm;

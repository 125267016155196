import React from "react";
import { useTranslation } from "react-i18next";

const DeliveryInfoSubscription = (props) => {
  const { t } = useTranslation();
  const date = new Date();
  const day = date.getDate();
  const deliveryDay = day >= 1 && day <= 15 ? 10 : 25;
  return (
    <React.Fragment>
      <h3 style={{ textAlign: "center" }}>{t("general.subscriptionDeliveries")}</h3>

      {props.dataSetSku.attributes.subscribed !== null && (
        <React.Fragment>
          <div className="card border-warning mb-3" style={{ backgroundColor: '#fff1c7' }}>
            <div className="card-header" style={{ backgroundColor: '#ffe596' }}>{t("general.alreadySubscribed")}</div>
            <div className="card-body">
              <h5 className="card-title">
                {t("general.loveSetText")}
              </h5>
              <p className="card-text">
                <strong>{t("general.sendingAGift")}</strong><br />
                {t("general.sendingAGiftInfo")}
              </p>
            </div>
          </div>
          <br />
        </React.Fragment>
      )}

      <div className="card border-info mb-3">
        <div className="card-header">{t("general.yourFirstBoxShipment")}</div>
        <div className="card-body">
          <h5 className="card-title" dangerouslySetInnerHTML={{ __html: t("general.singleDeliveriesSubTitle") }} />
          <p className="card-text">
            {t("general.singleDeliveriesDescription")}
          </p>
        </div>
      </div>
      <br />

      <div className="card border-warning mb-3">
        <div className="card-header">{t("general.yourMonthlyShipment")}</div>
        <div className="card-body">
          <h5 className="card-title" dangerouslySetInnerHTML={{ __html: t("general.yourMonthlyShipmentSubTitle", { deliveryDay: deliveryDay }) }} />
          <p className="card-text">
            {t("general.yourMonthlyShipmentDescription")}
          </p>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
};

export default DeliveryInfoSubscription;

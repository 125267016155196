import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//images
import WhyMembership from "./sections/WhyMembership";
import { useTranslation } from "react-i18next";
import Modal from "../../../layouts/frontend/modals/Modal";
import Header from "../../../layouts/frontend/Header";
import Footer from "../../../layouts/frontend/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGifts,
  faWineBottle,
  faTruckFast,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const SubscriptionHome = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("navigation.home")} | JustSaké Hong Kong -{" "}
          {t("home.banner.part1").replace(/(<([^>]+)>)/gi, " ")}{" "}
          {t("home.banner.part2").replace(/(<([^>]+)>)/gi, " ")}
        </title>
        <meta
          name="description"
          content={t("home.sellingPoints.description")}
        />
      </Helmet>
      <Header getLink={props.getLink} />
      <div className="liquor-law no-top-margin">{t("general.liquorLaw")}</div>
      <section id="home-banner">
        <div
          id="heroCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
          style={{ height: "511px", backgroundColor: "#000" }}
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div>
                <div className="container px-4 py-5">
                  <div className="row g-5 py-5 carousel-bg" style={{ backgroundImage: "url(/assets/images/hero1.jpg)" }}>
                    <div className="col-md-6 text-box">
                      <h1 className="text1" dangerouslySetInnerHTML={{ __html: t('home.carousel.banner1a') }} />
                      <h1 className="text2" dangerouslySetInnerHTML={{ __html: t('home.carousel.banner1b') }} />
                      <div className="cta-buttons">
                        <Link
                          to={props.getLink("start-your-journey")}
                          className="btn btn-light btn-cta"
                          style={{ marginRight: "5px" }}
                        >
                          {t("ctaButtons.startJourney")}
                        </Link>
                        <br />
                        <Link
                          to={props.getLink("how-it-works")}
                          className="mini-link text-white"
                        >
                          <small>{t("general.howDoesMembershipWork")}</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 image-box" />
                    <div className="bg-overlay" />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div>
                <div className="container px-4 py-5">
                  <div className="row g-5 py-5 carousel-bg" style={{ backgroundImage: "url(/assets/images/hero2.jpg)" }}>
                    <div className="col-md-6 text-box">
                      <h1 className="LineHeight35 text1" dangerouslySetInnerHTML={{ __html: t('home.carousel.banner2a') }} />
                      <h1 className="text2-2" dangerouslySetInnerHTML={{ __html: t('home.carousel.banner2b') }} />
                      <div className="cta-buttons">
                        <Link
                          to={props.getLink("start-your-journey")}
                          className="btn btn-light btn-cta"
                          style={{ marginRight: "5px" }}
                        >
                          {t("ctaButtons.startJourney")}
                        </Link>
                        <br />
                        <Link
                          to={props.getLink("how-it-works")}
                          className="mini-link text-white"
                        >
                          <small>{t("general.howDoesMembershipWork")}</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 image-box" />
                    <div className="bg-overlay" />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div>
                <div className="container px-4 py-5">
                  <div className="row g-5 py-5 carousel-bg" style={{ backgroundImage: "url(/assets/images/hero3.jpg)" }}>
                    <div className="col-md-6 text-box">
                      <h1 className="LineHeight35 text1" dangerouslySetInnerHTML={{ __html: t('home.carousel.banner3a') }} />
                      <h1 className="text2-2" dangerouslySetInnerHTML={{ __html: t('home.carousel.banner3b') }} />
                      <div className="cta-buttons">
                        <Link
                          to={props.getLink("start-your-journey")}
                          className="btn btn-light btn-cta"
                          style={{ marginRight: "5px" }}
                        >
                          {t("ctaButtons.startJourney")}
                        </Link>
                        <br />
                        <Link
                          to={props.getLink("how-it-works")}
                          className="mini-link text-white"
                        >
                          <small>{t("general.howDoesMembershipWork")}</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 image-box" />
                    <div className="bg-overlay" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#heroCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#heroCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      <section id="selling-points">
        <div className="section-content">
          <h2
            className="display-6 force-uppercase"
            dangerouslySetInnerHTML={{ __html: t("home.sellingPoints.title") }}
          ></h2>
          <div className="header-description">
            {t("home.sellingPoints.description")}
          </div>

          <div className="points container-fluid my-4">
            <div className="row">
              <div className="point col-md-3">
                {/* <img className="mb-3" src={imgPointGift} alt="" /> */}
                <FontAwesomeIcon
                  icon={faGifts}
                  style={{ height: "70px", marginBottom: "15px" }}
                />
                <h3
                  className="display-8 force-uppercase"
                  dangerouslySetInnerHTML={{
                    __html: t("home.sellingPoints.point1Title"),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("home.sellingPoints.point1Info"),
                  }}
                  className="d-none d-lg-block"
                />
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#welcomeBoxMore"
                  className="text-link"
                >
                  <small>{t("general.conditionsApply")}</small>
                </Link>
              </div>
              <div className="point col-md-3">
                {/* <img className="mb-3" src={imgPointSakeCups} alt="" /> */}
                <FontAwesomeIcon
                  icon={faWineBottle}
                  style={{ height: "70px", marginBottom: "15px" }}
                />
                <h3
                  className="display-8 force-uppercase"
                  dangerouslySetInnerHTML={{
                    __html: t("home.sellingPoints.point2Title"),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("home.sellingPoints.point2Info"),
                  }}
                  className="d-none d-lg-block"
                />
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#newCuratedMore"
                  className="text-link"
                >
                  <small>{t("general.moreInfo")}</small>
                </Link>
              </div>
              <div className="point col-md-3">
                {/* <img className="mb-3" src={imgPointDelivery} alt="" /> */}
                <FontAwesomeIcon
                  icon={faTruckFast}
                  style={{ height: "70px", marginBottom: "15px" }}
                />
                <h3
                  className="display-8 force-uppercase"
                  dangerouslySetInnerHTML={{
                    __html: t("home.sellingPoints.point3Title"),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("home.sellingPoints.point3Info"),
                  }}
                  className="d-none d-lg-block"
                />

                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#freeDeliveryMore"
                  className="text-link"
                >
                  <small>{t("general.moreInfo")}</small>
                </Link>
              </div>
              <div className="point col-md-3">
                {/* <img className="mb-3" src={imgPointCancel} alt="" /> */}
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ height: "70px", marginBottom: "15px" }}
                />
                <h3
                  className="display-8 force-uppercase"
                  dangerouslySetInnerHTML={{
                    __html: t("home.sellingPoints.point4Title"),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("home.sellingPoints.point4Info"),
                  }}
                  className="d-none d-lg-block"
                />

                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#cancellationMore"
                  className="text-link"
                >
                  <small>{t("general.moreInfo")}</small>
                </Link>
              </div>
            </div>
          </div>

          <div className="cta-buttons">
            <Link
              to={props.getLink("start-your-journey")}
              className="btn btn-light btn-cta"
            >
              {t("ctaButtons.startJourney")}
            </Link>
          </div>
        </div>
      </section>
      <section id="membership-push">
        <div className="jumbotron jumbotron-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col jumbo-left"></div>
              <div className="col jumbo-right">
                <div className="banner-bg">
                  <div className="banner-content">
                    <h2
                      className="display-6 force-uppercase"
                      dangerouslySetInnerHTML={{
                        __html: t("home.membershipPush.title"),
                      }}
                    />

                    <p
                      className="mb-4"
                      dangerouslySetInnerHTML={{
                        __html: t("home.membershipPush.description"),
                      }}
                    />

                    <div className="cta-buttons">
                      <Link
                        to={props.getLink("start-your-journey")}
                        className="btn btn-light btn-cta"
                        style={{ marginRight: "5px" }}
                      >
                        {t("ctaButtons.becomeMember")}
                      </Link>
                      <div className="mt-1">
                        <Link
                          to={props.getLink("how-it-works")}
                          className="mini-link text-link"
                        >
                          <small>{t("general.howDoesMembershipWork")}</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="membership-gift" className="mt-5">
        <div className="jumbotron jumbotron-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col jumbo-left">
                <div className="banner-bg">
                  <div className="banner-content">
                    <h2
                      className="display-6 force-uppercase"
                      dangerouslySetInnerHTML={{
                        __html: t("home.membershipGift.title"),
                      }}
                    />
                    <div
                      className="header-description mt-3 mb-4"
                      dangerouslySetInnerHTML={{
                        __html: t("home.membershipGift.subTitle"),
                      }}
                    />

                    <p
                      className="mb-4"
                      dangerouslySetInnerHTML={{
                        __html: t("home.membershipGift.description"),
                      }}
                    />

                    <div className="cta-buttons">
                      <Link
                        to={props.getLink("start-your-journey")}
                        className="btn btn-light btn-cta"
                        style={{ marginRight: "5px" }}
                      >
                        {t("ctaButtons.buyGift")}
                      </Link>
                      <div className="mt-1">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#giftMore"
                          className="mini-link text-link"
                        >
                          <small>{t("general.howDoISendAGift")}</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col jumbo-right"></div>
            </div>
          </div>
        </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Modal
        id="welcomeBoxMore"
        title={t("modals.welcomeBoxMore.title")}
        content={t("modals.welcomeBoxMore.content")}
      />
      <Modal
        id="newCuratedMore"
        title={t("modals.newCuratedMore.title")}
        content={t("modals.newCuratedMore.content")}
      />
      <Modal
        id="freeDeliveryMore"
        title={t("modals.freeDeliveryMore.title")}
        content={t("modals.freeDeliveryMore.content")}
      />
      <Modal
        id="shareMore"
        title={t("modals.shareMore.title")}
        content={t("modals.shareMore.content")}
      />
      <Modal
        id="giftMore"
        title={t("modals.giftMore.title")}
        content={t("modals.giftMore.content")}
      />
      <Modal
        id="cancellationMore"
        title={t("modals.cancellationMore.title")}
        content={t("general.subscriptionCancellationsDescription")}
      />
      <Footer getLink={props.getLink} />
    </>
  );
};

export default SubscriptionHome;

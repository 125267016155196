import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../../../../layouts/frontend/Footer";
import Header from "../../../../layouts/frontend/Header";
import Breadcrumb from "../../ui/Breadcrumb";

const BoxSelection = (props) => {
  const { t } = useTranslation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("startYourJourney.title")} | {t('boxSelection.title')} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          { text: t("startYourJourney.title"), link: "" },
        ]}
      />

      <div className="liquor-law">{t('general.liquorLaw')}</div>
      
      <section className="breadcrumb-spacing" id="box-selection">
        <div className="section-content pb-0">
          <h1
            className="display-6"
            dangerouslySetInnerHTML={{ __html: t("boxSelection.title") }}
          />

          <div className="container py-5 text-start">

            <div className="mb-5">
              <strong className="fs-5 ps-0">{t("boxSelection.subTitle")}</strong><br />
              <div className="d-none d-lg-block">{t("boxSelection.description")}</div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <h2>{t("boxSelection.subTitleIntro")}</h2>
                <small dangerouslySetInnerHTML={{ __html: t("boxSelection.subSummaryIntro") }} /><br />
                <div className="d-grid gap-2 my-3">
                  <Link to={props.getLink("start-your-journey/introductory-sets")} className="btn btn-md btn-outline-success glow-on-hover">{t("boxSelection.subBtnIntro")}</Link>
                </div>
              </div>
              <div className="col-md-8 d-none d-lg-block" dangerouslySetInnerHTML={{ __html: t("boxSelection.subDescriptionIntro") }} />
            </div>
            <br />

            <div className="row">
              <div className="col-md-4">
                <h2>{t("boxSelection.subTitleAvantGarde")}</h2>
                <small dangerouslySetInnerHTML={{ __html: t("boxSelection.subSummaryAvantGarde") }} /><br />
                <div className="d-grid gap-2 my-3">
                  <Link to={props.getLink("start-your-journey/special-sets")} className="btn btn-md btn-outline-success glow-on-hover">{t("boxSelection.subBtnAvantGarde")}</Link>
                </div>
              </div>
              <div className="col-md-8 d-none d-lg-block" dangerouslySetInnerHTML={{ __html: t("boxSelection.subDescriptionAvantGarde") }} />
            </div>
            <br />

            {/* <div className="row">
              <div className="col-md-4">
                <h2>{t("boxSelection.subTitleEverything")}</h2>
                <small dangerouslySetInnerHTML={{ __html: t("boxSelection.subSummaryEverything") }} /><br />
                <div className="d-grid gap-2 my-3">
                  <Link to={props.getLink("start-your-journey/all")} className="btn btn-md btn-outline-success">{t("boxSelection.subBtnEverything")}</Link>
                </div>
              </div>
              <div className="col-md-8" dangerouslySetInnerHTML={{ __html: t("boxSelection.subDescriptionEverything") }} />
            </div> */}

          </div>
        </div>
        {/* <WhyMembership getLink={props.getLink} /> */}
        <Footer getLink={props.getLink} />
      </section>
    </>
  );
};

export default BoxSelection;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Product from "../../page/products/partials/Product";
import "./SetCard.min.css";
import SetInfoCard from "./SetInfoCard";
import SetPriceCard from "./SetPriceCard";
import CartContext from "../../../../contexts/cart-context";
import { Link } from "react-router-dom";
import ProductsList from "../../page/products/partials/ProductsList";

const SetCard = (props) => {
  const { t, i18n } = useTranslation();
  const langContentModifier = i18n.language === "en" ? "" : "_zh";
  const cartCtx = useContext(CartContext);

  return (
    <div className="container-fluid card-container">
      <div className="row">
        <div className="col-lg-8">
          <SetInfoCard
            title={props.title}
            content={props.content}
            buttonText={props.buttonText}
            stockLevel={props.stockLevel}
            endDate={props.endDate}
            imageName={props.imageName}
            setLink={props.getLink(
              "start-your-journey/" +
                props.setType.attributes.slug +
                "/" +
                props.set.attributes.slug
            )}
          />

          <h3>{t("general.setProducts")}</h3>
          {/* {props.setParam !== undefined && <div className="d-flex flex-wrap" style={{ width: "100%" }}>
            <table className="table">
              <tbody>
                {props.set.relationships.set_product_skus && props.set.relationships.set_product_skus.map((setProductSku, idx) => <Product updateCart={cartCtx.updateCart} product={setProductSku.relationships.product} display="table-row" key={idx} />)}
              </tbody>
            </table>
          </div>}
          {props.setParam === undefined && <>
            <Link to={props.getLink('start-your-journey/' + props.setType.attributes.slug + '/' + props.set.attributes.slug)} className="btn btn-sm btn-success">{t("general.packageDetails")}</Link>
            <br /><br />
          </>} */}
          <div className="d-flex flex-wrap" style={{ width: "100%" }}>
            <ProductsList
              cartCtx={cartCtx}
              setProductSkus={props.set.relationships.set_product_skus}
              setParam={props.setParam}
              getLink={props.getLink}
            />
          </div>

          {props.setParam === undefined && (
            <>
              <Link
                to={props.getLink(
                  "start-your-journey/" +
                    props.setType.attributes.slug +
                    "/" +
                    props.set.attributes.slug
                )}
                className="btn btn-sm btn-success"
              >
                {t("general.packageDetails")}
              </Link>
              <br />
              <br />
            </>
          )}
        </div>

        <div className="col-lg-4">
          <SetPriceCard
            langContentModifier={langContentModifier}
            set={props.set}
            setType={props.setType}
            productType={props.productType}
            setSkus={props.setSkus}
            getLink={props.getLink}
          />
          <br />
        </div>
      </div>
    </div>
  );
};

export default SetCard;

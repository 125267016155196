import axios from "axios";
import React, { useEffect, useState, useCallback, useContext } from "react";
import UserContext from "./user-context";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ValidationErrors from "../utility/ValidationErrors";
import ReactGA from "react-ga4";
import ReactFBPixel from "react-facebook-pixel";

axios.defaults.withCredentials = true;

const UserProvider = (props) => {
  const { t, i18n } = useTranslation();
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    password: null,
    password_confirmation: null,
    phone: null
  });
  const [user, setUser] = useState({});
  const [userToken, setUserToken] = useState(null);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(null);
  const [redirect, setRedirect] = useState("");
  const [cart, setCart] = useState(null);

  const redirectHandler = (redirect) => {
    setRedirect(redirect);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (redirect.trim() !== "") {
      const r = redirect;
      setRedirect("");
      // navigate(r, { replace: true });
      navigate(r);
    }
  }, [redirect, navigate]);

  const addUser = async (name, email, password, password_confirm, phone, subscribe) => {
    await toast.promise(
      axios
        .post(process.env.REACT_APP_API_URL + "register", {
          name: name,
          email: email,
          password: password,
          password_confirmation: password_confirm,
          phone: phone,
          preferred_language: i18n.language,
          subscribe: subscribe
        })
        .then((response) => {
          setUser(response.data.user);
          setUserToken(response.data.token);
          localStorage.setItem("userJSON", JSON.stringify(response.data.user));
          localStorage.setItem("userToken", response.data.token);

          if (!window.location.href.includes("localhost")) {
            // send ga
            ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
            ReactGA.event('sign_up', {
              'event_category': 'engagement',
              'event_label': 'method'
            });

            // fb pixel
            const advancedMatching = {};
            const options = {
              autoConfig: true,
              debug: false
            };
            ReactFBPixel.init('6703591589656745', advancedMatching, options);
            ReactFBPixel.track('CompleteRegistration', { 
              content_name: 'Signup',
              status: 'complete'
            });
          }
        }),
      {
        loading: t("signup.signupLoading"),
        success: () => {
          setErrors({});
          setUserIsLoggedIn(true);

          const historyUrl = localStorage.getItem("protectedHistory") ?? '';
          localStorage.setItem("protectedHistory", "");
          redirectHandler(historyUrl);

          return <b>{t("signup.signupSuccess")}</b>;
        },
        error: (error) => {
          setErrors(error.response.data.errors);
          return error.response.data.message;
        },
      }
    );
  };

  const updateUser = async (name, phone) => {
    await toast.promise(
      axios
        .post(process.env.REACT_APP_API_URL + "user", {
          name: name,
          phone: phone,
        }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          setUser(response.data.user);
          localStorage.setItem("userJSON", JSON.stringify(response.data.user));
        }),
      {
        loading: t("profile.updateLoading"),
        success: () => {
          setErrors({});
          return <b>{t("profile.updateSuccess")}</b>;
        },
        error: (error) => {
          ValidationErrors(error.response.data.errors, ['name', 'phone'], t);
          setErrors(error.response.data.errors);
          return error.response.data.message;
        },
      }
    );
  };

  const updateUserPassword = async (old_password, password, password_confirmation) => {
    await toast.promise(
      axios
        .post(process.env.REACT_APP_API_URL + "user/password", {
          old_password: old_password,
          password: password,
          password_confirmation: password_confirmation
        }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          setUser(response.data.user);
          localStorage.setItem("userJSON", JSON.stringify(response.data.user));
        }),
      {
        loading: t("profile.updatePasswordLoading"),
        success: () => {
          setErrors({});
          return <b>{t("profile.updatePasswordSuccess")}</b>;
        },
        error: (error) => {
          ValidationErrors(error.response.data.errors, ['old_password', 'password'], t);
          setErrors(error.response.data.errors);
          return error.response.data.error;
        },
      }
    );
  };

  const loginUser = async (email, password) => {
    const cartJSON = localStorage.getItem('cartJSON');
    const cart = JSON.parse(cartJSON);
    let cartItems = null;
    if (!cart || cart.items === undefined || cart.items.length === 0) {
      cartItems = [];
    } else {
      cartItems = cart.items;
    }

    await toast.promise(
      axios
        .post(process.env.REACT_APP_API_URL + "login", {
          email: email,
          password: password,
          cart_items: cartItems
        })
        .then((response) => {
          setUser(response.data.user);
          setUserToken(response.data.token);
          localStorage.setItem("userJSON", JSON.stringify(response.data.user));
          localStorage.setItem("userToken", response.data.token);

          // send ga
          ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
          ReactGA.event('login', {
            'event_category': 'engagement',
            'event_label': 'method'
          });
        }),
      {
        loading: t("login.loginLoading"),
        success: () => {
          setErrors({});
          setUserIsLoggedIn(true);

          const historyUrl = localStorage.getItem("protectedHistory") ?? '';
          localStorage.setItem("protectedHistory", "");
          redirectHandler(historyUrl);

          return <b>{t("login.loginSuccess")}</b>;
        },
        error: (error) => {
          setErrors(error.response.data.errors);
          return error.response.data.message;
        },
      }
    );
  };

  const logoutUser = async () => {
    await toast.promise(
      axios
        .post(process.env.REACT_APP_API_URL + "logout", null, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(() => {
          setUser({});
          setUserToken("");
          localStorage.removeItem("userJSON");
          localStorage.removeItem("userToken");
        }),
      {
        loading: t("login.loggingOut"),
        success: () => {
          setErrors({});
          setUserIsLoggedIn(false);
          localStorage.setItem("protectedHistory", "");
          redirectHandler("/");
          return <b>{t("login.loggingOutSuccess")}</b>;
        },
        error: (error) => {
          setErrors(error.response.data.errors);
          return error.response.data.message;
        },
      }
    );
  };

  const authCheck = useCallback(async (token) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUser(response.data.user);
        localStorage.setItem("userJSON", JSON.stringify(response.data.user));
        setUserIsLoggedIn(true);

      })
      .catch((error) => {
        setUser({});
        setUserToken("");
        localStorage.removeItem("userJSON");
        localStorage.removeItem("userToken");
        setUserIsLoggedIn(false);
      });
  }, []);

  useEffect(() => {
    const storedUserToken = localStorage.getItem("userToken");

    if (storedUserToken) {
      setUserToken(storedUserToken);
    }

    authCheck(storedUserToken);
  }, [authCheck]);

  return (
    <UserContext.Provider
      value={{
        errors: errors,
        user: user,
        userToken: userToken,
        userIsLoggedIn: userIsLoggedIn,
        cart: cart,
        addUser: addUser,
        loginUser: loginUser,
        logoutUser: logoutUser,
        updateUser: updateUser,
        updateUserPassword: updateUserPassword,
        redirectHandler: redirectHandler
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;

import React from "react";
import { useTranslation } from "react-i18next";

const CouponForm = (props) => {
  const { t } = useTranslation();
  return (
    <div className="card">
      <div className="card-header">{t("general.useCoupon")}</div>
      <div className="card-body">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={t("general.couponCode")}
            ref={props.couponCodeRef}
          />
          <button
            className="btn btn-success"
            type="button"
            id="btn-coupon"
            onClick={props.couponSubmit}
          >
            {t("general.applyCode")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CouponForm;
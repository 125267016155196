import React from "react";
import CartItem from "./CartItem";

const CartItems = (props) => {
  return (
    <>
      <table className="table">
        <tbody>
          {props.totalBottlesToPrice > 0 && props.cartItems.map((item, cKey) => (
            <CartItem
              totalBottlesToPrice={props.totalBottlesToPrice}
              getLink={props.getLink}
              showCartHandler={props.showCartHandler}
              from={props.from}
              cartItem={item}
              updateCartHandler={props.updateCartHandler}
              key={cKey}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CartItems;

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../layouts/frontend/modals/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';

const WhyMembership = (props) => {
  const { t } = useTranslation();
  const linkUrl = props.buyLink !== undefined ? props.buyLink : props.getLink("start-your-journey");
  return (
    <section id="membership-why" className="mt-5 mb-5">
        <div className="section-content mb-5">
            <h2 className="display-6 force-uppercase" dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.title") }} />
            <div className="header-description" dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.description") }} />
        
            <div className="points container-fluid mt-5 mb-4">
                <div className="row">
                    <div className="point col-md-3">
                        <h3 className="display-8 force-uppercase" dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.point1Title") }} />
                        <p dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.point1Info") }} />
                    </div>
                    <div className="point col-md-3">
                    <h3 className="display-8 force-uppercase" dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.point2Title") }} />
                        <p dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.point2Info") }} />
                    </div>
                    <div className="point col-md-3">
                        <h3 className="display-8 force-uppercase" dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.point3Title") }} />
                        <p dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.point3Info") }} />
                    </div>
                    <div className="point col-md-3">
                        <h3 className="display-8 force-uppercase" dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.point4Title") }} />
                        <p dangerouslySetInnerHTML={{ __html: t("home.membershipWhy.point4Info") }} />
                        <Link to="#" data-bs-toggle="modal" data-bs-target="#priceMatchMore" className="mini-link text-link"><small>{t("home.membershipWhy.point4LinkText")}</small></Link>
                    </div>
                </div>
            </div>
        
            <div className="cta-buttons">
                <Link to={linkUrl} className="btn btn-light btn-cta">{t("ctaButtons.discoverYourSakeNow")}</Link>
            </div>
        </div>
        <Modal id="priceMatchMore" title={t("modals.priceMatchMore.title")} content={t("modals.priceMatchMore.content")} />
        <Modal id="priceMatchDetailsMore" title={t("modals.priceMatchDetailsMore.title")} content={t("modals.priceMatchDetailsMore.content")} />
    </section>
  );
}

export default WhyMembership;
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <nav className="px-4 pt-2 pb-2" id="breadcrumb-nav" aria-label="breadcrumb">
      <ol className="breadcrumb">
        {props.crumbs.map((crumb, idx) => {
          if (crumb.link !== "") {
            return (
              <li className="breadcrumb-item" key={idx}>
                <Link to={crumb.link}>{crumb.text}</Link>
              </li>
            );
          } else {
            if (typeof crumb.text === 'string') {
              return (
                <li
                  className="breadcrumb-item active"
                  dangerouslySetInnerHTML={{ __html: crumb.text }}
                  key={idx}
                />
              );
            } else {
              return <li className="breadcrumb-item active" key={idx}>{crumb.text}</li>
            }
          }
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../../layouts/frontend/Footer';
import Header from '../../../../layouts/frontend/Header';
import Breadcrumb from '../../ui/Breadcrumb';
import WhyMembership from '../sections/WhyMembership';
import UserContext from '../../../../contexts/user-context';
import Loader from '../../ui/Loader';
import axios from 'axios';
import Swal from 'sweetalert2';
import Subscription from './partials/Subscription';
import { Link } from 'react-router-dom';
import Order from './partials/Order';

import '../../ui/cards/SetInfoCard.min.css';
import Helmet from 'react-helmet';

axios.defaults.withCredentials = true;

const Orders = (props) => {
  const { t } = useTranslation();
  const userCtx = useContext(UserContext);
  const [ subscriptions, setSubscriptions ] = useState(null);
  const [ orders, setOrders] = useState(null);
  const storedUserToken = localStorage.getItem('userToken');

  const getSubscriptions = async () => {
    await axios.get(process.env.REACT_APP_API_URL + 'user/subscriptions', {
      headers: {
        "Authorization": `Bearer ${storedUserToken}`
      }
    })
    .catch((err) => {
      Swal.fire(t("errorMessages.swalDefaultTitle"), err.response.data.message, "error");
    })
    .then((response) => {
      if (typeof response != "undefined") {
        setSubscriptions(response.data.data);      
      } else {
        Swal.fire(t("errorMessages.swalDefaultTitle"), t("errorMessages.noData"), "error");
      }
    });
  };

  const getOrders = async () => {
    await axios.get(process.env.REACT_APP_API_URL + 'user/order', {
      headers: {
        "Authorization": `Bearer ${storedUserToken}`
      }
    })
    .catch((err) => {
      Swal.fire(t("errorMessages.swalDefaultTitle"), err.response.data.message, "error");
    })
    .then((response) => {
      if (typeof response != "undefined") {
        setOrders(response.data.data);      
      } else {
        Swal.fire(t("errorMessages.swalDefaultTitle"), t("errorMessages.noData"), "error");
      }
    });
  };

  useEffect(() => {
    getSubscriptions();
    getOrders();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("navigation.orderedSets")} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb crumbs={[
          {text: t("navigation.home"), link: props.getLink("/")},
          {text: t("navigation.orderedSets"), link: ''}
      ]} />

      <div className="liquor-law">{t('general.liquorLaw')}</div>
      
      {(userCtx.userIsLoggedIn === null || userCtx.userIsLoggedIn === false) && 
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      }

      {userCtx.userIsLoggedIn === true && 
        <section className="breadcrumb-spacing" id="user-account">
          <div className="section-content" style={{ paddingBottom: "100px" }}>
            <h1
              className="display-7 pb-2"
              dangerouslySetInnerHTML={{ __html: t("navigation.orderedSets") }}
            />

            <div
              className="header-description"
              dangerouslySetInnerHTML={{ __html: t("orderedSets.description") }}
            />

            <div className="container my-5">
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="pb-3">{t("orderedSets.yourSubscriptions")}</h3>
                  {!subscriptions && <Loader />}

                  {subscriptions && subscriptions.length === 0 && 
                    <div>
                      {t("orderedSets.noSubscriptionsText")}<br /><br />
                      <Link to={props.getLink('start-your-journey')} className="btn btn-success glow-on-hover">{t("orderedSets.noSubscriptionsButton")}</Link>
                    </div>
                  }

                  {subscriptions && subscriptions.map((subscription, subIdx) => 
                    <Subscription subscription={subscription} key={subIdx} />
                  )}
                </div>
                <div className="col-lg-6">
                  <h3 className="pb-3">{t("orderedSets.yourSingleBoxItems")}</h3>

                  {!orders && <Loader />}

                  {orders && orders.length === 0 && 
                    <div>
                      {t("orderedSets.noOrdersText")}<br /><br />
                      <Link to={props.getLink('start-your-journey')} className="btn btn-success glow-on-hover">{t("orderedSets.noOrdersButton")}</Link>
                    </div>
                  }

                  {orders && orders.map((order, subIdx) => 
                    <Order order={order} key={subIdx} />
                  )}

                </div>
              </div>
            </div>
          </div>
        </section>
      }

      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
}

export default Orders;
import React from "react";
import "./LoaderText.min.css";

const LoaderText = () => {
  return (
      <React.Fragment>
        <span>...</span>
      </React.Fragment>
  );
};

export default LoaderText;

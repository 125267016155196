import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import btnStyles from './LangSelector.module.css';
import UserContext from '../../../contexts/user-context';
import axios from 'axios';
import ValidationErrors from '../../../utility/ValidationErrors';

axios.defaults.withCredentials = true;

const LangSelector = () => {
  const{ t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');
  const { locale } = useParams();
  const storedUserToken = localStorage.getItem("userToken");
  const userCtx = useContext(UserContext);
  
  useEffect(() => {
    setSelectedLang(locale);
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  const changeLanguage = () => {    
    const lang = (selectedLang === 'en') ? 'zh-HK' : 'en';
    setSelectedLang(lang);
    i18n.changeLanguage(lang);

    let url = window.location.href;
    url = (lang === 'en') ? url.replace('zh-HK', lang) : url.replace('en', lang);
    window.history.replaceState(null, '', url);

    if (userCtx.userIsLoggedIn === true) {
      //update lang
      const apiUrl = process.env.REACT_APP_API_URL + "user/change_language";
      axios.post(apiUrl, {
        'preferred_language': i18n.language
      }, {
        headers: { Authorization: `Bearer ${storedUserToken}` }
      })
      .then(response => {
        //console.log(response);
      })
      .catch(error => {
        ValidationErrors(error.response.data.errors, ['preferred_language'], t);
      });
    }
  }

  return (
    <React.Fragment>
      <button className={`${btnStyles.navLink} nav-link`} onClick={changeLanguage}>{t('navigation.langSwitch')}</button>
    </React.Fragment>
  );
}

export default LangSelector;
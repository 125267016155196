import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import UserContext from "./contexts/user-context";
import Protected from "./components/navigation/Protected";
import useGaTracker from "./useGaTracker";
import ReactDOM from 'react-dom';

// css
import './assets/frontend/css/main.min.css';

// Pages
// const SetTypes = lazy(() => import("./components/frontend/page/SetTypes"));
import SubscriptionHome from "./components/frontend/page/SubscriptionHome";
import About from "./components/frontend/page/About";
import HowItWorks from "./components/frontend/page/HowItWorks";
import Login from "./components/frontend/page/user/Login";
import Signup from "./components/frontend/page/user/Signup";
import BoxSelection from "./components/frontend/page/startYourJourney/BoxSelection";
import Sets from "./components/frontend/page/startYourJourney/Sets";
import SubscriptionCardForm from "./components/frontend/page/checkout/SubscriptionCardForm";
import OrderComplete from "./components/frontend/page/user/OrderComplete";
import Profile from "./components/frontend/page/user/Profile";
import Orders from "./components/frontend/page/user/Orders";
import Deliveries from "./components/frontend/page/user/Deliveries";
import ForgotPassword from "./components/frontend/page/user/ForgotPassword";
import ResetPassword from "./components/frontend/page/user/ResetPassword";
import TermsAndConditions from "./components/frontend/page/TermsAndConditions";
import PrivacyPolicy from "./components/frontend/page/PrivacyPolicy";
import WhatsInABoxSet from "./components/frontend/page/WhatsInABoxSet";
import Swal from "sweetalert2";
import AllProducts from "./components/frontend/page/products/AllProducts";
import CartModal from "./components/frontend/page/cart/CartModal";
import CartContext from "./contexts/cart-context";
import SingleProduct from "./components/frontend/page/product/SingleProduct";
import ProductHome from "./components/frontend/page/ProductHome";
import axios from "axios";

function App() {
  const { i18n } = useTranslation();
  const userCtx = useContext(UserContext);
  const cartCtx = useContext(CartContext);
  const storedUserToken = localStorage.getItem("userToken");
  const [ allProducts, setAllProducts ] = useState(null);

  // check age modal
  const checkAge = () => {
    const age18AndOver = localStorage.getItem("age18AndOver");

    if (age18AndOver === "true") {
      return;
    } else {
      Swal.fire({
        title: 'Are you 18 years of age or older? 你是否年滿18對歲？',
        width: 600,
        padding: '3em',
        color: '#000',
        imageUrl: "/assets/images/logo.png",
        imageWidth: 200,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes, i'm 18 and over 以滿18歲",
        confirmButtonColor: "#000",
        showDenyButton: true,
        denyButtonText: "No, i'm under 18 未滿18歲",
        background: 'rgba(255,255,255,1)',
        backdrop: 'rgba(0,0,0,1)'
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem('age18AndOver', true);
        }
        
        if (result.isDenied) {
          window.location.href = 'https://en.wikipedia.org/wiki/Alcohol_laws_of_Hong_Kong';
        }
      });
    }
  }
  
  //GA
  useGaTracker();

  //methods
  const getLink = (path) => {
    const usePath = (path === '/') ? '' : path;
    return '/' + i18n.language + '/' + usePath;
  }

  const loadJS = (url, implementationCode, location) => {
    //url is URL of external file, implementationCode is the code
    //to be called from the file, location is the location to 
    //insert the <script> element

    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    location.appendChild(scriptTag);
  };

  const implementationCodeOnLoad = () => {
    //your code goes here
  }

  const getProducts = async (options) => {
    const optionsStr = options !== undefined ? options.reduce((prev, cur) => (prev === '' ? '' : prev + '&') + cur[0] + '=' + cur[1], '') : '';
    
    let url = process.env.REACT_APP_API_URL + "products?" + optionsStr;

    const products = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${storedUserToken}`,
      },
    });

    return products;
  };

  useEffect(() => {
    checkAge();

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      loadJS('//code.tidio.co/cdeoygwoppk4jvon7o0ienir0hx0vqnu.js', implementationCodeOnLoad, document.body);
    }

    getProducts()
      .then((response) => setAllProducts(response.data.data));
  }, []);

  useEffect(() => {
    //console.log(i18n.language + " | " + userCtx?.user?.attributes?.preferred_language);
    if (userCtx?.user?.attributes?.preferred_language !== undefined && 
        i18n.language !== userCtx.user.attributes.preferred_language
    ) {
      i18n.changeLanguage(userCtx.user.attributes.preferred_language);
    }

    if (userCtx?.user?.attributes !== undefined) {
      window?.tidioChatApi?.setVisitorData({
        email: userCtx.user.attributes.email, // visitor email
        name: userCtx.user.attributes.name, // Visitor name
        phone: userCtx.user.attributes.phone //Visitor phone
      });
    }
  }, [userCtx.user, i18n]);

  // TODO - Need to include proper key -> id for array items to optimize react 
  return (
    <>
      {cartCtx.showCart && ReactDOM.createPortal(<CartModal getLink={getLink} />, document.getElementById('overlays'))}
      <Routes>
        {["/", "/:locale"].map((path, index) => (
          <Route path={path} key={index}>
            <Route index element={<ProductHome getProducts={getProducts} getLink={getLink} updateCart={cartCtx.updateCart} />} key={index} />
            <Route path="subscriptions/home" element={<SubscriptionHome getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="about" element={<About getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="how-it-works" element={<HowItWorks getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="what-is-in-a-box-set" element={<WhatsInABoxSet getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="terms-and-conditions" element={<TermsAndConditions getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="privacy" element={<PrivacyPolicy getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="start-your-journey" element={<BoxSelection getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="start-your-journey/:setType" element={<Sets getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="start-your-journey/:setType/:set" element={<Sets getLink={getLink} />} key={`${index}-${path}`} />
            {/* <Route path="set-types" element={<SetTypes getLink={getLink} />} key={`${index}-${path}`} /> */}

            <Route path="products" element={<AllProducts allProducts={allProducts} getProducts={getProducts} getLink={getLink} updateCart={cartCtx.updateCart} />} key={`${index}-${path}`} />
            <Route path="products/:productSlug" element={<SingleProduct getLink={getLink} updateCart={cartCtx.updateCart} />} key={`${index}-${path}`} />

            <Route path="forgot-password" element={<ForgotPassword getLink={getLink} />} key={`${index}-${path}`} />
            <Route path="reset-password/:token" element={<ResetPassword getLink={getLink} />} key={`${index}-${path}`} />

            {/* <Route path="billing-portal" element={              
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn="" redirectNotLoggedIn={getLink('login')}>
                {axios.get(process.env.REACT_APP_API_URL + 'billing-portal', {
                    headers: { "Authorization": `Bearer ${storedUserToken}` }
                  }).then((response) => window.location.href = response.data.url)
                    .catch((e) => console.log(e))
                }
              </Protected>
            } key={`${index}-${path}`} /> */}

            {/* subscription payments */}
            
            <Route path="checkout" element={
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn="" redirectNotLoggedIn={getLink('signup')}>
                  <SubscriptionCardForm getLink={getLink} />
              </Protected>
            } key={`${index}-${path}`} />

            <Route path="start-your-journey/:setType/:set/:setSku" element={
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn="" redirectNotLoggedIn={getLink('signup')}>
                  <SubscriptionCardForm getLink={getLink} />
              </Protected>
            } key={`${index}-${path}`} />
            
            <Route path="login" element={
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn={getLink('/')} redirectNotLoggedIn="">
                <Login getLink={getLink} />
              </Protected>
            } key={`${index}-${path}`} />

            <Route path="signup" element={
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn={getLink('/')} redirectNotLoggedIn="">
                <Signup getLink={getLink} />
              </Protected>
            } key={`${index}-${path}`} />

            <Route path="order/:order" element={
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn="" redirectNotLoggedIn={getLink('login')}>
                  <OrderComplete getLink={getLink} />
              </Protected>
            } key={`${index}-${path}`} />

            <Route path="profile" element={
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn="" redirectNotLoggedIn={getLink('login')}>
                  <Profile getLink={getLink} />
              </Protected>
            } key={`${index}-${path}`} />

            <Route path="orders" element={
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn="" redirectNotLoggedIn={getLink('login')}>
                  <Orders getLink={getLink} />
              </Protected>
            } key={`${index}-${path}`} />

            <Route path="deliveries" element={
              <Protected isLoggedIn={userCtx.userIsLoggedIn} redirectLoggedIn="" redirectNotLoggedIn={getLink('login')}>
                  <Deliveries getLink={getLink} />
              </Protected>
            } key={`${index}-${path}`} />

          </Route>
        ))}
      </Routes>
    </>
  );
}

export default App;
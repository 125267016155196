import React from "react";
import { useTranslation } from "react-i18next";

const CardSummary = (props) => {
  const { t } = useTranslation();
  return (
    <div className="card border-secondary mb-3">
      <div className="card-body text-secondary">
        <h5 className="card-title">{t("general.paymentInformation")}:</h5>
          <table className="table">
            <tbody>
              <tr>
                <td>{t("general.nameOnCard")}: </td>
                <td>{props.payment.billing_name}</td>
              </tr>
              <tr>
                <td>{t("general.cardNumber")}: </td>
                <td>*** **** **** {props.payment.card_last4}</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  );
};

export default CardSummary;

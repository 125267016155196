import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import DeliveryItemsInfo from "./DeliveryItemsInfo";
import SetInfo from "./SetInfo";

const PastDelivery = (props) => {
  const { t, i18n } = useTranslation();
  const langContentModifier = i18n.language === "en" ? "" : "_zh";

  let estDeliveryStart = null;
  let estDeliveryEnd = null;
  if (props.delivery.relationships.delivery_time_slot !== null) {
    estDeliveryEnd = props.delivery.relationships.delivery_time_slot.start_at;
  } else {
    estDeliveryStart = props.delivery.attributes.estimated_delivery_start;
    estDeliveryEnd = props.delivery.attributes.estimated_delivery_end;
  }

  var productInfoArr = [];
  var productInfo = "";
  if (props.delivery.relationships.delivery_items.length > 0) {
    let i = 1;
    for (const deliveryItem of props.delivery.relationships.delivery_items) {
      if (
        deliveryItem.relationships.product[
          "description" + langContentModifier
        ] !== undefined &&
        deliveryItem.relationships.product[
          "description" + langContentModifier
        ] !== null &&
        deliveryItem.relationships.product[
          "description" + langContentModifier
        ].trim() !== ""
      ) {
        productInfo +=
          "<strong>" +
          t("general.description") +
          ": </strong>" +
          deliveryItem.relationships.product[
            "description" + langContentModifier
          ] +
          "<br />";
      }

      if (
        deliveryItem.relationships.product[
          "interesting_fact" + langContentModifier
        ] !== undefined &&
        deliveryItem.relationships.product[
          "interesting_fact" + langContentModifier
        ] !== null &&
        deliveryItem.relationships.product[
          "interesting_fact" + langContentModifier
        ].trim() !== ""
      ) {
        productInfo +=
          "<strong>" +
          t("general.interestingFact") +
          ": </strong>" +
          deliveryItem.relationships.product[
            "interesting_fact" + langContentModifier
          ] +
          "<br />";
      }

      if (
        deliveryItem.relationships.product["awards" + langContentModifier] !==
          undefined &&
        deliveryItem.relationships.product["awards" + langContentModifier] !==
          null &&
        deliveryItem.relationships.product[
          "awards" + langContentModifier
        ].trim() !== ""
      ) {
        productInfo +=
          "<strong>" +
          t("general.awards") +
          ": </strong>" +
          deliveryItem.relationships.product["awards" + langContentModifier] +
          "<br />";
      }

      if (
        deliveryItem.relationships.rice_type["name" + langContentModifier] !==
          null &&
        deliveryItem.relationships.rice_type[
          "name" + langContentModifier
        ].trim() !== ""
      ) {
        productInfo +=
          "<strong>" +
          t("general.riceType") +
          ":&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
          deliveryItem.relationships.rice_type["name" + langContentModifier] +
          "<br />";
      }

      if (
        deliveryItem.relationships.product.alcohol_content !== undefined &&
        deliveryItem.relationships.product.alcohol_content !== null &&
        deliveryItem.relationships.product.alcohol_content.trim() !== ""
      ) {
        productInfo +=
          "<strong>" +
          t("general.alcoholContent") +
          ":&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
          deliveryItem.relationships.product.alcohol_content +
          "%" +
          "<br />";
      }

      if (
        deliveryItem.relationships.product.sake_meter_value !== undefined &&
        deliveryItem.relationships.product.sake_meter_value !== null &&
        deliveryItem.relationships.product.sake_meter_value !== ""
      ) {
        productInfo +=
          "<strong>" +
          t("general.sakeMeterValue") +
          ":&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
          deliveryItem.relationships.product.sake_meter_value +
          "<br />";
      }

      if (
        deliveryItem.relationships.product.rice_polishing_ratio !== undefined &&
        deliveryItem.relationships.product.rice_polishing_ratio !== null &&
        deliveryItem.relationships.product.rice_polishing_ratio !== ""
      ) {
        productInfo +=
          "<strong>" +
          t("general.ricePolishingRatio") +
          ":&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
          deliveryItem.relationships.product.rice_polishing_ratio +
          "%" +
          "<br />";
      }

      if (
        deliveryItem.relationships.product.rice_polishing_ratio !== undefined &&
        deliveryItem.relationships.product.rice_polishing_ratio !== null &&
        deliveryItem.relationships.rice_polishing_ratio !== ""
      ) {
        let sakeType =
          parseFloat(deliveryItem.relationships.product.rice_polishing_ratio) >=
          50
            ? t("general.ginjo")
            : "";
        sakeType =
          parseFloat(deliveryItem.relationships.product.rice_polishing_ratio) <
          50
            ? t("general.daiginjo")
            : "";
        let junmai =
          deliveryItem.relationships.product.is_junmai === 1
            ? t("general.junmai")
            : "";
        productInfo +=
          "<strong>" +
          t("general.sakeType") +
          ":&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
          junmai +
          " " +
          sakeType +
          "<br />";
      }

      if (productInfo.trim() !== "") {
        productInfo =
          '<div style="font-weight: bold; font-size: 1.2rem"></div>' +
          productInfo;
        productInfoArr.push(productInfo);
        productInfo = "";
      }
    }
  }

  return (
    <>
      <div className="card text-start">
        {props.delivery.relationships.set !== null && (
          <SetInfo
            set={props.delivery.relationships.set}
            set_sku_type={props.delivery.relationships.set_sku_type}
            product_type={props.delivery.relationships.product_type}
          />
        )}
        {props.delivery.relationships.set === null && (
          <DeliveryItemsInfo
            deliveryItems={props.delivery.relationships.delivery_items}
          />
        )}
        <ul className="list-group list-group-flush">          
          <li className="list-group-item">
            <strong>{t("general.orderNumber")}</strong>: {props.delivery.relationships.order.order_number}
          </li>
          <li className="list-group-item">
            <strong>{t("general.orderCreated")}</strong>: {moment(props.delivery.relationships.order.created_at).format("MMMM Do YYYY")}
          </li>
          <li className="list-group-item">
            <strong>
              {t("deliveries.estimatedShipmentDate")}:&nbsp;&nbsp;
            </strong>
            {estDeliveryStart !== null && (
              <>{moment(estDeliveryStart).format("MMMM Do")} - </>
            )}
            {moment(estDeliveryEnd).format("MMMM Do YYYY")}{" "}
          </li>
          {(props.delivery.relationships.set !== null && productInfoArr.length > 0) &&
            productInfoArr.map((info, idx) => <li className="list-group-item" key={idx}>
              <strong>
                {t("general.sake")}&nbsp;{idx + 1}:
              </strong>
              <br />
              <div dangerouslySetInnerHTML={{ __html: info }} />
            </li>)}
        </ul>
      </div>
      <br />
      <br />
    </>
  );
};

export default PastDelivery;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { validate as validateEmail } from 'react-email-validator';
import UserContext from '../../../../contexts/user-context';
import ValidationError from '../../ui/ValidationError';
import Breadcrumb from '../../ui/Breadcrumb';
import Header from '../../../../layouts/frontend/Header';
import Footer from '../../../../layouts/frontend/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helmet from 'react-helmet';

axios.defaults.withCredentials = true;

const Signup = (props) => {
  const { t } = useTranslation();
  const firstNameInputRef = useRef();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const passwordConfirmInputRef = useRef();
  const phoneNumberInputRef = useRef();
  const userCtx = useContext(UserContext);
  const [ submitBtnDisabled, setSubmitBtnDisabled ] = useState(false);
  const [ phoneInputDisabled, setPhoneInputDisabled ] = useState(false);
  const [ phoneVerifyStatus, setPhoneVerifyStatus ] = useState(null); // null, pending, approved
  const [ subscribeNewsletter, setSubscribeNewsletter ] = useState(false);
  
  const onSubmitHandler = async (e) => {
    e.preventDefault();

    //disable submit button
    setSubmitBtnDisabled(true);

    //do basic data validation
    if (validateUser(firstNameInputRef.current.value, emailInputRef.current.value, passwordInputRef.current.value, phoneNumberInputRef.current.value) === false) {
      //if not ok, alert user
      setSubmitBtnDisabled(false);
      return;
    }
    
    
    if (phoneVerifyStatus === null) {
      // do phone verify
      const { response:twilioVerifyError, data:twilioVerification } = await axios.post(process.env.REACT_APP_API_URL + 'register/phone/verify_create', {
        'phone': phoneNumberInputRef.current.value
      })
      .catch((error) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          t('errorMessages.phoneEmptyTitle'),
          "error"
        );
        return;
      });

      if (twilioVerifyError) {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          twilioVerifyError.response.data.message,
          "error"
        );
        return;
      }

      setPhoneVerifyStatus(twilioVerification.status);

      if (twilioVerification.status === 'pending') {
        phoneVerifyCheckThenSubmit();
      }
    }

    if (phoneVerifyStatus === 'pending') {
      phoneVerifyCheckThenSubmit();
    }

    if (phoneVerifyStatus === 'approved') {
      userCtx.addUser(firstNameInputRef.current.value, emailInputRef.current.value, passwordInputRef.current.value, passwordConfirmInputRef.current.value, phoneNumberInputRef.current.value, subscribeNewsletter)
      .then(response => {
        setSubmitBtnDisabled(false);
      })
      .catch((err) => {
        setSubmitBtnDisabled(false);
      });
    }
  }

  const toggleSuscribe = () => {
    if (subscribeNewsletter === false) {
      setSubscribeNewsletter(true);
    } else {
      setSubscribeNewsletter(false);
    }
  }

  const phoneVerifyCheckThenSubmit = () => {
    Swal.fire({
      title: t("signup.submitJSCode"),
      text: t('signup.submitJSCodeDescription'),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: false,
      confirmButtonText: t("signup.submitJSConfirm"),
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: (code) => {
        axios.post(process.env.REACT_APP_API_URL + 'register/phone/verify_check', {
          phone: phoneNumberInputRef.current.value,
          code: code
        })
        .then(response => {
          setPhoneInputDisabled(true);
          setPhoneVerifyStatus(response.data.status);

          if (response.data.status === 'approved') {
            setPhoneInputDisabled(true);

            //if ok, continue -> addUser
            userCtx.addUser(firstNameInputRef.current.value, emailInputRef.current.value, passwordInputRef.current.value, passwordConfirmInputRef.current.value, phoneNumberInputRef.current.value)
            .then(response => {
              setSubmitBtnDisabled(false);
            })
            .catch((err) => {
              setSubmitBtnDisabled(false);
            });
          } else {
            Swal.fire({ icon: 'error', title: t("errorMessages.twilioVerifyCheckError"), text: response.data.message });
            setSubmitBtnDisabled(false);
          }
        })
        .catch(error => {
            setPhoneInputDisabled(true);
            Swal.fire({ icon: 'error', title: t("errorMessages.twilioVerifyCheckError"), text: error.response.data.message });
            setSubmitBtnDisabled(false);
        });
      }
    });
  }
  
  /**
   * 
   * @param {string} firstName 
   * @param {string} emailAddress
   * @param {string} password 
   * @returns boolean
   */
  const validateUser = (firstName, emailAddress, password, phone) => {
    // check empties
    if (firstName.trim().length === 0) {
      Swal.fire({ icon: 'error', title: t("errorMessages.firstNameEmptyTitle"), text: t("errorMessages.firstNameEmptyText") });
      return false;
    }

    if (emailAddress.trim().length === 0) {
      Swal.fire({ icon: 'error', title: t("errorMessages.emailAddressEmptyTitle"), text: t("errorMessages.emailAddressEmptyText") });
      return false;
    }

    if (password.trim().length === 0) {
      Swal.fire({ icon: 'error', title: t("errorMessages.passwordEmptyTitle"), text: t("errorMessages.passwordEmptyText") });
      return false;
    }

    // check valid email
    if (validateEmail(emailAddress) === false) {
      Swal.fire({ icon: 'error', title: t("errorMessages.emailAddressInvalidTitle"), text: t("errorMessages.emailAddressInvalidText") });
      return false;
    }

    if (phone.trim().length === 0) {
      Swal.fire({ icon: 'error', title: t("errorMessages.phoneEmptyTitle"), text: t("errorMessages.phoneEmptyText") });
      return false;
    }

    // check password length
    // TODO - Include better password validation
    if (password.trim().length < 8) {
      Swal.fire({ icon: 'error', title: t("errorMessages.passwordInvalidLengthTitle"), text: t("errorMessages.passwordInvalidLengthText") });
      return false;
    }
  }

  useEffect(() => {
    //bypass phone check if local
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      setPhoneVerifyStatus('approved');
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('navigation.signup')} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb crumbs={[
          {text: t("navigation.home"), link: props.getLink("/")},
          {text: t("navigation.signup"), link: ''}
      ]} />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="signup">
        <div className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6 pt-4 pb-5">
              <h2 className="display-6">{t("signup.title")}</h2>
              <br />

              <form onSubmit={onSubmitHandler}>
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">{t("signup.firstName")}</label>
                  <input type="text" className="form-control" aria-describedby="emailHelp" ref={firstNameInputRef} />
                  <ValidationError message={userCtx.errors.name} />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">{t("signup.emailAddress")}</label>
                  <input type="email" className="form-control" aria-describedby="emailHelp" ref={emailInputRef} />
                  <ValidationError message={userCtx.errors.email} />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">{t("signup.password")}</label>
                  <input type="password" className="form-control" ref={passwordInputRef} />
                  <ValidationError message={userCtx.errors.password} />
                </div>
                <div className="mb-3">
                  <label htmlFor="passwordConfirm" className="form-label">{t("signup.confirmPassword")}</label>
                  <input type="password" className="form-control" ref={passwordConfirmInputRef} />
                  <ValidationError message={userCtx.errors.password_confirmation} />
                </div>
                <label htmlFor="passwordConfirm" className="form-label">{t("signup.phoneNumber")}</label>
                <div className="mb-3 row">
                  <div className="col">
                    <input type="text" className="form-control" disabled={phoneInputDisabled} placeholder={t("signup.enterPhoneNumber")} ref={phoneNumberInputRef} aria-label="Phone Number" />
                    <ValidationError message={userCtx.errors.phone} />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="1" id="terms" onChange={toggleSuscribe} defaultChecked={subscribeNewsletter} />
                      <label className="form-check-label" htmlFor="terms">
                        {t('signup.checkToSubscribeText')}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col" dangerouslySetInnerHTML={{ __html: t("signup.signupAcceptTerms") }} />
                </div>

                <button type="submit" disabled={submitBtnDisabled} className="btn btn-primary" id="submitBtn">{t("navigation.signup")}</button>

                <hr style={{ color: "#ccc" }} className="my-4 mt-5" />

                <Link to={props.getLink('login')}>{t("navigation.login")}</Link>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer getLink={props.getLink} />
    </>
  );
}

export default Signup;
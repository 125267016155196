import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loader from '../../ui/Loader';
import Product from '../products/partials/Product';

const PreviewProducts = (props) => {
  const { t } = useTranslation();
  const title = props.title;
  const products = props.products;
  const updateCart = props.updateCart;
  const getLink = props.getLink;

  return (
    <div className="card text-center">
      <div className="card-header">{title}&nbsp;&nbsp;&nbsp;&nbsp;<Link to={props.moreLink} className="btn btn-sm btn-success">{t("general.moreInfo")}...</Link></div>
      <div className="card-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col d-flex flex-wrap justify-content-center">
            {!products && <Loader />}
            {products &&
              products.map((product, idx) => (
                <Product
                  updateCart={updateCart}
                  product={product}
                  getLink={getLink}
                  key={idx}
                />
            ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewProducts;
import React from "react";
import { useTranslation } from "react-i18next";
import {
  percentFormat,
  smvFormat,
} from "../../../../../../src/assets/frontend/js/numberFormat";

//import images
import imgSmv from "../../../../../../src/assets/frontend/assets/icons/smv.png";
import imgAcidity from "../../../../../../src/assets/frontend/assets/icons/acidity.png";
import imgPolishing from "../../../../../../src/assets/frontend/assets/icons/polishing.png";
import imgRiceType from "../../../../../../src/assets/frontend/assets/icons/rice-type.png";
import imgAlcohol from "../../../../../../src/assets/frontend/assets/icons/alcohol.png";
import imgFruitiness from "../../../../../../src/assets/frontend/assets/icons/fruitiness.png";
import imgSweetness from "../../../../../../src/assets/frontend/assets/icons/sweetness.png";
import imgSourness from "../../../../../../src/assets/frontend/assets/icons/sourness.png";

import "./Product.min.css";
import { Link } from "react-router-dom";
import AddToCartButtons from "./AddToCartButtons";

const Product = (props) => {
  const { t, i18n } = useTranslation();
  const langContentModifier = i18n.language === "en" ? "" : "_zh";

  const productDetailHandler = (url) => {
    window.open(url);
  }

  return (
    <>
      {(props.display === 'card' || props.display === undefined) && (
        <div className={props.type === 'set-details' ? `set-details card mb-3 product` : `card mb-3 product`}>
          <div className="row g-0">
            <div className="col-sm-4 image" onClick={() => productDetailHandler(props.getLink('products/' + props.product.attributes.slug))}>
              <img
                src={props.product.attributes.product_image_name_vertical}
                alt=""
              />
            </div>
            <div className="col-sm-8">
              <div className="card-body" onClick={() => productDetailHandler(props.getLink('products/' + props.product.attributes.slug))}>
                <h6>
                  {
                    props.product.relationships.brewery[
                      "name" + langContentModifier
                    ]
                  }
                </h6>
                <h5 className="card-title">
                  {props.product.attributes["name" + langContentModifier]}
                </h5>
                <div className="card-text">
                  <table style={{ fontSize: "0.8rem", lineHeight: "1.3rem" }}>
                    <tbody>
                      {props.product.attributes.rice_polishing_ratio !==
                        null && (
                        <tr>
                          <td
                            style={{
                              color: "#e1627e",
                              fontWeight: "bold",
                              paddingRight: "10px",
                            }}
                          >
                            <img src={imgPolishing} style={{ width: "15px" }} />{" "}
                            {t("OurProducts.ricePolishingRatio")}:{" "}
                          </td>
                          <td>
                            {percentFormat(
                              props.product.attributes.rice_polishing_ratio
                            )}
                            %
                          </td>
                        </tr>
                      )}
                      {props.product.attributes.alcohol_content !== null && (
                        <tr>
                          <td
                            style={{
                              color: "#e1627e",
                              fontWeight: "bold",
                              paddingRight: "10px",
                            }}
                          >
                            <img src={imgAlcohol} style={{ width: "15px" }} />{" "}
                            {t("OurProducts.alcoholPercent")}:{" "}
                          </td>
                          <td>
                            {percentFormat(
                              props.product.attributes.alcohol_content
                            )}
                            %
                          </td>
                        </tr>
                      )}
                      {props.product.attributes.sake_meter_value !== null && (
                        <tr>
                          <td
                            style={{
                              color: "#e1627e",
                              fontWeight: "bold",
                              paddingRight: "10px",
                            }}
                          >
                            <img src={imgSmv} style={{ width: "15px" }} />{" "}
                            {t("OurProducts.sakeMeterValue")}:{" "}
                          </td>
                          <td>
                            {smvFormat(
                              props.product.attributes.sake_meter_value
                            )}
                          </td>
                        </tr>
                      )}
                      {props.product.attributes.acidity !== null && (
                        <tr>
                          <td
                            style={{
                              color: "#e1627e",
                              fontWeight: "bold",
                              paddingRight: "10px",
                            }}
                          >
                            <img src={imgAcidity} style={{ width: "15px" }} />{" "}
                            {t("OurProducts.acidity")}:{" "}
                          </td>
                          <td>{props.product.attributes.acidity}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <br />
                  <Link className="btn btn-sm btn-primary more-info" target="_blank" to={props.getLink('products/' + props.product.attributes.slug)}>{t("general.moreInfo")}</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer" style={{ width: "100%" }}>
            {(props.product) && <AddToCartButtons 
              product={props.product}
            />}
          </div>
        </div>
      )}

      {props.display === "table-row" && (
        <tr className="product-table-display">
          <td className="image-container">
            <img
              src={props.product.attributes.product_image_name_vertical}
              alt=""
            />
          </td>
          <td className="product-info">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h6>
                    {
                      props.product.relationships.brewery[
                        "name" + langContentModifier
                      ]
                    }
                  </h6>
                  <h5 className="card-title">
                    {props.product.attributes["name" + langContentModifier]}
                  </h5>
                  <Link className="btn btn-sm btn-primary more-info" target="_blank" to={props.getLink('products/' + props.product.attributes.slug)}>{t("general.moreInfo")}</Link>
                </div>
                <div className="col-sm-6">
                  {props.product && <AddToCartButtons 
                    product={props.product}
                  />}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default Product;

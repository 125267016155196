import React from "react";
import { useTranslation } from "react-i18next";
import './DeliveryItemsInfo.min.css';

const DeliveryItemsInfo = (props) => {
  const { t, i18n } = useTranslation();
  const langContentModifier = i18n.language === "en" ? "" : "_zh";
  return (
    <>
      <div className="card-body">
        <h4 className="card-title">
          {t('general.singleDeliveries')}
        </h4>
      </div>
      <div className="list-group-item" style={{ border: "0px", borderTop: "1px solid rgba(0,0,0,.125)" }}>
        <h5>{t("general.orderProducts")}</h5>
        <table className="table product-items">
          <tbody>
            {props.deliveryItems.map((deliveryItem, idx) => <tr key={idx}>
              <td>
                <img src={deliveryItem.attributes.product_image_name_vertical} alt={deliveryItem.relationships.product['name' + langContentModifier]} />
              </td>
              <td>
                {deliveryItem.relationships.product['name' + langContentModifier]} - {deliveryItem.relationships.product_type['name' + langContentModifier]}<br />
                <strong>{deliveryItem.relationships.brewery['name' + langContentModifier]}</strong>
              </td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DeliveryItemsInfo;
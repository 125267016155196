import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { numberFormat } from '../../../../assets/frontend/js/numberFormat';

const SetPriceCard = (props) => {
  const { t } = useTranslation();
  const monthlySetSku = props.setSkus.find((setSku) => setSku.relationships.set_sku_type.payment_period_count === 1 && setSku.relationships.set_sku_type.period_type === 'monthly');
  const monthlyPrice = monthlySetSku.relationships.set_sku_type.price;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-header">
          <strong>{t("general.subscriptionInfo")}</strong>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <i>{t("general.setContent")}: {props.set.attributes.product_quantity} x{" "}
            {props.productType["name" + props.langContentModifier]}</i><br />
            {t("general.setContentInfo")}           
          </li>
        </ul>

        <div className="card-header">
          <strong>{t("general.buyingOptionsSingle")}</strong>
        </div>
        <ul className="list-group list-group-flush">
          {props.setSkus.map((setSku, idx) => {
            if (setSku.relationships.set_sku_type.type === 'prepay') {
              const name = setSku.relationships["set_sku_type"]["name" + props.langContentModifier];
              const nameArr = name.split('(');
              return (
                <li className="list-group-item" style={{ background: "ivory" }} key={idx}>
                  <strong>{numberFormat(setSku.relationships.set_sku_type.price)}</strong><br />{" "}
                  <i>
                    {
                      nameArr[0]
                    }
                    {/* {" "}{setSku.relationships["set_sku_type"]["type"]} */}
                  </i>
                  <Link to={props.getLink('start-your-journey/' + props.setType.attributes['slug'] + '/' + setSku.relationships.set.slug + '/' + setSku.id)} className="btn btn-sm btn-subscribe float-end glow-on-hover">{t("general.payNow")}</Link>
                </li>
              );
            }
          })}
        </ul>

        <div className="card-header">
          <strong>{t("general.buyingOptions")}:</strong>
        </div>
        <ul className="list-group list-group-flush">
          {props.setSkus.map((setSku, idx) => {
            if (setSku.relationships.set_sku_type.type === 'subscription') {
              const name = setSku.relationships["set_sku_type"]["name" + props.langContentModifier];
              const nameArr = name.split('(');
              return (
                <li className="list-group-item" key={idx}>
                  {setSku.relationships.set_sku_type.payment_period_count > 1 && <span className="discount-badge badge bg-danger">
                    {setSku.relationships.set_sku_type.payment_period_count === 3 && t("general.5%Off")}
                    {setSku.relationships.set_sku_type.payment_period_count === 6 && t("general.10%Off")}
                  <span className="visually-hidden">unread messages</span></span>}
                  
                  <strong className="discounted-price">{numberFormat(setSku.relationships.set_sku_type.price)}</strong>

                  {setSku.relationships.set_sku_type.payment_period_count > 1 && <span className="strike-original-price">{numberFormat(setSku.relationships.set_sku_type.payment_period_count * monthlyPrice)}</span>}

                  <br />{" "}
                  <i>
                    {
                      nameArr[0]
                    }
                    {/* {" "}{setSku.relationships["set_sku_type"]["type"]} */}
                  </i>
                  <Link to={props.getLink('start-your-journey/' + props.setType.attributes['slug'] + '/' + setSku.relationships.set.slug + '/' + setSku.id)} className="btn btn-sm btn-subscribe float-end glow-on-hover">{t("general.subscribe")}</Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </React.Fragment>
  );
}

export default SetPriceCard;
import React from 'react';

import classes from './ValidationError.module.css';

const ValidationError = (props) => {
  return (
    <React.Fragment>
      <div className={classes.error}>{props.message}</div>
    </React.Fragment>
  );
}

export default ValidationError;
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import ValidationErrors from "../utility/ValidationErrors";
import CartContext from "./cart-context";
import UserContext from "./user-context";

axios.defaults.withCredentials = true;

const CartProvider = (props) => {
  const { t } = useTranslation();

  /**
    const cart = [
      'total' => ...,
      'total_bottles' => ...,
      'total_bottles_to_price => ..., // the bottle numbers to use for grabbing price data
      'msrp_total' => ...,
      'no_discount_total' =>...,
      'items' => [
        [
          'product_skus_id' => ...,
          'quantity' => ...,
          'set_skus_id' => ...,
          'item' => {}
        ]
      ]
    ];
   */
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const userCtx = useContext(UserContext);
  const storedUserToken = localStorage.getItem("userToken");

  const showCartHandler = () => {
    setShowCart((prevShowCart) => {
      if (prevShowCart === false) {
        localStorage.setItem("showCart", true);
        return true;
      } else if (prevShowCart === true) {
        localStorage.setItem("showCart", false);
        return false;
      }
    });
  };

  const setCartFromApi = (cart) => {
    var _cart = [];
    if (cart !== null) {
      const cartItems = cart.relationships.cart_items;
      _cart = cart.attributes;
      _cart["items"] = [];
      if (cartItems !== null && cartItems.length > 0) {
        for (var i = 0; i < cartItems.length; i++) {
          const cartItem = cartItems[i];
          if (cartItem.attributes.product_skus_id !== null) {
            _cart["items"].push({
              quantity: cartItem.attributes.quantity,
              product_skus_id: cartItem.attributes.product_skus_id,
              set_skus_id: cartItem.attributes.set_skus_id,
              item:
                cartItem.attributes.product_skus_id !== null
                  ? cartItem.relationships.product_sku
                  : cartItem.relationships.set_sku,
              item_type:
                cartItem.attributes.product_skus_id !== null
                  ? cartItem.relationships.product_type
                  : cartItem.relationships.set_sku_type,
              product: cartItem.relationships.product,
              item_brewery: cartItem.relationships.brewery,
            });
          }
        }
      }

      if (_cart["items"].length > 0) {
        saveCart(_cart);
      }
    }
  };

  const setCartToLocalStorage = (cart) => {
    const cartJSON = JSON.stringify(cart);
    localStorage.setItem("cartJSON", cartJSON);
  };

  const setCartObj = () => {
    const cartJSON = localStorage.getItem("cartJSON");
    const cart = JSON.parse(cartJSON);
    saveCart(cart);
  };

  const saveCart = (cart) => {
    if (!cart) {
      cart = {};
    }

    // if a cart is from old data without price_data, we need to clear it.
    if (
      cart.items !== undefined &&
      cart.items.length > 0 &&
      cart.items[0].item.price_data === undefined
    ) {
      clearCart();
      cart = {};
    }

    const data = calculateTotals(cart);
    cart.total = data.cartTotal;
    cart.total_bottles = data.numBottles;
    cart.total_bottles_to_price = data.numBottlesToPrice;
    cart.no_discount_total = data.noDiscountTotal;
    cart.msrp_total = data.MsrpTotal;
    setCart((prevCart) => {
      let updatedCart = { ...cart };
      return updatedCart;
    });
    setCartToLocalStorage(cart);
  };

  const calculateTotals = (cart) => {
    // total bottles
    const numBottles = calculateNumBottles(cart);

    // total bottles to price
    const numBottlesToPrice =
      numBottles > process.env.REACT_APP_MAX_BOTTLES_TO_PRICE
        ? process.env.REACT_APP_MAX_BOTTLES_TO_PRICE
        : numBottles;

    // cart total
    const cartTotal = calculateCartTotal(cart, numBottlesToPrice);

    // no discount total
    const noDiscountTotal = calculateNoDiscountTotal(cart);

    // msrp total
    const MsrpTotal = calculateMsrpTotal(cart);

    return {
      numBottles: numBottles,
      numBottlesToPrice: numBottlesToPrice,
      cartTotal: cartTotal,
      noDiscountTotal: noDiscountTotal,
      MsrpTotal: MsrpTotal,
    };
  };

  const calculateNumBottles = (cart) => {
    if (isCartEmpty(cart) === true) {
      return 0;
    }

    return cart.items.reduce((prev, current) => prev + current.quantity, 0);
  };

  const calculateCartTotal = (cart, numBottlesToPrice) => {
    if (
      isCartEmpty(cart) === true ||
      numBottlesToPrice === 0 ||
      numBottlesToPrice === undefined
    ) {
      return 0;
    }

    // calculate based on current items in cart count
    return cart.items.reduce((prev, current) => {
      return (
        prev +
        parseInt(current.quantity) *
          parseFloat(current.item.price_data[numBottlesToPrice - 1].price)
      );
    }, 0);
  };

  const calculateMsrpTotal = (cart) => {
    if (isCartEmpty(cart) === true) {
      return 0;
    }

    // calculate based on current items in cart count
    return cart.items.reduce(
      (prev, current) =>
        prev + parseInt(current.quantity) * parseFloat(current.item.msrp_price),
      0
    );
  };

  const calculateNoDiscountTotal = (cart) => {
    if (isCartEmpty(cart) === true) {
      return 0;
    }

    // calculate based on current items in cart count
    return cart.items.reduce(
      (prev, current) =>
        prev + parseInt(current.quantity) * parseFloat(current.item.price),
      0
    );
  };

  /**
   *
   * @param {*} set_skus_id
   * @param {*} product_skus_id
   * @param {*} quantity
   * @param {*} updateType | increment | fixed - increment = add towards cart amount, fixed = replaces cart quantity
   * @returns
   */
  const updateCart = (set_skus_id, product_skus_id, quantity, updateType) => {
    let _cart = cart;
    let updateQuantity = parseInt(quantity);

    updateCartApi(set_skus_id, product_skus_id, quantity);

    if (!isCartEmpty(_cart)) {
      const itemKey = findItemKey(set_skus_id, product_skus_id);

      if (itemKey === null) {
        //item not found
        addToCart(set_skus_id, product_skus_id, quantity);
      } else {
        //update quantity
        const cartQuantity =
          parseInt(_cart.items[itemKey].quantity) >= 0
            ? parseInt(_cart.items[itemKey].quantity)
            : 0;

        if (updateType === "increment") {
          updateQuantity += cartQuantity;
        }

        if (updateQuantity > 0) {
          // increment item
          _cart.items[itemKey].quantity = updateQuantity;
        } else {
          // delete item
          _cart.items.splice(itemKey, 1);
        }

        saveCart(_cart);

        return _cart;
      }
    } else {
      addToCart(set_skus_id, product_skus_id, quantity);
    }
  };

  const updateCartApi = (set_skus_id, product_skus_id, quantity) => {
    const itemKey = set_skus_id !== null ? "set_skus_id" : "product_skus_id";
    const itemValue = set_skus_id !== null ? set_skus_id : product_skus_id;
    let options = {
      quantity: quantity,
    };
    options[itemKey] = itemValue;

    axios
      .post(process.env.REACT_APP_API_URL + "cart/cart_items", options, {
        headers: { Authorization: `Bearer ${storedUserToken}` },
      })
      .catch((err) =>
        ValidationErrors(
          err.response.data.errors,
          [
            "set_skus_id_and_product_skus_id_both",
            "set_skus_id_and_product_skus_id_none",
            "set_skus_id",
            "product_skus_id",
          ],
          t
        )
      );
  };

  const findItemKey = (set_skus_id, product_skus_id) => {
    if (set_skus_id !== null) {
      for (let i = 0; i < cart.items.length; i++) {
        if (cart.items[i].set_skus_id === parseInt(set_skus_id)) {
          return i;
        }
      }
    }

    if (product_skus_id !== null) {
      for (let i = 0; i < cart.items.length; i++) {
        if (
          parseInt(cart.items[i].product_skus_id) === parseInt(product_skus_id)
        ) {
          return i;
        }
      }
    }

    return null;
  };

  /** #TODO - refactor getSku stuff so this function isn't so long............ */
  const addToCart = (set_skus_id, product_skus_id, quantity) => {
    let _cart = cart;
    if (set_skus_id !== null) {
      axios
        .get(process.env.REACT_APP_API_URL + "set_skus/" + set_skus_id, {
          headers: { Authorization: `Bearer ${storedUserToken}` },
        })
        .catch((err) => {
          Swal.fire(
            t("errorMessages.swalDefaultTitle"),
            err.response.data.message,
            "error"
          );
        })
        .then((response) => {
          const item = {
            quantity: quantity,
            product_skus_id: product_skus_id,
            set_skus_id: set_skus_id,
            item: response.data.data.attributes,
            item_type: response.data.data.relationships.set_sku_type,
            product: null,
            item_brewery: null,
          };

          if (_cart.items === undefined) {
            _cart.items = [item];
          } else {
            _cart.items.push(item);
          }

          saveCart(_cart);

          return _cart;
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL + "product_skus/" + product_skus_id,
          {
            headers: { Authorization: `Bearer ${storedUserToken}` },
          }
        )
        .catch((err) => {
          Swal.fire(
            t("errorMessages.swalDefaultTitle"),
            err.response.data.message,
            "error"
          );
        })
        .then((response) => {
          const item = {
            quantity: quantity,
            product_skus_id: product_skus_id,
            set_skus_id: set_skus_id,
            item: response.data.data.attributes,
            product: response.data.data.relationships.product,
            item_type: response.data.data.relationships.product_type,
            item_brewery: response.data.data.relationships.brewery,
          };

          if (_cart.items === undefined) {
            _cart.items = [item];
          } else {
            _cart.items.push(item);
          }

          saveCart(_cart);

          return _cart;
        });
    }
  };

  const clearCart = () => {
    saveCart([]);
  };

  const isCartEmpty = (_cart) => {
    const __cart = _cart !== undefined ? _cart : cart;
    if (!__cart || __cart.items === undefined || __cart.items.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      typeof userCtx.user === "object" &&
      Object.keys(userCtx.user).length > 0
    ) {
      setCartFromApi(userCtx.user.relationships.cart);
    } else {
      setCartObj();
    }
  }, [userCtx.user]);

  useEffect(() => {
    const storedShowCart = localStorage.getItem("showCart");
    if (storedShowCart === "false" || storedShowCart === null) {
      setShowCart(false);
    } else {
      setShowCart(true);
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart: cart,
        showCart: showCart,
        showCartHandler: showCartHandler,
        updateCart: updateCart,
        isCartEmpty: isCartEmpty,
        clearCart: clearCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { numberFormat } from '../../../../../assets/frontend/js/numberFormat';

const BottleDiscountBar = (props) => {
  const { t } = useTranslation();
  const numBottlesToPrice = props.numBottlesToPrice;
  const cartTotal = props.cartTotal;
  const noDiscountTotal = props.noDiscountTotal;
  const msrpTotal = props.msrpTotal;
  const maxBottlesToPrice = process.env.REACT_APP_MAX_BOTTLES_TO_PRICE;

  const bottlesToGoMessage = (msg, maxMsg, bottlesToMaxDiscount) => {
    if (bottlesToMaxDiscount > 0) {
      return t(msg, { bottles: bottlesToMaxDiscount });
    } else {
      return t(maxMsg);
    }
  } 

  const bottlesToMaxDiscount = maxBottlesToPrice - numBottlesToPrice;
  const progressbarPercent = (numBottlesToPrice/maxBottlesToPrice) * 100;

  return <>
    <div className="text-center" style={{ fontSize: "0.7rem", lineHeight: "1.2rem" }} dangerouslySetInnerHTML={{ __html: bottlesToGoMessage("general.numBottlesForMaxDiscountMessage", "general.maxDiscountReachedMessage", bottlesToMaxDiscount) }} />

    <table width="100%" style={{ fontSize: "0.7rem", lineHeight: "0.7rem" }}>
      <tbody>
        <tr>
          <td><strong>1</strong></td>
          <td width="100%">
            <div className="progress" style={{ height: "10px" }}>
                <div className={`progress-bar progress-bar-striped progress-bar-animated ${maxBottlesToPrice > numBottlesToPrice ? `bg-warning` : `bg-success`}`} role="progressbar" style={{ color: "#000", fontSize: "0.5rem", width: `${progressbarPercent}%` }} aria-valuenow={progressbarPercent} aria-valuemin="0" aria-valuemax="100"><strong>{numBottlesToPrice} {t("general.perBottles")}</strong></div>
            </div>
          </td>
          <td><strong>{maxBottlesToPrice}</strong></td>
        </tr>
      </tbody>
    </table>

    <div className="alert alert-success" style={{ padding: "0 10px", margin: "5px 0px", fontSize: "0.7rem", lineHeight: "1rem", textAlign: "center" }} role="alert">
        {t("general.discountTotal")}: <strong>{numberFormat(msrpTotal - cartTotal)}</strong>
    </div>
  </>
}

export default BottleDiscountBar;
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../../../../layouts/frontend/Footer";
import Header from "../../../../layouts/frontend/Header";
import Breadcrumb from "../../ui/Breadcrumb";
import { percentFormat } from "../../../../assets/frontend/js/numberFormat";

//import images
import imgSmv from "../../../../assets/frontend/assets/icons/smv.png";
import imgAcidity from "../../../../assets/frontend/assets/icons/acidity.png";
import imgPolishing from "../../../../assets/frontend/assets/icons/polishing.png";
import imgRiceType from "../../../../assets/frontend/assets/icons/rice-type.png";
import imgAlcohol from "../../../../assets/frontend/assets/icons/alcohol.png";
import imgFruitiness from "../../../../assets/frontend/assets/icons/fruitiness.png";
import imgSweetness from "../../../../assets/frontend/assets/icons/sweetness.png";
import imgSourness from "../../../../assets/frontend/assets/icons/sourness.png";

import "./SingleProduct.min.css";
import SmvAcidityChart from "./SmvAcidityChart";
import AddToCartButtons from "../products/partials/AddToCartButtons";

const SingleProduct = (props) => {
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState(null);
  const storedUserToken = localStorage.getItem("userToken");
  const langContentModifier = i18n.language === "en" ? "" : "_zh";

  //params
  const { productSlug } = useParams();

  const getProduct = async () => {
    const url = process.env.REACT_APP_API_URL + "products/" + productSlug;
    const product = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${storedUserToken}`,
      },
    });
    return product;
  };

  useEffect(() => {
    getProduct()
      .catch((err) => {
        Swal.fire(
          t("errorMessages.swalDefaultTitle"),
          err.response.data.message,
          "error"
        );
      })
      .then((response) => {
        if (typeof response != "undefined") {
          setProduct(response.data.data);
        } else {
          Swal.fire(
            t("errorMessages.swalDefaultTitle"),
            t("errorMessages.noData"),
            "error"
          );
        }
      });

    window.scrollTo(0, 0);
  }, [productSlug]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${product?.attributes.name}`} | {t("ourProducts.title")} | JustSaké Hong Kong
        </title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          {
            text: t("OurProducts.titlePlain"),
            link: props.getLink("products"),
          },
          { text: product?.attributes["name" + langContentModifier], link: "" },
        ]}
      />

      <div className="liquor-law">{t("general.liquorLaw")}</div>

      <section className="breadcrumb-spacing" id="hiw">
        <div className="section-content pb-0">
          <h2
            className="display-6 force-uppercase"
            dangerouslySetInnerHTML={{
              __html: product?.attributes["name" + langContentModifier],
            }}
          />
        </div>
        <br />
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="card mb-3 single-product-info">
                <div className="row g-0">
                  <div
                    className="col-md-4 image"
                    style={{
                      backgroundImage: `url(${product?.attributes.product_image_name_square})`,
                    }}
                  ></div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">
                        {t("product.productInformation")}
                      </h3>
                      <p className="card-text">
                        {product &&
                          product.attributes[
                            "description" + langContentModifier
                          ]}
                        {product &&
                          (product.attributes[
                            "description" + langContentModifier
                          ] === "" ||
                            product.attributes[
                              "description" + langContentModifier
                            ] === null) &&
                          t("product.noInfo")}
                      </p>
                      <br />
                      <h3 className="card-title">
                        {t("product.interestingFacts")}
                      </h3>
                      <p className="card-text">
                        {product &&
                          product.attributes[
                            "interesting_fact" + langContentModifier
                          ]}
                        {product &&
                          (product.attributes[
                            "interesting_fact" + langContentModifier
                          ] === "" ||
                            product.attributes[
                              "interesting_fact" + langContentModifier
                            ] === null) &&
                          t("product.noInfo")}
                      </p>

                      <br />
                      <h3 className="card-title">
                        {t("product.tastingProfile")}
                      </h3>
                      <p className="card-text">
                        {product &&
                          product.attributes[
                            "tasting_profile" + langContentModifier
                          ]}
                        {product &&
                          (product.attributes[
                            "tasting_profile" + langContentModifier
                          ] === "" ||
                            product.attributes[
                              "tasting_profile" + langContentModifier
                            ] === null) &&
                          t("product.noInfo")}
                      </p>

                      <br />
                      <h3 className="card-title">
                        {t("product.pairsWellWith")}
                      </h3>
                      <p className="card-text">
                        {product &&
                          product.attributes[
                            "pairs_well_with" + langContentModifier
                          ]}
                        {product &&
                          (product.attributes[
                            "pairs_well_with" + langContentModifier
                          ] === "" ||
                            product.attributes[
                              "pairs_well_with" + langContentModifier
                            ] === null) &&
                          t("product.noInfo")}
                      </p>

                      <br />
                      <h3 className="card-title">
                        {t("general.awards")}
                      </h3>
                      <p className="card-text">
                        {product &&
                          product.attributes[
                            "awards" + langContentModifier
                          ]}
                        {product &&
                          (product.attributes[
                            "awards" + langContentModifier
                          ] === "" ||
                            product.attributes[
                              "awards" + langContentModifier
                            ] === null) &&
                          t("product.noInfo")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card shopping-buttons">
                <div className="card-body">
                  {product && <AddToCartButtons product={product} />}
                </div>
              </div>
              <br />

              <div className="card brewery">
                {product?.relationships.brewery.logo_image_name !== "" &&
                  product?.relationships.brewery.logo_image_name !== null &&
                  product?.relationships.brewery.logo_image_name !==
                    undefined && (
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${product?.relationships.brewery.logo_image_name_rectangle})`,
                      }}
                    ></div>
                  )}
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>{t("product.brewery")}: </strong>
                    {
                      product?.relationships.brewery[
                        "name" + langContentModifier
                      ]
                    }
                  </li>
                </ul>
              </div>
              <br />

              {product?.relationships.district !== null && (
                <>
                  <div className="card prefecture">
                    {product?.relationships.district.image_name !== "" &&
                      product?.relationships.district.image_name !== null &&
                      product?.relationships.district.image_name !==
                        undefined && (
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${product?.relationships.district.image_name_medium})`,
                          }}
                        ></div>
                      )}
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <strong>{t("product.prefecture")}: </strong>
                        {
                          product?.relationships.district[
                            "name" + langContentModifier
                          ]
                        }
                      </li>
                    </ul>
                  </div>
                  <br />
                </>
              )}

              <div className="card sake-facts">
                {product &&
                  product.attributes.sake_meter_value !== null &&
                  product.attributes.acidity !== null && (
                    <div className="card-body">
                      <div className="chart-container">
                        <SmvAcidityChart
                          name="SMV & Acidity"
                          smv={product.attributes.sake_meter_value}
                          acidity={product.attributes.acidity}
                        />
                      </div>
                    </div>
                  )}
                <ul className="list-group list-group-flush facts-container">
                  {product?.attributes.sake_meter_value !== null && (
                    <li className="list-group-item">
                      <img
                        src={imgSmv}
                        alt={t("OurProducts.sakeMeterValue")}
                        style={{ width: "20px" }}
                      />{" "}
                      <strong>{t("OurProducts.sakeMeterValue")}: </strong>
                      {product?.attributes.sake_meter_value}
                    </li>
                  )}
                  {product?.attributes.acidity !== null && (
                    <li className="list-group-item">
                      <img
                        src={imgAcidity}
                        alt={t("OurProducts.acidity")}
                        style={{ width: "20px" }}
                      />{" "}
                      <strong>{t("OurProducts.acidity")}: </strong>
                      {product?.attributes.acidity}
                    </li>
                  )}
                  {product?.attributes.rice_polishing_ratio !== null && (
                    <li className="list-group-item">
                      <img
                        src={imgPolishing}
                        alt={t("OurProducts.ricePolishingRatio")}
                        style={{ width: "20px" }}
                      />{" "}
                      <strong>{t("OurProducts.ricePolishingRatio")}: </strong>
                      {percentFormat(product?.attributes.rice_polishing_ratio)}%
                    </li>
                  )}
                  {product?.attributes.alcohol_content !== null && (
                    <li className="list-group-item">
                      <img
                        src={imgAlcohol}
                        alt={t("OurProducts.alcoholPercent")}
                        style={{ width: "20px" }}
                      />{" "}
                      <strong>{t("OurProducts.alcoholPercent")}: </strong>
                      {percentFormat(product?.attributes.alcohol_content)}%
                    </li>
                  )}
                  {product?.relationships.rice_type !== null && (
                    <li className="list-group-item">
                      <img
                        src={imgRiceType}
                        alt={t("general.riceType")}
                        style={{ width: "20px" }}
                      />{" "}
                      <strong>{t("general.riceType")}: </strong>
                      {
                        product?.relationships.rice_type[
                          "name" + langContentModifier
                        ]
                      }
                    </li>
                  )}
                  {product?.relationships.sake_type !== null && (
                    <li className="list-group-item">
                      <strong>{t("general.sakeType")}: </strong>
                      {
                        product?.relationships.sake_type[
                          "name" + langContentModifier
                        ]
                      }
                    </li>
                  )}
                </ul>
              </div>
              <br />
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <Footer getLink={props.getLink} />
    </>
  );
};

export default SingleProduct;

import App from './App';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// 3rd party libs
import { Toaster } from 'react-hot-toast';
import './index.min.css';
import './i18n/i18n';
import UserContextProvider from './contexts/UserProvider';
import CartContextProvider from './contexts/CartProvider';

ReactDOM.render(
  <BrowserRouter>
    <Toaster position="bottom-center" />
    <Suspense fallback={<div>Loading...</div>}>
      <UserContextProvider>
        <CartContextProvider>
          <App />
        </CartContextProvider>
      </UserContextProvider>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);
import React from "react";

const Address = (props) => {
  return (
    <React.Fragment>
      <div id="address-display">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.address.attributes.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">Contact: {props.address.attributes.email} / {props.address.attributes.phone}</h6>
            <p className="card-text">
              <strong>{props.address.attributes.type}</strong><br />
              {props.address.attributes.floor} / {props.address.attributes.flat}, {props.address.attributes.block}, {props.address.attributes.building}<br />
              {props.address.attributes.street}<br />
              {props.address.relationships.district.name}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Address;

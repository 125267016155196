import React from 'react';
import { useTranslation } from 'react-i18next';

const DeliveryInfoSingle = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h3 style={{ textAlign: 'center' }}>{t("general.singleDeliveries")}</h3>

      <div className="card border-info mb-3">
        <div className="card-header">{t("general.yourFirstBoxShipment")}</div>
        <div className="card-body">
          <h5 className="card-title" dangerouslySetInnerHTML={{__html:t("general.singleDeliveriesSubTitle")}}  />
          <p className="card-text">
            {t("general.singleDeliveriesDescription")}
          </p>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default DeliveryInfoSingle;
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Footer from "../../../../layouts/frontend/Footer";
import Header from "../../../../layouts/frontend/Header";
import ValidationErrors from "../../../../utility/ValidationErrors";
import Breadcrumb from "../../ui/Breadcrumb";
import WhyMembership from "../sections/WhyMembership";

axios.defaults.withCredentials = true;

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const emailInputRef = useRef();
  const [ submitBtnDisabled, setSubmitBtnDisabled ] = useState(false);
  const [ submitted, setSubmitted ] = useState(false);

  const forgotPasswordHandler = (e) => {
    e.preventDefault();

    setSubmitBtnDisabled(true);

    axios
      .post(process.env.REACT_APP_API_URL + "forgot_password", {
        email: emailInputRef.current.value,
      })
      .then((response) => {
        //userCtx.redirectHandler(props.getLink('reset-password'));
        setSubmitted(true);
      })
      .catch((error) => {
        setSubmitBtnDisabled(false);
        ValidationErrors(error.response.data.errors, [
          "email"
        ], t);
      });

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("forgotPassword.title")} | JustSaké Hong Kong</title>
      </Helmet>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          { text: t("forgotPassword.title"), link: "" },
        ]}
      />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="forgot-password">
        <div className="container">
          {submitted === false && 
            <div className="row">
              <div className="offset-lg-3 col-lg-6 pt-4 pb-5">
                <h2 className="my-5">{t("forgotPassword.title")}</h2>
                <form onSubmit={forgotPasswordHandler}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      {t("signup.emailAddress")}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      aria-describedby="emailHelp"
                      ref={emailInputRef}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={submitBtnDisabled}
                    className="btn btn-primary"
                    id="submitBtn"
                  >
                    {t("general.submit")}
                  </button>
                </form>
              </div>
            </div>
          }

          {submitted === true && 
            <div className="row">
              <div className="offset-lg-3 col-lg-6 pt-4 pb-5">
                <h2 className="my-5 text-center">{t("forgotPassword.submittedTitle")}</h2>
                <p className="text-center">{t("forgotPassword.thankYou")}</p>
              </div>
            </div>
          }
        </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </>
  );
};

export default ForgotPassword;

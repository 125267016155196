// TODO - needs to be refactored into smaller components for efficiency.

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import StatusMessages, { useMessages } from "../../../../../StatusMessages";
import PaymentMethods from "./PaymentMethods";
import "./CardForm.min.css";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import UserContext from "../../../../../contexts/user-context";
import CardElementForm from "./CardElementForm";
import ReactFBPixel from "react-facebook-pixel";
import CartContext from "../../../../../contexts/cart-context";

axios.defaults.withCredentials = true;

const CardForm = (props) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const cardHolderNameInputRef = useRef();
  const subscriptionCheckboxRef = useRef();
  // const [messages, addMessage] = useMessages();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const storedUserToken = localStorage.getItem("userToken");
  const userCtx = useContext(UserContext);
  const cartCtx = useContext(CartContext);

  /******************************************************************
   *
   * begin handleSubmit
   *
   ******************************************************************/
  const handleSubmit = async (dataPaymentMethods) => {
    Swal.fire({
      title: t("general.paymentProcessing"),
      html: t("general.paymentProcessingText"), // add html attribute if you want or remove
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false
    });

    if (!stripe || !elements) {
      Swal.fire({
        icon: "error",
        title: t("errorMessages.swalDefaultTitle"),
        text: "Stripe.js has not yet loaded.",
      });
      //addMessage("Stripe.js has not yet loaded.");
      return;
    }

    if (props.deliveryAddress === null) {
      Swal.fire({
        icon: "error",
        title: t("errorMessages.swalDefaultTitle"),
        text: "Please enter a delivery address.",
      });
      //addMessage("Please enter a delivery address.");
      return;
    }

    setIsLoading(true);

    if (cardHolderNameInputRef.current.value.trim().length === 0) {
      if (selectedPaymentMethodId !== null) {
        // pay with previously added credit cards
        if (props.setupIntentId !== null) {
          subscribe();
        }

        if (props.paymentIntentId !== null) {
          singlePayment();
        }
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: t("errorMessages.swalDefaultTitle"),
          text: "Card holder name required, or select a previously used card.",
        });
        // addMessage(
        //   "Card holder name required, or select a previously used card."
        // );
        return;
      }
    } else {
      //subscribe with new credit card
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

      if (paymentMethodError) {
        //addMessage(paymentMethodError.message);
        Swal.fire({
          icon: "error",
          title: t("errorMessages.swalDefaultTitle"),
          text: paymentMethodError.message,
        });
        setIsLoading(false);
        return;
      }
      //addMessage(`Successfully created PaymentMethod ${paymentMethod.id}`);

      /**
       * check if payment is duplicate
       */
      const { isDuplicate } = await axios
        .post(
          process.env.REACT_APP_API_URL + "payment/check_is_duplicate",
          {
            payment_method_id: paymentMethod.id,
          },
          {
            headers: { Authorization: `Bearer ${storedUserToken}` },
          }
        )
        .then((response) => response.data)
        .catch((e) => e);
      // addMessage(
      //   `Got the duplicate result for ${paymentMethod.id}, is ${isDuplicate}`
      // );

      if (isDuplicate) {
        Swal.fire({
          icon: "error",
          title: t("errorMessages.swalDefaultTitle"),
          text: "Payment method is a duplicate",
        });
        //addMessage("Payment Method is a duplicate");
        setIsLoading(false);
        return;
      }

      /***********************************************************************
       *
       * begin confirm card
       *
       ***********************************************************************/
      if (props.paymentIntentId !== null) {
        var { error: paymentIntentError, paymentIntent } =
          await confirmCardPaymentHandler();
      } else {
        var { error: setupIntentError, setupIntent } =
          await confirmCardSetupHandler();
      }
      /***********************************************************************
       *
       * end confirm card
       *
       ***********************************************************************/
      const stripeError =
        props.paymentIntentId !== null ? paymentIntentError : setupIntentError;
      if (stripeError) {
        Swal.fire({
          icon: "error",
          title: t("errorMessages.swalDefaultTitle"),
          text: stripeError.message,
        });
        //addMessage(stripeError.message);
        setIsLoading(false);
        return;
      }

      /**
       * add payment method
       */
      const stripePaymentMethodId =
        props.setupIntentId !== null
          ? setupIntent.payment_method
          : paymentIntent.payment_method;
      var {
        error: addPaymentError,
        //payment,
        paymentMethods,
      } = await axios
        .post(
          process.env.REACT_APP_API_URL + "payment/user/payment_method",
          {
            payment_method_id: stripePaymentMethodId,
          },
          {
            headers: { Authorization: `Bearer ${storedUserToken}` },
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          return e;
        });

      if (addPaymentError) {
        Swal.fire({
          icon: "error",
          title: t("errorMessages.swalDefaultTitle"),
          text: addPaymentError.message,
        });
        //addMessage(addPaymentError.message);
        setIsLoading(false);
        return;
      }
      //addMessage(`Payment add ${payment.status}: ${payment.id}`);
      _setSelectedPaymentMethods(paymentMethods);

      // TODO - See how to reactivate onchange event in paymentMethods.  currently disabled props.createSetupIntent(), since page refresh resolves the client_secret (since its loaded in SubscriptionCardForm.js).  Currently, force to reload in order to reactivate paymentMethod onchange events after adding new card.
      // retrieve new setupIntent if adding a new card
      // props.createSetupIntent();
      const _paymentMethod =
        paymentMethods !== undefined
          ? paymentMethods.filter((pm) => pm.is_default === 1)
          : dataPaymentMethods;
      const _paymentMethodId =
        _paymentMethod.length > 0 ? _paymentMethod[0].id : null;

      if (!window.location.href.includes("localhost")) {
        // fb pixel
        const advancedMatching = {};
        const options = {
          autoConfig: true,
          debug: false
        };
        ReactFBPixel.init('6703591589656745', advancedMatching, options);
        ReactFBPixel.track('AddPaymentInfo');
      }

      if (props.setupIntentId !== null) {
        subscribe(_paymentMethodId);
      } else {
        singlePayment(_paymentMethodId);
      }
    }
  };
  /******************************************************************
   *
   * end handleSubmit
   *
   ******************************************************************/

  const confirmCardPaymentHandler = (stripe_payment_method_id) => {
    const pm_data =
      stripe_payment_method_id !== undefined
        ? stripe_payment_method_id
        : {
            card: elements.getElement(CardElement),
            billing_details: {
              name: cardHolderNameInputRef.current.value,
            },
          };
    return stripe
      .confirmCardPayment(props.clientSecret, {
        payment_method: pm_data,
        setup_future_usage: "on_session",
      })
      .catch((e) => {
        return e;
      });
  };

  const confirmCardSetupHandler = async (stripe_payment_method_id) => {
    const pm_data =
      stripe_payment_method_id !== undefined
        ? stripe_payment_method_id
        : {
            card: elements.getElement(CardElement),
            billing_details: {
              name: cardHolderNameInputRef.current.value,
            },
          };
    const cardSetup = stripe.confirmCardSetup(props.clientSecret, {
      payment_method: pm_data,
    });

    return cardSetup;
  };

  /******************************************************************
   *
   * begin subscriptions
   *
   ******************************************************************/
  const subscribe = async (paymentMethodId, ordersId) => {
    if (subscriptionCheckboxRef.current.checked === false) {
      Swal.fire({
        icon: "info",
        confirmButtonText: t("general.subCheckboxOk"),
        title: t("errorMessages.swalDefaultTitle"),
        text: t("general.subCheckboxError"),
      });
      setIsLoading(false);
      return;
    }

    const _selectedPaymentMethodId =
      typeof paymentMethodId != "undefined"
        ? paymentMethodId
        : selectedPaymentMethodId;
    const _ordersId = typeof ordersId != "undefined" ? ordersId : null;
    await axios
      .post(
        process.env.REACT_APP_API_URL + "payment/user/subscribe",
        {
          payment_methods_id: _selectedPaymentMethodId,
          set_skus_id: props.setSku,
          setup_intent_id: props.setupIntentId,
          orders_id: _ordersId,
        },
        {
          headers: { Authorization: `Bearer ${storedUserToken}` },
        }
      )
      .then((response) => {
        if (
          response.data.state === "requires_action" ||
          response.data.state === "payment_pending" ||
          response.data.state === "requires_payment_method"
        ) {
          confirmCardSetupHandler(response.data.stripe_payment_method_id)
            .then((response2) => {
              if (
                response2.setupIntent !== undefined &&
                response2.setupIntent.status === "succeeded"
              ) {
                subscribe(response.data.payment_methods_id, response.data.id);
              }

              if (response2.error !== undefined) {
                setIsLoading(false);
                Swal.fire({
                  icon: "error",
                  title: t("errorMessages.swalDefaultTitle"),
                  text: "Sorry, we were not able to process your payment.  Please try a different card.",
                }).then((result) => {
                  if (result.isConfirmed) {
                    // if payment was added, but card still invalid, we need to refresh page to grab
                    // updated setupIntent that is empty.
                    window.location.reload();
                  }
                });
              }
            })
            .catch((error2) => {
              setIsLoading(false);
              Swal.fire({
                icon: "error",
                title: t("errorMessages.swalDefaultTitle"),
                text: "Sorry, we were not able to process your payment.  Please try a different card.",
              });
            });
        } else if (
          response.data.state === "paid" ||
          response.data.state === "subscribed"
        ) {
          userCtx.redirectHandler(
            props.getLink("order/" + response.data.order_number)
          );

          Swal.close();
        } else if (response.data.state === "payment_rejected") {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: t("errorMessages.swalDefaultTitle"),
            text: "Sorry, we were not able to process your payment.  Please try a different card.",
          });
        }

        //closes modal window
        //Swal.close();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: t("errorMessages.swalDefaultTitle"),
          text: e.response.data.error,
        });
        setIsLoading(false);
      });
  };

  /******************************************************************
   *
   * end subscriptions
   *
   ******************************************************************/

  /******************************************************************
   *
   * begin single payment
   *
   ******************************************************************/
  const singlePayment = async (paymentMethodId, ordersId) => {
    const _selectedPaymentMethodId =
      typeof paymentMethodId != "undefined"
        ? paymentMethodId
        : selectedPaymentMethodId;
    const _ordersId = typeof ordersId != "undefined" ? ordersId : null;
    await axios
      .post(
        process.env.REACT_APP_API_URL + "payment/user/single_payment",
        {
          payment_methods_id: _selectedPaymentMethodId,
          set_skus_id: props.setSku,
          payment_intent_id: props.paymentIntentId,
          orders_id: _ordersId,
        },
        {
          headers: { Authorization: `Bearer ${storedUserToken}` },
        }
      )
      .then((response) => {
        if (response.data.state === "requires_action") {
          confirmCardPaymentHandler(response.data.stripe_payment_method_id)
            .then((response2) => {
              if (response2.paymentIntent.status === "succeeded") {
                singlePayment(
                  response.data.payment_methods_id,
                  response.data.id
                );
              }
            })
            .catch((error2) => {
              setIsLoading(false);
              Swal.fire({
                icon: "error",
                title: t("errorMessages.swalDefaultTitle"),
                text: "Sorry, we were not able to process your payment.  Please try a different card.",
              });
            });
        } else {
          // clear cartItems
          cartCtx.clearCart();

          userCtx.redirectHandler(
            props.getLink("order/" + response.data.order_number)
          );
          
          Swal.close();
        }
      })
      .catch((e) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: t("errorMessages.swalDefaultTitle"),
          text: e.response.data.error,
        });
      });
  };

  /******************************************************************
   *
   * end single payment
   *
   ******************************************************************/

  /******************************************************************
   *
   * begin payment methods
   *
   ******************************************************************/
  const { setPaymentMethods } = props;
  const _setSelectedPaymentMethods = (paymentMethods) => {
    let defaultPaymentMethodSet = false;
    if (paymentMethods === null) {
      setPaymentMethods([]);
      return;
    }

    if (paymentMethods.length === 0) {
      setSelectedPaymentMethodId(null);
    }

    const _pm = paymentMethods.map((pm) => {
      if (pm.is_default) {
        setSelectedPaymentMethodId(pm.id);
        defaultPaymentMethodSet = true;
      }
      return { ...pm, keyid: "pm" + pm.id };
    });

    if (defaultPaymentMethodSet === false && paymentMethods.length > 0) {
      setSelectedPaymentMethodId(_pm[0].id);
    }

    setPaymentMethods(_pm);
  };

  /**
   * remove pm
   */
  const removePaymentMethodHandler = async (paymentMethodId) => {
    await axios
      .delete(
        process.env.REACT_APP_API_URL +
          "payment/user/payment_method/" +
          paymentMethodId,
        {
          headers: { Authorization: `Bearer ${storedUserToken}` },
        }
      )
      .then((response) => {
        _setSelectedPaymentMethods(response.data);
      })
      .catch((e) => {
        return e;
      });
  };

  // /**
  //  * get PMs
  //  */
  // const getDataPaymentMethods = useCallback(async (storedUserToken) => await axios.get(process.env.REACT_APP_API_URL + 'payment/user/payment_methods', {
  //   headers: { "Authorization": `Bearer ${storedUserToken}` }
  // }).then( (response) => {
  //   _setSelectedPaymentMethods(response.data);
  // }).catch( (e) => {
  //   return e;
  // }), []);

  /**
   * change pms
   * #TODO - if selected, should change to default payment method.
   */
  const changePaymentMethodsHandler = (e) => {
    //console.log(e.target.value);
    setSelectedPaymentMethodId(e.target.value);
  };

  useEffect(() => {
    _setSelectedPaymentMethods(props.paymentMethods);
  }, []);
  /******************************************************************
   *
   * end payment methods
   *
   ******************************************************************/

  const options = {
    hidePostalCode: true
  };

  return (
    <div id="payment-methods">
      <div id="payment-selects" onChange={changePaymentMethodsHandler}>
        
        {props.paymentMethods !== null && (
          <PaymentMethods
            paymentMethods={props.paymentMethods}
            setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            removePaymentMethodHandler={removePaymentMethodHandler}
          />
        )}
      </div>

      <div id="payment-form">
        <CardElementForm
          handleSubmit={handleSubmit}
          paymentMethods={props.paymentMethods}
          cardHolderNameInputRef={cardHolderNameInputRef}
          options={options}
          isLoading={isLoading}
          dataSetSku={props.dataSetSku}
          subscriptionCheckboxRef={subscriptionCheckboxRef}
        />
      </div>
    </div>
  );
};

export default CardForm;

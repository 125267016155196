import React from 'react';

const CartItemButtons = (props) => {
  const set_skus_id = props.setSkusId;
  const product_skus_id = props.productSkusId;
  const quantity = props.quantity;

  return <>
    <div className="cart-quantity">{quantity}</div>
    <div className="d-flex justify-content-center">
      <button
        className="cart-button"
        onClick={(e) => props.updateCartHandler(e)}
        data-set_skus_id={set_skus_id}
        data-product_skus_id={product_skus_id}
        data-quantity={quantity-1}
      >
        -
      </button>
      <button
        className="cart-button"
        onClick={(e) => props.updateCartHandler(e)}
        data-set_skus_id={set_skus_id}
        data-product_skus_id={product_skus_id}
        data-quantity={quantity+1}
      >
        +
      </button>
      <button
        className="cart-button"
        style={{ backgroundColor: "#c10000", color: "#fff", fontWeight: "bold", fontSize: "12px" }}
        onClick={(e) => props.updateCartHandler(e)}
        data-set_skus_id={set_skus_id}
        data-product_skus_id={product_skus_id}
        data-quantity={0}
      >
        x
      </button>
    </div>
  </>
}

export default CartItemButtons;
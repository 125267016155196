import React from "react";
import { useTranslation } from "react-i18next";
import CartItemNameProduct from "./CartItemNameProduct";
import CartItemNameSet from "./CartItemNameSet";

const CartItemName = (props) => {
  const { i18n } = useTranslation();
  const langContentModifier = i18n.language === "en" ? "" : "_zh";

  return (
    <>
      {props.setSkusId !== null && (
        <CartItemNameSet
          setName={props.cartItem.set["name" + langContentModifier]}
          setSkuTypeName={
            props.cartItem.set_sku_type["name" + langContentModifier]
          }
        />
      )}
      {props.productSkusId !== null && (
        <CartItemNameProduct
          itemBreweryName={
            props.itemBrewery !== null && props.itemBrewery !== undefined
              ? props.itemBrewery["name" + langContentModifier]
              : null
          }
          productDetailHandler={props.productDetailHandler}
          productUrl={props.cartItem.product !== undefined ? props.getLink('products/' + props.cartItem.product.slug) : null}
          productName={props.itemAttribute["name" + langContentModifier]}
          itemTypeQuantity={props.itemType.item_quantity}
          itemTypeName={props.itemType["name" + langContentModifier]}
        />
      )}
    </>
  );
};

export default CartItemName;

import moment from 'moment';
import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import "./SetInfoCard.min.css";

const SetInfoCard = (props) => {
  const { t } = useTranslation();
  
  const now = moment(); //todays date
  const end = moment(props.endDate); // another date
  const duration = moment.duration(end.diff(now));
  const hours = duration.asHours();
  
  const lowStockNotice = parseInt(process.env.REACT_APP_LOW_STOCK_DISPLAY_NOTICE);
  const noticeHoursBeforeEnd = parseInt(process.env.REACT_APP_END_DATE_NOTICE);

  return (
    <React.Fragment>
      {hours < noticeHoursBeforeEnd && props.stockLevel !== null && <div style={{ padding: "5px 15px", fontSize: "0.9rem" }} className="alert alert-warning position-relative">
        <span style={{ fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: t("general.endingSoon") }} />
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
          !
          <span className="visually-hidden">unread messages</span>
        </span>
        <div className="float-end" style={{ fontWeight: "bold" }}>
        <Countdown date={props.endDate} />
        </div>
      </div>}

      <div className="card mb-2 mb-sm-4">
        <div className="row g-0">
          <div className="col-md-4 image-display" style={{ backgroundImage: "url(/assets/images/" + props.imageName + ")" }}></div>
          <div className="col-md-8">
            <div className="card-body">
              <h2 className="card-title">
                {props.title}
                {props.stockLevel <= lowStockNotice && props.endDate !== null && <div style={{ padding: "5px 15px", fontSize: "0.9rem" }} className="alert alert-danger position-relative float-end">
                  <span dangerouslySetInnerHTML={{ __html: t("general.lowStockWarning") }} />
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    !
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </div>}
              </h2>
              <p dangerouslySetInnerHTML={{ __html: props.content }} />
              {/* <Link to={props.setLink} className="btn btn-sm btn-success">{t("general.packageDetails")}</Link> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SetInfoCard;
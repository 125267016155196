import toast from "react-hot-toast";
import Swal from "sweetalert2";

/**
 * 
 * @param {Array} errors errors array - key is name of field, and value is error message
 * @param {Array} fields to pick for error display, array of field names to display
 * @param {*} t  The language object from useTranslation
 * @param {*} alertType  toast | swal
 */
const ValidationErrors = (errors, fields, t, alertType) => {
  alertType = alertType === undefined ? "toast" : "swal";

  if (errors !== undefined) {
    if (alertType === "toast") {
      fields.forEach((field) => {
        if (errors[field] !== undefined) {
          errors[field].forEach((e) => {
            toast.error(e);
          });
        }
      });
    } else {
      fields.forEach((field) => {
        if (errors[field] !== undefined) {
          errors[field].forEach((e) => {
            Swal.fire(
              t("errorMessages.swalDefaultTitle"),
              e,
              "error"
            );
          });
        }
      });
    }
  }
};

export default ValidationErrors;

import React from "react";

const UserContext = React.createContext({
  errors: { email: '', name: '', password: '', password_confirmation: '' },
  user: {},
  userToken: null,
  userIsLoggedIn: null,
  cart: null,
  addUser: (firstName, emailAddress, password) => {},
  loginUser: (emailAddress, password) => {},
  logoutUser: () => {},
  updateUser: () => {},
  updateUserPassword: () => {},
  redirectHandler: () => {},
  getCart: () => {}
});

export default UserContext;
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { numberFormat } from '../../../../../assets/frontend/js/numberFormat';
import DeliveryItemsInfo from './DeliveryItemsInfo';

const Order = (props) => {
  const { t, i18n } = useTranslation();  
  const langContentModifier = i18n.language === "en" ? "" : "_zh";
  const deliveryAddress = JSON.parse(
    props.order?.attributes?.address_data
  );
  
  const order = props.order;
  const order_total = order?.attributes?.total;
  const set = props.order?.relationships?.order_items[0]?.relationships?.set;
  const set_sku_type = props.order?.relationships?.order_items[0]?.relationships?.set_sku_type;
  const set_image_name = set !== null ? set.image_name : '';
  const set_name = set !== null ? set["name" + langContentModifier] : '';
  const set_sku_type_name = set_sku_type !== null ? set_sku_type["name" + langContentModifier] : '';
  const set_sku_type_price = set_sku_type !== null ? set_sku_type.price : '';
  const set_description = set !== null ? set["description" + langContentModifier] : '';
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card text-start">
        {set !== null && <div className="set-info">
          {set_image_name !== null && <div className="image-display" style={{ backgroundImage: "url(/assets/images/" + set_image_name + ")" }} />}
          <div className="card-body">
            <h4 className="card-title">
              {set_name}<span style={{ color: "#ccc" }}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>{set_sku_type_name}
            </h4>
            <div className="price">{numberFormat(set_sku_type_price)}</div>
            <p className="card-text" dangerouslySetInnerHTML={{ __html: set_description }} />
          </div>
        </div>}



        {set === null && (
          <>
            <DeliveryItemsInfo
              deliveryItems={props.order.relationships.order_items}
            />
            <div className="card-body" style={{ paddingTop: "0px" }}>
              <div className="price" style={{ marginTop: "0px" }}>{numberFormat(order_total)}</div>
            </div>
          </>
        )}

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <strong>{t("profile.deliveryAddress")}</strong>:<br />
            <strong style={{ textTransform: "uppercase" }}>
              [{deliveryAddress.attributes?.type}]
            </strong>
            &nbsp;&nbsp;{deliveryAddress.attributes?.floor}/
            {deliveryAddress.attributes?.flat},{" "}
            {deliveryAddress.attributes?.building},{" "}
            {deliveryAddress.attributes?.street},{" "}
            {
              deliveryAddress.relationships?.district[
                "name" + langContentModifier
              ]
            }{" "}
            {/* <button className="btn btn-success btn-sm float-end">
              {t("profile.changeAddress")}
            </button> */}
          </li>

          <li className="list-group-item">
            <strong>{t("profile.nextDeliveryDate")}</strong>:<br />
            {order?.attributes?.next_delivery_start !== null && (
              <>
                {moment(order?.attributes?.next_delivery_start).format("MMMM Do")}{" "}-{" "}
              </>
            )}{moment(order?.attributes?.next_delivery_end).format("MMMM Do YYYY")}{" "}
            {/* <button className="btn btn-success btn-sm float-end">
              {t("profile.pastDeliveries")}
            </button> */}
          </li>

          <li className="list-group-item">
            <strong>{t("profile.paymentStatus")}</strong>:{" "}
            {order?.attributes?.state} <br />
            <span style={{ fontSize: "0.8rem" }}>
              <strong>{t("general.orderNumber")}</strong>:{" "}
              {order?.attributes?.order_number} 
              <br />
              <strong>{t("profile.purchasedDateAt")}</strong>:{" "}
              {moment(order?.attributes?.created_at).format(
                "MMMM Do YYYY"
              )}
            </span>
            {/* <button className="btn btn-success btn-sm float-end">
              {t("profile.paymentUpdate")}
            </button> */}
          </li>
        </ul>
      </div>
      <br />
      <br />
    </>
  );
}

export default Order;
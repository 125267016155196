import React from 'react';

const CartItemNameProduct = (props) => {
  return <>
    {props.itemBreweryName !== null && <div className="item-brewery">{props.itemBreweryName}</div>}
    <div className="inner-item-name" onClick={() => props.productDetailHandler(props.productUrl)}>{props.productName}</div>
    <div className="item-price">{props.itemTypeQuantity} x {props.itemTypeName}</div>
  </>
}

export default CartItemNameProduct;
import React from 'react';
import { Link } from 'react-router-dom';

const Dropdown = (props) => {
  return (
    <React.Fragment>
      <div className="dropdown">
        <Link className={`btn btn-${props.type} btn-${props.size} dropdown-toggle`} to="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          {props.items.map((item, idx) => {
            if (item.id === props.selected) {
              return <span key={idx}>{item.text}</span>;
            }
          })}
        </Link>

        <ul className="dropdown-menu">
          {props.items.map((item, idx) => {
            if (item.id !== props.selected) {
              return <li key={idx}><Link className="dropdown-item" to={item.link}>{item.text}</Link></li>;
            }
          })}
        </ul>
      </div>
    </React.Fragment>
  );
}

export default Dropdown;
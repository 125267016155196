import React from "react";

const CartContext = React.createContext({
  cart: null,
  showCart: false,
  showCartHandler: () => {},
  updateCart: () => {},
  isCartEmpty: () => {},
  clearCart: () => {}
});

export default CartContext;
import React from 'react';

const CartItemNameSet = (props) => {
  return <>
    <div className="item-title">
      {props.setName}
    </div>
    <div className="item-subtitle">
      {props.setSkuTypeName}
    </div>
  </>
}

export default CartItemNameSet;
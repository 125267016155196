import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../layouts/frontend/Footer";
import Header from "../../../layouts/frontend/Header";
import Breadcrumb from "../ui/Breadcrumb";
import WhyMembership from "./sections/WhyMembership";

const PrivacyPolicy = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Header getLink={props.getLink} />
      <Breadcrumb
        crumbs={[
          { text: t("navigation.home"), link: props.getLink("/") },
          { text: t("footer.termsAndConditions"), link: "" },
        ]}
      />

      <div className="liquor-law">{t('general.liquorLaw')}</div>

      <section className="breadcrumb-spacing" id="membership-push">
        <div className="jumbotron jumbotron-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col jumbo-right">
                <div className="banner-bg">
                  <div className="banner-content">
                    <h2
                      className="display-6"
                      dangerouslySetInnerHTML={{
                        __html: t("footer.privacyPolicy"),
                      }}
                    />
                    {i18n.language === "en" && (
                      <div>
                        <p>
                          This Privacy Policy describes how your personal
                          information is collected, used, and shared when you
                          visit or make a purchase from www.justsake.com.
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>
                          PRIVACY POLICY SECTION 1 | PERSONAL INFORMATION WE
                          COLLECT:
                        </h1>
                        <p>
                          When you visit the Site, we automatically collect
                          certain information about your device, including
                          information about your web browser, IP address, time
                          zone, and some of the cookies that are installed on
                          your device. Additionally, as you browse the Site, we
                          collect information about the individual web pages or
                          products that you view, what websites or search terms
                          referred you to the Site, and information about how
                          you interact with the Site. We refer to this
                          automatically-collected information as &ldquo;Device
                          Information&rdquo;.
                        </p>
                        <p>
                          We collect Device Information using the following
                          technologies:
                        </p>
                        <ul>
                          <li>
                            <p>
                              &ldquo;Cookies&rdquo; are data files that are
                              placed on your device or computer and often
                              include an anonymous unique identifier. For more
                              information about cookies, and how to disable
                              cookies, visit http://www.allaboutcookies.org.
                            </p>
                          </li>
                          <li>
                            <p>
                              &ldquo;Log files&rdquo; track actions occurring on
                              the Site, and collect data including your IP
                              address, browser type, Internet service provider,
                              referring/exit pages, and date/time stamps.
                            </p>
                          </li>
                          <li>
                            <p>
                              &ldquo;Web beacons&rdquo;, &ldquo;tags&rdquo;, and
                              &ldquo;pixels&rdquo; are electronic files used to
                              record information about how you browse the Site.
                            </p>
                          </li>
                        </ul>
                        <p>
                          Additionally, when you make a purchase or attempt to
                          make a purchase through the Site, we collect certain
                          information from you, including your name, billing
                          address, shipping address, payment information
                          (including credit card numbers, email address, and
                          phone number. We refer to this information as
                          &ldquo;Order Information&rdquo;.
                        </p>
                        <p>
                          When we talk about &ldquo;Personal Information&rdquo;
                          in this Privacy Policy, we are talking both about
                          Device Information and Order Information.
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>
                          PRIVACY POLICY SECTION 2 | HOW DO WE USE YOUR PERSONAL
                          INFORMATION?
                        </h1>
                        <p>
                          We use the Order Information that we collect generally
                          to fulfil any orders placed through the Site
                          (including processing your payment information,
                          arranging for shipping, and providing you with
                          invoices and/or order confirmations). Additionally, we
                          use this Order Information to:
                        </p>
                        <ul>
                          <li>
                            <p>Communicate with you;</p>
                          </li>
                          <li>
                            <p>
                              Screen our orders for potential risk or fraud; and
                            </p>
                          </li>
                          <li>
                            <p>
                              When in line with the preferences you have shared
                              with us, provide you with information or
                              advertising relating to our products or services.
                            </p>
                          </li>
                        </ul>
                        <p>
                          We use the Device Information that we collect to help
                          us screen for potential risk and fraud (in particular,
                          your IP address), and more generally to improve and
                          optimize our Site (for example, by generating
                          analytics about how our customers browse and interact
                          with the Site, and to assess the success of our
                          marketing and advertising campaigns).
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>
                          PRIVACY POLICY SECTION 3 | SHARING YOUR PERSONAL
                          INFORMATION
                        </h1>
                        <p>
                          We share your Personal Information with third parties
                          to help us use your Personal Information, as described
                          above. &nbsp;We also use Google Analytics to help us
                          understand how our customers use the Site -- you can
                          read more about how Google uses your Personal
                          Information here:
                          https://www.google.com/intl/en/policies/privacy/. You
                          can also opt-out of Google Analytics here:
                          https://tools.google.com/dlpage/gaoptout.
                        </p>
                        <p>
                          Finally, we may also share your Personal Information
                          to comply with applicable laws and regulations, to
                          respond to a subpoena, search warrant or other lawful
                          requests for information we receive, or to otherwise
                          protect our rights.
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>
                          PRIVACY POLICY SECTION 4 | BEHAVIORAL ADVERTISING
                        </h1>
                        <p>
                          As described above, we use your Personal Information
                          to provide you with targeted advertisements or
                          marketing communications we believe may be of interest
                          to you. For more information about how targeted
                          advertising works, you can visit the Network
                          Advertising Initiative&rsquo;s (&ldquo;NAI&rdquo;)
                          educational page at
                          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                        </p>
                        <p>
                          You can opt out of targeted advertising by using the
                          links below:
                        </p>
                        <ul>
                          <li>
                            <p>
                              Facebook:
                              https://www.facebook.com/settings/?tab=ads
                            </p>
                          </li>
                          <li>
                            <p>
                              Google:
                              https://www.google.com/settings/ads/anonymous
                            </p>
                          </li>
                          <li>
                            <p>
                              Bing:
                              https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                            </p>
                          </li>
                        </ul>
                        <p>
                          Additionally, you can opt out of some of these
                          services by visiting the Digital Advertising
                          Alliance&rsquo;s opt-out portal at:{" "}
                          <a href="http://optout.aboutads.info/">
                            http://optout.aboutads.info/
                          </a>
                          .
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>PRIVACY POLICY SECTION 5 | DO NOT TRACK</h1>
                        <p>
                          Please note that we do not alter our Site&rsquo;s data
                          collection and use practices when we see a Do Not
                          Track signal from your browser.
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>PRIVACY POLICY SECTION 6 | YOUR RIGHTS</h1>
                        <p>
                          If you are a European resident, you have the right to
                          access personal information we hold about you and to
                          ask that your personal information be corrected,
                          updated, or deleted. If you would like to exercise
                          this right, please contact us through the contact
                          information below.
                        </p>
                        <p>
                          Additionally, if you are a European resident we note
                          that we are processing your information in order to
                          fulfill contracts we might have with you (for example
                          if you make an order through the Site), or otherwise
                          to pursue our legitimate business interests listed
                          above. Additionally, please note that your information
                          will be transferred outside of Europe, including to
                          Canada and the United States.
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>PRIVACY POLICY SECTION 7 | DATA RETENTION</h1>
                        <p>
                          When you place an order through the Site, we will
                          maintain your Order Information for our records unless
                          and until you ask us to delete this information.
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>PRIVACY POLICY SECTION 8 | CHANGES</h1>
                        <p>
                          We may update this privacy policy from time to time in
                          order to reflect, for example, changes to our
                          practices or for other operational, legal or
                          regulatory reasons.
                        </p>
                        <p>
                          <br />
                        </p>
                        <h1>PRIVACY POLICY SECTION 9 | MINORS</h1>
                        <p>
                          The Site is not intended for individuals under the age
                          of 18. By using our website and ordering from us, you
                          automatically agree to our terms and that you are not
                          under the age of 18.
                        </p>
                        <h1>
                          <br />
                        </h1>
                        <h1>PRIVACY POLICY SECTION 10 | CONTACT US</h1>
                        <p>
                          For more information about our privacy practices, if
                          you have questions, or if you would like to make a
                          complaint, please contact us by e‑mail at&nbsp;
                          <a href="mailto:hello@justsake.com">
                            hello@justsake.com
                          </a>{" "}
                          .
                        </p>
                        <p>
                          <br />
                        </p>
                      </div>
                    )}

                    {i18n.language !== "en" && (
                      <div>
                        <p>
                          本隱私政策描述了您在訪問 www.justsake.com
                          或進行購買時如何收集、使用和共享您的個人信息。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 1 節 |我們收集的個人信息：</p>
                        <p>
                          當您訪問本網站時，我們會自動收集有關您設備的某些信息，包括有關您的網絡瀏覽器、IP
                          地址、時區以及安裝在您設備上的一些 cookie
                          的信息。此外，當您瀏覽本網站時，我們會收集有關您查看的各個網頁或產品的信息、哪些網站或搜索詞將您引至本網站，以及有關您如何與本網站互動的信息。我們將此自動收集的信息稱為&ldquo;設備信息&rdquo;。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>我們使用以下技術收集設備信息：</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          &ldquo;Cookies&rdquo;是放置在您的設備或計算機上的數據文件，通常包含一個匿名的唯一標識符。有關
                          cookie 以及如何禁用 cookie 的更多信息，請訪問
                          http://www.allaboutcookies.org。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          &ldquo;日誌文件&rdquo;跟踪網站上發生的操作，並收集包括您的
                          IP
                          地址、瀏覽器類型、互聯網服務提供商、引用/退出頁面和日期/時間戳在內的數據。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          &ldquo;網絡信標&rdquo;、&ldquo;標籤&rdquo;和&ldquo;像素&rdquo;是用於記錄有關您如何瀏覽本網站的信息的電子文件。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          此外，當您通過本網站進行購買或嘗試進行購買時，我們會從您那裡收集某些信息，包括您的姓名、賬單地址、送貨地址、付款信息（包括信用卡號、電子郵件地址和電話號碼。我們將此信息稱為&ldquo;訂單信息&rdquo;。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          當我們在本隱私政策中談論&ldquo;個人信息&rdquo;時，我們指的是設備信息和訂單信息。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 2 節 |我們如何使用您的個人信息？</p>
                        <p>
                          我們使用我們通常收集的訂單信息來完成通過網站下達的任何訂單（包括處理您的付款信息、安排運輸以及向您提供發票和/或訂單確認）。此外，我們使用此訂單信息來：
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>與您溝通；</p>
                        <p>
                          <br />
                        </p>
                        <p>篩選我們的訂單是否存在潛在風險或欺詐；和</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          根據您與我們分享的偏好，向您提供與我們的產品或服務相關的信息或廣告。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          我們使用我們收集的設備信息來幫助我們篩查潛在的風險和欺詐行為（特別是您的
                          IP
                          地址），並且更廣泛地用於改進和優化我們的網站（例如，通過生成關於我們的客戶如何瀏覽和互動的分析網站，並評估我們的營銷和廣告活動的成功）。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 3 節 |分享您的個人信息</p>
                        <p>
                          如上所述，我們與第三方共享您的個人信息以幫助我們使用您的個人信息。我們還使用
                          Google Analytics
                          來幫助我們了解客戶如何使用本網站&mdash;&mdash;您可以在此處閱讀更多關於
                          Google
                          如何使用您的個人信息的信息：https://www.google.com/intl/en/policies/privacy/。您也可以在此處選擇退出
                          Google
                          Analytics：https://tools.google.com/dlpage/gaoptout。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          最後，我們還可能共享您的個人信息，以遵守適用的法律和法規，回應傳票、搜查令或其他對我們收到的信息的合法請求，或以其他方式保護我們的權利。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 4 節 |行為廣告</p>
                        <p>
                          如上所述，我們使用您的個人信息為您提供我們認為您可能感興趣的有針對性的廣告或營銷通訊。有關定向廣告如何運作的更多信息，您可以訪問網絡廣告倡議
                          (&ldquo;NAI&rdquo;) 的教育頁面，網址為
                          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>您可以使用以下鏈接選擇退出定向廣告：</p>
                        <p>
                          <br />
                        </p>
                        <p>臉書：https://www.facebook.com/settings/?tab=ads</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          谷歌：https://www.google.com/settings/ads/anonymous
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          必應：https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          此外，您可以通過訪問數字廣告聯盟的退出門戶網站選擇退出其中一些服務：http://optout.aboutads.info/。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 5 節 |不跟踪</p>
                        <p>
                          請注意，當我們從您的瀏覽器中看到&ldquo;請勿跟踪&rdquo;信號時，我們不會改變我們網站的數據收集和使用做法。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 6 節 |您的權利</p>
                        <p>
                          如果您是歐洲居民，您有權訪問我們持有的關於您的個人信息，並要求更正、更新或刪除您的個人信息。如果您想行使此權利，請通過以下聯繫信息與我們聯繫。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          此外，如果您是歐洲居民，我們注意到我們正在處理您的信息以履行我們可能與您簽訂的合同（例如，如果您通過本網站下訂單），或以其他方式追求我們上面列出的合法商業利益。此外，請注意，您的信息將被轉移到歐洲以外，包括加拿大和美國。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 7 節 |數據保留</p>
                        <p>
                          當您通過本網站下訂單時，我們將保留您的訂單信息以供我們記錄，除非您要求我們刪除此信息。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 8 節 |變化</p>
                        <p>
                          我們可能會不時更新本隱私政策，以反映例如我們的做法或其他運營、法律或監管原因的變化。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 9 節 |未成年人</p>
                        <p>
                          本網站不適用於 18
                          歲以下的個人。使用我們的網站並向我們訂購，即表示您自動同意我們的條款並且您未滿
                          18 歲。
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>隱私政策第 10 節 |聯繫我們</p>
                        <p>
                          有關我們的隱私慣例的更多信息，如果您有任何疑問，或者如果您想提出投訴，請發送電子郵件至
                          hello@justsake.com 與我們聯繫。
                        </p>
                        <p>
                          <br />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyMembership getLink={props.getLink} />
      <Footer getLink={props.getLink} />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
